import React, {Component} from "react";
import GoogleMapReact from 'google-map-react';

export default class TourPlan extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            TimeNow: "",
        };
    }


    render() { 
        var TourPlanArray = Array.from(this.props.TourPlanList)
        var spaceTimes = this.props.FeedSpacesTime
        var TourPlanContent;
        if (TourPlanArray.length === 0) {
            TourPlanContent = <div className="TourPlanNothing">Nothing added to the tourplan</div>
        } else {
            var TourPlanId;
            var TourPlanTitle = "Untitled"
            var TourPlanGallery;
            var TourPlanAddress;
            var TourPlanCity;
            var TourPlanLat;
            var TourPlanLng;
            var spaceMonday;
            var spaceTuesday;
            var spaceWednesday;
            var spaceThursday;
            var spaceFriday;
            var spaceSaturday;
            var spaceSunday;
            TourPlanContent = [];
            var TourPlanMarkerPositions = [];
            for (var TourItem in TourPlanArray) {
                for (var AllEE in this.props.FeedCalendar) {
                    if (this.props.FeedCalendar[AllEE]["eeId"] === TourPlanArray[TourItem]) {
                        var TourArtists = []
                        for (var AllSpace in this.props.FeedSpaces) {
                            if (this.props.FeedSpaces[AllSpace]["spaceId"] === this.props.FeedCalendar[AllEE]["spaceId"])  {
                                if (this.props.FeedCalendar[AllEE]["title"]) {
                                    TourPlanId = TourPlanArray[TourItem]
                                    TourPlanTitle = this.props.FeedCalendar[AllEE]["title"]
                                    TourPlanGallery = this.props.FeedSpaces[AllSpace]["name"]
                                    TourPlanAddress = this.props.FeedSpaces[AllSpace]["address"]
                                    TourPlanCity = this.props.FeedSpaces[AllSpace]["city"]
                                    for (var thisExtra in this.props.FeedSpacesAttributes) {
                                        if (this.props.FeedSpacesAttributes[thisExtra]["spaceId"] === this.props.FeedCalendar[AllEE]["spaceId"]) {
                                            if (this.props.FeedSpacesAttributes[thisExtra].attributeId === 9) { 
                                                TourPlanLat = this.props.FeedSpacesAttributes[thisExtra].attributeValue
                                            } 
                                            if (this.props.FeedSpacesAttributes[thisExtra].attributeId === 10) {
                                                TourPlanLng = this.props.FeedSpacesAttributes[thisExtra].attributeValue
                                            }
                                        }
                                    }
                                }
                                var matchingSpaces = spaceTimes.filter(space => space.spaceId === this.props.FeedSpaces[AllSpace]["spaceId"]);
                                if (matchingSpaces) {
                                    matchingSpaces.forEach(spaceTime => {
                                        if (spaceTime.daynumber === 1) {
                                            if (spaceTime.opentime) {
                                                spaceMonday = <div className="SpacesDetailMond"><b>Mon:</b>{spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceMonday = <div className="SpacesDetailMond"><b>Mon:</b> By Appt.</div>
                                            }
                                        } 
                                        if (spaceTime.daynumber === 2) {
                                            if (spaceTime.opentime) {
                                                spaceTuesday = <div className="SpacesDetailTues"><b>Tue:</b>{spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceTuesday = <div className="SpacesDetailTues"><b>Tue:</b> By Appt.</div>
                                            }
                                        } 
                                        if (spaceTime.daynumber === 3) {
                                            if (spaceTime.opentime) {
                                                spaceWednesday = <div className="SpacesDetailWeds"><b>Wed:</b>{spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceWednesday = <div className="SpacesDetailWeds"><b>Wed:</b> By Appt.</div>
                                            }
                                        } 
                                        if (spaceTime.daynumber === 4) {
                                            if (spaceTime.opentime) {
                                                spaceThursday = <div className="SpacesDetailThurs"><b>Thu:</b>{spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceThursday = <div className="SpacesDetailThurs"><b>Thu:</b> By Appt.</div>
                                            }
                                        } 
                                        if (spaceTime.daynumber === 5) {
                                            if (spaceTime.opentime) {
                                                spaceFriday = <div className="SpacesDetailFrid"><b>Fri:</b> {spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceFriday = <div className="SpacesDetailFrid"><b>Fri:</b> By Appt.</div>;
                                            }
                                        } 
                                        if (spaceTime.daynumber === 6) {
                                            if (spaceTime.opentime) {
                                                spaceSaturday = <div className="SpacesDetailSatu"><b>Sat:</b> {spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceSaturday = <div className="SpacesDetailSatu"><b>Sat:</b> By Appt.</div>
                                            }
                                        }
                                        if (spaceTime.daynumber === 7) {
                                            if (spaceTime.opentime) {
                                                spaceSunday = <div className="SpacesDetailSund"><b>Sun:</b> {spaceTime.opentime.slice(0,5) + "-" + spaceTime.closetime.slice(0,5)}</div>
                                            } else if (spaceTime.byAppointment) {
                                                spaceSunday = <div className="SpacesDetailSund"><b>Sun:</b> By Appt.</div>;
                                            }
                                        }
                                    });
                                }
                            }
                            
                        }
                        for (var thisArtist in this.props.FeedArtists) {
                            if (this.props.FeedArtists[thisArtist].eEid === this.props.FeedCalendar[AllEE].eeId) {
                                if (this.props.FeedArtists[thisArtist].roletext === "Artist") {
                                    TourArtists.push(this.props.FeedArtists[thisArtist].contactName)
                                }
                            }
                        }
                    }
                }
                TourPlanContent.push(<div className="TourPlanEntryCont"><div className="TourPlanLeft">{TourPlanTitle}, <i>{TourArtists}</i><div className="TourPlanTitle"></div><div className="TourPlanGallery">{TourPlanGallery}</div><div className="TourPlanLocation">{TourPlanAddress}, {TourPlanCity}</div></div><div className="TourPlanRight">{spaceMonday}{spaceTuesday}{spaceWednesday}{spaceThursday}{spaceFriday}{spaceSaturday}{spaceSunday}</div></div>)
                TourPlanMarkerPositions.push({name: TourPlanGallery, lat: TourPlanLat, lng:TourPlanLng, spaceId: TourPlanId})
            }
            var MarkerCollection = [];
            var GoogleMapsDirections = "";
            for (var MarkerDetail in TourPlanMarkerPositions) {
                const DetailMarker = ({ name, spaceID }) => <div key={"TourPlanPinKey_"+spaceID} className="PinDetail"><div key={"TourPlanNameKey_"+spaceID} className="PinDetailTitle">{name}</div></div>
                MarkerCollection.push(<DetailMarker key={"DetailMarkerKey_"+TourPlanMarkerPositions[MarkerDetail].spaceId}
                lat={parseFloat(TourPlanMarkerPositions[MarkerDetail].lat)}
                lng={parseFloat(TourPlanMarkerPositions[MarkerDetail].lng)}
                name={TourPlanMarkerPositions[MarkerDetail].name}
                spaceID={TourPlanMarkerPositions[MarkerDetail].spaceId}/>)
                GoogleMapsDirections += TourPlanMarkerPositions[MarkerDetail].lat+","+TourPlanMarkerPositions[MarkerDetail].lng+"/"
            }
            if (TourPlanArray.length > 1) {
                TourPlanContent.push(<a className="TourPlanRouteMap" target="_blank" rel="noreferrer" href={"https://www.google.com/maps/dir/"+GoogleMapsDirections+"@"+TourPlanMarkerPositions[0].lat+","+TourPlanMarkerPositions[0].lng+",12z/data=!3m1!4b1!4m2!4m1!3e2"}><u>Click here for directions on google maps</u> <span className="sr-only">↗</span></a>)
                TourPlanContent.push(<div className="tourplanMapCont"><GoogleMapReact 
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                    center={{lat: parseFloat(TourPlanMarkerPositions[0].lat), lng: parseFloat(TourPlanMarkerPositions[0].lng)}}
                    zoom={10}
                    options={{mapId:"eddf51aff07de27a"}}
                    >
                        {MarkerCollection}
                    </GoogleMapReact></div>)
            }
        }

        return (
            <div className="BigContainer">
                <div className="TourPlanCont">
                    {TourPlanContent}
                </div>
            </div>
        );
    }
}