import React, {Component} from "react";
import { BiCopy } from 'react-icons/bi';
import ali_picture from "../assets/ali-profile.png";
import laus_picture from "../assets/laus-profile1.png";
import katinka_picture from "../assets/katinka-profile1.png";
import jeanette_picture from "../assets/jeanette-profile1.png";
import thor_picture from "../assets/thor-picture.png";
import fanny_picture from "../assets/fanny-profile1.png";
import lea_picture from "../assets/lea-profile.png";
import linda_picture from "../assets/lindaa-profile.png";
import "../css/global.css";

export default class AboutPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    EmailAddress: "contact@seaslug.se"
  };
}

render() {
    return (
    <div className="AboutPageCont">
        <div className="AboutPageText">
            <h1>About Seaslug</h1>
            <p>Seaslug is an independent platform providing a guide to Stockholm’s art scene. Our site includes a calendar and an overview of exhibitions, performances, art spaces and other cultural events that are happening around the city. We strive towards creating a non-hierarchical space where small artist-run spaces receive as much attention as big institutions. Like a seaslug we navigate Stockholm’s scene, and if we lose heads or limbs, we will hopefully grow new ones.</p>
            <p>
            Curation opportunities are available to trusted Seaslug affiliates. If you are interested in joining or would like to know more feel fee to contact us.
            </p>
        </div>
        <div className="SeaslugEmail">
            <div className="EmailText">{this.state.EmailAddress}</div>
            <div className="EmailCopySymbol"><BiCopy onClick={() => {navigator.clipboard.writeText(this.state.EmailAddress)}}/></div>
        </div>
        <div className="AboutPageTeam">
            <h2>Brought to you by:</h2>
            <div className="aboutImages">
                <a className="personLink" href="https://www.instagram.com/kattenpaainsta/" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={laus_picture} alt="laus_picture"/>
                    <div className="memberName">Laus Østergaard</div>
                    <div className="memberRole">Curator, Branding & Social Media</div>
                </a>
                <a className="personLink" href="https://www.instagram.com/katsaarnak/" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={katinka_picture} alt="katinka_picture"/>
                    <div className="memberName">Katinka Saarnak</div>
                    <div className="memberRole">Curator & Social Media</div>
                </a>
                <a className="personLink" href="https://alirazzak.info/" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={ali_picture} alt="ali_picture"/>
                    <div className="memberName">Ali Razzak</div>
                    <div className="memberRole">Web Design & Development</div>
                </a>
                <a className="personLink" href="https://www.jeanettegunnarsson.com/" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={jeanette_picture} alt="jeanette_picture"/>
                    <div className="memberName">Jeanette Gunnarsson</div>
                    <div className="memberRole">Curator, Silent Supporter</div>
                </a>
                <a className="personLink" href="https://www.instagram.com/fanny.bylund/" target="_blank"rel="noreferrer" >
                    <img className="memberPicture" src={fanny_picture} alt="fanny_picture"/>
                    <div className="memberName">Fanny Bylund</div>
                    <div className="memberRole">Artist</div>
                </a>
                <a className="personLink" href="https://www.scientdata.dk/" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={thor_picture} alt="thor_picture"/>
                    <div className="memberName">Thor Østergaard</div>
                    <div className="memberRole">Web Engineer</div>
                </a>
                <a className="personLink" href="https://www.instagram.com/kulturkris" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={lea_picture} alt="lea_picture"/>
                    <div className="memberName">Lea Gill</div>
                    <div className="memberRole">Editor & Social Media</div>
                </a>
                <a className="personLink" href="https://www.instagram.com/lindalouisekarlsson" target="_blank" rel="noreferrer">
                    <img className="memberPicture" src={linda_picture} alt="lea_picture"/>
                    <div className="memberName">Linda L. Karlsson</div>
                    <div className="memberRole">Curator & Social Media</div>
                </a>
            </div>
        </div>
    </div>
    )
}
}
