import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import dotenv from "dotenv"
dotenv.config();

class VenueGoogleMap extends Component {
  state = {
    center: {
    }
  };
  if 

  render() {
    var ThesePins;
    if (this.props.SpacesMapType === "all") {
      ThesePins = this.props.MapSpacesPinsAll
    }
    if (this.props.SpacesMapType === "ongoing") {
      ThesePins = this.props.MapSpacesPinsOngoing
    }
    if (this.props.SpacesMapType === "konsthall") {
      ThesePins = this.props.MapSpacesPinsKonsthall
    }
    if (this.props.SpacesMapType === "artistrun") {
      ThesePins = this.props.MapSpacesPinsArtistRun
    }
    if (this.props.SpacesMapType === "gallery") {
      ThesePins = this.props.MapSpacesPinsGallery
    }
    if (this.props.SpacesMapType === "other") {
      ThesePins = this.props.MapSpacesPinsOther
    }
    if (this.props.SpacesMapType === "organization") {
      ThesePins = this.props.MapSpacesPinsOrganization
    }
    if (this.props.SpacesMapType === "museum") {
      ThesePins = this.props.MapSpacesPinsMuseum
    }
    if (this.props.SpacesMapType === "artschool") {
      ThesePins = this.props.MapSpacesPinsArtSchool
    }

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100%", width: "100%", display: this.props.SpacesMapTypeAll }}>
        <GoogleMapReact key={"GogleMapItself"}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
          center={{lat: 59.32926481792779, lng: 18.067990674440974}}
          zoom={11}
          options={{mapId:"eddf51aff07de27a"}}
        >
          {ThesePins}
        </GoogleMapReact>
      </div>
    );
  }
}
export default VenueGoogleMap;