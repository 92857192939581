
import React, {Component} from "react";
import "../css/cfaf.css";

export default class CFAF extends Component {
    constructor(props) {
      super(props);
      // Initialize state to store text file content
      this.state = {
        fileContent: '',
      };
    }
  
    componentDidMount() {
      // Fetch the text file content when the component mounts
      fetch(`${process.env.PUBLIC_URL}/CFAF.txt`)
        .then((response) => response.text())
        .then((text) => {
          // Update state with the fetched content
          this.setState({ fileContent: text });
        })
        .catch((error) => console.error('Error fetching text file:', error));
    }
  
    render() {
      // Render the fetched content
      return (
        <div className="ascii">
          <pre>{this.state.fileContent}</pre>
        </div>
      );
    }
  }