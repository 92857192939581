import React, {Component} from "react";
import { BiCopy } from 'react-icons/bi';
import VenueGoogleMap from "./VenueGoogleMap"
import GoogleMapReact from 'google-map-react';
import moment from "moment";
import dayjs from 'dayjs';
import "../css/global.css";
import Select from 'react-select'
import { forEach } from "draft-js/lib/DefaultDraftBlockRenderMap";

export default class SpacesPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    SpacesType: true,
    SpacesApppearList: true,
    SpacesAppearMap: false,
    SpacesMapType: "all",
    LowerSpaceContent: "",
    spaceDetailContent:<div className="spaceDetailCont"><div className="spacePlaceholdler">If your space is not on this list but you feel it should be added please reach out to us at contact@seaslug.se</div></div>
  };
  this.toggleSpacesType = this.toggleSpacesType.bind(this);
  this.toggleSpacesApppearList = this.toggleSpacesApppearList.bind(this);
  this.toggleSpacesAppearMap = this.toggleSpacesAppearMap.bind(this);
  this.handleMapSpaceEffect = this.handleMapSpaceEffect.bind(this);
  this.handleMapLabelVisibility = this.handleMapLabelVisibility.bind(this);
  this.seeAllButton = this.seeAllButton.bind(this)
  this.seeLessButton = this.seeLessButton.bind(this)
  this.loadSpacesDetailDesktop = this.loadSpacesDetailDesktop.bind(this)
}

loadSpacesDetailDesktop(theId) {
    var AllEE = this.props.FeedCalendar
    var AllSpaces = this.props.SpacesFeed
    if (AllSpaces) {
        AllSpaces.sort((a, b) => a.name.localeCompare(b.name));
        var SpacesFilter = [];
        var excludeSpaces = ["Benhuset","Den Fantastiska Platsen","Domeij Gallery","Flat Octopus","Fylkingen","Galerie Nordenhake Focus","Galleri Skomakeriet","Gerlesborgsskolan","Ring art space","Selva Studio Sthlm","Galleri Artsight","Art Lab Gnesta","Galleri gemla","Litografiska Museet"]
        AllSpaces.forEach((space) => {
            if (!excludeSpaces.some(excludeSpace => space.name.includes(excludeSpace))) {
                SpacesFilter.push(space)
            }
        })
        AllSpaces = SpacesFilter;
    }
    var AllArtists = this.props.FeedArtists
    var spaceAttributes = this.props.FeedSpacesAttributes
    var spaceTimes = this.props.FeedSpacesTime
    var spaceFee = "";
    var spaceToilet = "";
    var spaceAccess = "";
    var spaceMonday = "";
    var spaceTuesday = "";
    var spaceWednesday = "";
    var spaceThursday = "";
    var spaceFriday = "";
    var spaceSaturday = ""
    var spaceSunday = "";
    for (var thisSpace in AllSpaces) {
        if (AllSpaces[thisSpace].spaceId === parseInt(theId) && !AllSpaces[thisSpace].name.includes("Accelerator")) {
            var spaceName = AllSpaces[thisSpace].name
            var spaceAddresss = AllSpaces[thisSpace].address
            var spaceCity = AllSpaces[thisSpace].city
            if (AllSpaces[thisSpace].phonenumber) {
                var spacePhonenumber = <div className="SpacesDetailPhone">{AllSpaces[thisSpace].phonenumber}</div>
            }
            if (AllSpaces[thisSpace].email) {
                var spaceEmail = <div className="SpacesDetailEmail">{AllSpaces[thisSpace].email}<div className="EmailCopySymbol"><BiCopy className="copysymbol" id={AllSpaces[thisSpace].email} onClick={(event) => navigator.clipboard.writeText(event.currentTarget.id)}/></div></div>
            }
            var spaceType = <div className="SpacesDetailType">{AllSpaces[thisSpace].type}</div>
            for (var thisAttribute in spaceAttributes) {
                if (spaceAttributes[thisAttribute].spaceId === parseInt(theId)) {
                    if (spaceAttributes[thisAttribute].attributeId === 1 && spaceAttributes[thisAttribute].attributeValue) { 
                        var spaceWesbite = <a target="_blank" rel="noreferrer" href={spaceAttributes[thisAttribute].attributeValue} className="SpacesDetailWebsite">website <span className="sr-only">↗</span></a>
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 2 && spaceAttributes[thisAttribute].attributeValue) { 
                        var spaceInsta = <a target="_blank" rel="noreferrer" href={"https://www.instagram.com/"+spaceAttributes[thisAttribute].attributeValue} className="SpacesDetailInstagram">instagram <span className="sr-only">↗</span></a>
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 3 && spaceAttributes[thisAttribute].attributeValue) { 
                        var spaceFacebook = <a target="_blank" rel="noreferrer"  href={spaceAttributes[thisAttribute].attributeValue} className="SpacesDetailFacebook">facebook <span className="sr-only">↗</span></a>
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 4 && spaceAttributes[thisAttribute].attributeValue) { 
                        spaceAccess = <a className="SpacesDetailAccess" href={spaceAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer">accessibility info <span className="sr-only">↗</span></a>
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 5 && spaceAttributes[thisAttribute].attributeValue) {
                        spaceToilet = <div className="SpacesDetailToilet"><b>Toilet:</b> {spaceAttributes[thisAttribute].attributeValue}</div>
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 6 && spaceAttributes[thisAttribute].attributeValue) { 
                        var spaceOther = spaceAttributes[thisAttribute].attributeValue
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 7 && spaceAttributes[thisAttribute].attributeValue) {
                        if (spaceAttributes[thisAttribute].attributeValue === "free") {
                            spaceFee = <div className="SpacesDetailFree"><b>Admission:</b> Free</div>
                        } else {
                            spaceFee = <a className="SpacesDetailCost" href={spaceAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer">cost info <span className="sr-only">↗</span></a>
                        }
                    }
                    if (spaceAttributes[thisAttribute].attributeId === 8 && spaceAttributes[thisAttribute].attributeValue) { 
                        var spaceAbout = <div className="SpacesDetailDescriptionImage"><div className="SpacesDetailAbout" style={{width:"100"}}  dangerouslySetInnerHTML={{__html: spaceAttributes[thisAttribute].attributeValue}}></div></div>
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 9) { 
                        var spaceLat = spaceAttributes[thisAttribute].attributeValue
                    } 
                    if (spaceAttributes[thisAttribute].attributeId === 10) {
                        var spaceLng = spaceAttributes[thisAttribute].attributeValue
                    }
                }
            }

            var spaceDetailMap = "";
            if (spaceLat) {
                const DetailMarker = ({ name, spaceID }) => <div  className={"PinDetail"}><div id={"SpaceDetailTitleId_"+spaceID} className="PinDetailTitle">{name}</div></div>
                spaceDetailMap = <div className="SpacesDetailMapsCont">
                    <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                    center={{lat: parseFloat(spaceLat), lng: parseFloat(spaceLng)}}
                    zoom={14}
                    options={{mapId:"eddf51aff07de27a"}}
                    >
                        <DetailMarker 
                        lat={parseFloat(spaceLat)}
                        lng={parseFloat(spaceLng)}
                        name={spaceName}
                        spaceID={AllSpaces[thisSpace].spaceId}/>
                    </GoogleMapReact>
                </div>
            }

            for (var thisTime in spaceTimes) {
                if (spaceTimes[thisTime].spaceId === AllSpaces[thisSpace].spaceId & !spaceTimes[thisTime].havingBreak) {
                    if (spaceTimes[thisTime].daynumber === 1) {
                        if (spaceTimes[thisTime].opentime) {
                        spaceMonday = <div className="SpacesDetailMond"><b>Mon:</b>{spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceMonday = <div className="SpacesDetailMond"><b>Mon:</b> By Appt.</div>
                        }
                    } 
                    if (spaceTimes[thisTime].daynumber === 2) {
                        if (spaceTimes[thisTime].opentime) {
                            spaceTuesday = <div className="SpacesDetailTues"><b>Tue:</b>{spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceTuesday = <div className="SpacesDetailTues"><b>Tue:</b> By Appt.</div>
                        }
                    } 
                    if (spaceTimes[thisTime].daynumber === 3) {
                        if (spaceTimes[thisTime].opentime) {
                            spaceWednesday = <div className="SpacesDetailWeds"><b>Wed:</b>{spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceWednesday = <div className="SpacesDetailWeds"><b>Wed:</b> By Appt.</div>
                        }
                    } 
                    if (spaceTimes[thisTime].daynumber === 4) {
                        if (spaceTimes[thisTime].opentime) {
                            spaceThursday = <div className="SpacesDetailThurs"><b>Thu:</b>{spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceThursday = <div className="SpacesDetailThurs"><b>Thu:</b> By Appt.</div>
                        }
                    } 
                    if (spaceTimes[thisTime].daynumber === 5) {
                        if (spaceTimes[thisTime].opentime) {
                            spaceFriday = <div className="SpacesDetailFrid"><b>Fri:</b> {spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceFriday = <div className="SpacesDetailFrid"><b>Fri:</b> By Appt.</div>;
                        }
                    } 
                    if (spaceTimes[thisTime].daynumber === 6) {
                        if (spaceTimes[thisTime].opentime) {
                            spaceSaturday = <div className="SpacesDetailSatu"><b>Sat:</b> {spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceSaturday = <div className="SpacesDetailSatu"><b>Sat:</b> By Appt.</div>
                        }
                    } 
                    if (spaceTimes[thisTime].daynumber === 7) {
                        if (spaceTimes[thisTime].opentime) {
                            spaceSunday = <div className="SpacesDetailSund"><b>Sun:</b> {spaceTimes[thisTime].opentime.slice(0,5) + "-" + spaceTimes[thisTime].closetime.slice(0,5)}</div>
                        } else if (spaceTimes[thisTime].byAppointment) {
                            spaceSunday = <div className="SpacesDetailSund"><b>Sun:</b> By Appt.</div>;
                        }
                    }
                }
            }

            var ThisMoment = moment(this.props.TimeNow, "DD/MM/YYYY").toDate().getTime()
            var CurrentlyShowing = [];
            var PreviouslyShown = []
            for (var thisEE in AllEE) {
                var eeStart = "";
                var eeStartMoment = "";
                var eeEnd = "";
                var eeEndMoment = "";
                if (AllEE[thisEE].eeTypeId === 3) {
                    if (AllEE[thisEE].spaceId === parseInt(theId)) {
                        var artistList = [];
                        for (var thisArtst in AllArtists) {
                            if (AllArtists[thisArtst].eEid === AllEE[thisEE].eeId && AllArtists[thisArtst].roletext === "Artist") {
                                artistList.push(AllArtists[thisArtst].contactName)
                            }
                        }
                        eeStart = dayjs(AllEE[thisEE].startDay).format('DD/MM/YYYY')
                        eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
                        eeEnd = dayjs(AllEE[thisEE].endDay).format('DD/MM/YYYY')
                        eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
                        if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment) {
                            CurrentlyShowing.push(<div>{AllEE[thisEE].title}, <i>{artistList.join(", ")}</i></div>)
                        }
                        if (eeEndMoment < ThisMoment ) {
                            PreviouslyShown.push(<div>{AllEE[thisEE].title}, <i>{artistList.join(", ")}</i></div>)
                        }
                    }
                }
            }

            var CurrentlyShowinigOut =  "";
            if (CurrentlyShowing.length > 0 ) {
                CurrentlyShowinigOut = <div key={"SpacesCurrenlyShowing_"+spaceTimes[thisTime].spaceId} className="SpacesCurrenlyShowing"><div key={"CurrentlyShowingTitle_"+spaceTimes[thisTime].spaceId} className="CurrentlyShowingTitle">Currently showing</div><div key={"CurrentlyShowingItems_"+spaceTimes[thisTime].spaceId} className="CurrentlyShowingItems">{CurrentlyShowing}</div></div>
            }

            var PreviouslyShownOut =  "";
            if (0 < PreviouslyShown.length) {
                PreviouslyShownOut = <div key={"SpacesPreviouslyShown_"+spaceTimes[thisTime].spaceId} className="SpacesPreviouslyShown"><div key={"PreviouslyShownTitle_"+spaceTimes[thisTime].spaceId} className="PreviouslyShownTitle">Previously shown</div><div key={"PreviouslyShownItems_"+spaceTimes[thisTime].spaceId} className="PreviouslyShownItems">{PreviouslyShown}</div></div>
            }
        }
    }
    this.setState({
        spaceDetailContent: [
            <div className="spaceDetailCont">
                <div className="spaceDetailTop">
                    <div className="spaceDetailName">{spaceName}</div>
                    {spaceType}
                </div>
                <div className="spaceDetailAddress">{spaceAddresss}, {spaceCity}</div> 
                <div className="spaceDetailContacts">{spacePhonenumber} {spaceEmail}</div>
                {CurrentlyShowinigOut}
                {spaceAbout}
                <div className="spaceDetailExtra">
                    <div className="spaceDetailExtraLeft">
                        {spaceMonday}
                        {spaceTuesday}
                        {spaceWednesday}
                        {spaceThursday}
                        {spaceFriday}
                        {spaceSaturday}
                        {spaceSunday}
                    </div>
                    <div className="spaceDetailExtraRight">
                        <div className="eeDetailSpaceExternal">
                            {spaceWesbite} {spaceInsta} {spaceFacebook}
                        </div>
                        <div className="eeDetailSpaceExternal2">
                            {spaceFee}
                            {spaceAccess}
                            {spaceToilet}
                        </div>
                    </div>
                </div>
                {spaceDetailMap}
                {PreviouslyShownOut}
            </div>
        ]
    })
}

seeAllButton(information) {
    document.getElementsByClassName("seeAllButton_"+information)[0].style.display = "none"
    document.getElementById("seeAllCont_"+information).style.display = "block"
}

seeLessButton(information) {
    var theId = "seeAllCont_" + information.split("_")[1]
    document.getElementById(theId).style.display = "none"
    document.getElementsByClassName("seeAllButton_"+information.split("_")[1])[0].style.display = "block"
}

async componentDidMount() {
    var SpacesDetailSpace = [];
    SpacesDetailSpace.push(
        <div key="SpacesCalendarListKey_Default" className="SpacesCalendarList">
        <div key="SpaceGalleryTypeContKey_Default" className="SpaceGalleryTypeCont">
            <div key="SpaceNameKey_Default" className="SpaceName">
                Accelerator
            </div>
            <div key="SpaceTypeKey_Default" className="SpaceType">
                Konsthall
            </div>
        </div>
        <div key="SpaceAddressReadContKey_Default" className="SpaceAddressReadCont">
            <div key="SpaceAddressKey_Default" className="SpaceAddress">
                Frescativägen 26A, 114 18 Norra Djurgården
            </div>
        </div>
        <div key="SpacesDetailExtraContentKey_Default" className="SpacesDetailExtraContent">
            <div key="SpacesDetailCommLinksKey_Default" className="SpacesDetailCommLinks">
                <div key="SpacesDetailPhoneEmailKey_Default" className="SpacesDetailPhoneEmail">
                    <div key="SpacesDetailEmailKey_Default" className="SpacesDetailEmail">accelerator@su.se<div className="EmailCopySymbol"><BiCopy className="copysymbol" onClick={() => {navigator.clipboard.writeText("accelerator@su.se")}}/></div></div>
                </div>
            </div>
            <div key="SpacesDetailDescriptionImageKey_Default" className="SpacesDetailDescriptionImage">
                <div key="SpacesDetailAboutKey_Default" className="SpacesDetailAbout" style={{width:"100"}}>Accelerator is an exhibition space where art, science and societal issues meet. It is part of Stockholm University. The mission of Accelerator is to engage actively with society, producing exhibitions presenting international and Swedish contemporary art.</div>
            </div>
            <div key="SpacesDetailTimeExtraKey_Default" className="SpacesDetailTimeExtra">
                <div key="SpacesDetailTimeKey_Default" className="SpacesDetailTime">
                    <div key="SpacesDetailTuesKey_Default" className="SpacesDetailTues"><b>Tue:</b> 12:00-18:00</div>
                    <div key="SpacesDetailWedsKey_Default" className="SpacesDetailWeds"><b>Wed:</b> 12:00-20:00</div>
                    <div key="SpacesDetailThursKey_Default" className="SpacesDetailThurs"><b>Thu:</b> 12:00-18:00</div>
                    <div key="SpacesDetailFriKey_Default" className="SpacesDetailFri"><b>Fri:</b> 12:00-18:00</div>
                    <div key="SpacesDetailSatKey_Default" className="SpacesDetailSat"><b>Sat:</b> 12:00-17:00</div>
                    <div key="SpacesDetailSundKey_Default" className="SpacesDetailSund"><b>Sun:</b> 12:00-17:00</div>
                </div>
                <div key="SpacesDetailExtraKey_Default" className="SpacesDetailExtra">
                    <div className="eeDetailSpaceExternal">
                        <a key="SpacesDetailWebsiteKey_Default" target="_blank" rel="noreferrer" href="https://acceleratorsu.art/en/frontpage/" className="SpacesDetailWebsite">website <span className="sr-only">↗</span></a>
                        <a key="SpacesDetailInstagramKey_Default" target="_blank" rel="noreferrer" href="https://www.instagram.com/acceleratorsu" className="SpacesDetailInstagram">instagram <span className="sr-only">↗</span></a>
                        <a key="SpacesDetailFacebookKey_Default" target="_blank" rel="noreferrer"  href="https://www.facebook.com/AcceleratorSU" className="SpacesDetailFacebook">facebook <span className="sr-only">↗</span></a>
                    </div>
                    <div className="eeDetailSpaceExternal2">
                        <a key="SpacesDetailAccessibilityKey_Default" className="SpacesDetailAccess" href="https://acceleratorsu.art/en/visit/" target="_blank" rel="noreferrer">accessibility info <span className="sr-only">↗</span></a>
                        <div key="SpacesDetailToiletKey_Default" className="SpacesDetailToilet"><b>Toilet:</b> yes</div>
                        <div key="SpacesDetailFreeKey_Default" className="SpacesDetailFree"><b>Admission:</b> Free</div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
    this.setState({
        LowerSpaceContent: SpacesDetailSpace
    })
    //var herewego = await this.props.SpacesFeed[0].spaceId
    //this.loadSpacesDetailDesktop("1000")
}

toggleSpacesType() {
    this.setState({
        SpacesType: !this.state.SpacesType
    });
}

toggleSpacesApppearList() {
    this.setState({
        SpacesApppearList: !this.state.SpacesApppearList
    });
}

toggleSpacesAppearMap() {
    this.setState({
        SpacesAppearMap: !this.state.SpacesAppearMap
    });
    if (this.state.SpacesAppearMap) {
        this.setState({
            SpacesMapType: "all"
        });
    } else {
        this.setState({
            SpacesMapType: "ongoing"
        });
    }
}

handleMapLabelVisibility(MarkerNameType) {
    this.setState({
        SpacesMapType: MarkerNameType
    })
}

async handleMapSpaceEffect(name,  address, city, phonenumber, email, type, havingBreak, website, instagram, facebook, accessibility_info, toilet, other, entrance_fee, monday, tuesday, wednesday, thursday, friday, saturday, sunday, about, spaceID) {
    var SpacesDetailSpace = [];
    var PhonenumberContent;
    var EmailContent;
    var WebsiteContent;
    var InstagramContent;
    var FacebookContent;
    var AboutImageContent
    if (phonenumber) {
        PhonenumberContent = <div key={"SpacesDetailPhoneKey_"+spaceID} className="SpacesDetailPhone">{phonenumber}</div>
    }
    if (email) {
        EmailContent = <div key={"SpacesDetailEmailKey_"+spaceID} className="SpacesDetailEmail">{email}<div className="EmailCopySymbol"><BiCopy className="copysymbol" onClick={() => {navigator.clipboard.writeText(email)}}/></div></div>
    }
    if (website) {
        WebsiteContent = <a key={"SpacesDetailWebsiteKey_"+spaceID} target="_blank" rel="noreferrer" href={website} className="SpacesDetailWebsite">website <span className="sr-only">↗</span></a>
    }
    if (instagram) {
        InstagramContent = <a key={"SpacesDetailInstagramKey_"+spaceID} target="_blank" rel="noreferrer" href={"https://www.instagram.com/"+instagram} className="SpacesDetailInstagram">instagram <span className="sr-only">↗</span></a>
    }
    if (facebook) {
        FacebookContent = <a key={"SpacesDetailFacebookKey_"+spaceID} target="_blank" rel="noreferrer"  href={facebook} className="SpacesDetailFacebook">facebook <span className="sr-only">↗</span></a>
    }
    var image//="https://optimise2.assets-servd.host/maniacal-finch/production/animals/reticulated-giraffe-01-01.jpg?w=1200&auto=compress%2Cformat&fit=crop&dm=1658951271&s=179de228b20aafcd748a5f0dc8fde776";
    if (image) {
        AboutImageContent = <div key={"SpacesDetailDescriptionImageKey_"+spaceID} className="SpacesDetailDescriptionImage"><div key={"SpacesDetailAboutKey_"+spaceID} className="SpacesDetailAbout" style={{width:"49%"}} dangerouslySetInnerHTML={{__html: about}}></div><div key={"SpacesDetailImgCaptionKey_"+spaceID} className="SpacesDetailImgCaption"><div key={"SpacesDetailImgContKey_"+spaceID} className="SpacesDetailImgCont"><img src={image} key={"SpacesDetailImgKey_"+spaceID} className="SpacesDetailImg" alt="Space Building"/></div><div key={"SpacesDetailCaptionKey_"+spaceID} className="SpacesDetailCaption">woo</div></div></div>
    } else {
        AboutImageContent = <div key={"SpacesDetailDescriptionImageKey_"+spaceID} className="SpacesDetailDescriptionImage"><div key={"SpacesDetailAboutKey_"+spaceID} className="SpacesDetailAbout" style={{width:"100"}} dangerouslySetInnerHTML={{__html: about}}></div></div>
    }

    var TimeExtraContent = [];
    if (!monday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailMondKey_"+spaceID} className="SpacesDetailMond">Mon: {monday}</div>)
    }
    if (!tuesday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailTuesKey_"+spaceID} className="SpacesDetailTues">Tue: {tuesday}</div>)
    }
    if (!wednesday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailWedsKey_"+spaceID} className="SpacesDetailWeds">Wed: {wednesday}</div>)
    }
    if (!thursday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailThursKey_"+spaceID} className="SpacesDetailThurs">Thu: {thursday}</div>)
    }
    if (!friday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailFriKey_"+spaceID} className="SpacesDetailFri">Fri: {friday}</div>)
    }
    if (!saturday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailSatKey_"+spaceID} className="SpacesDetailSat">Sat: {saturday}</div>)
    }
    if (!sunday.includes("null")) {
        TimeExtraContent.push(<div key={"SpacesDetailSundKey_"+spaceID} className="SpacesDetailSund">Sun: {sunday}</div>)
    }
    
    var AccessContent;
    if (accessibility_info) {
        AccessContent = <a key={"SpacesDetailAccessKey_"+spaceID} className="SpacesDetailAccess" href={accessibility_info} target="_blank" rel="noreferrer">accessibility info <span className="sr-only">↗</span></a>
    }
    var ToiletContent;
    if (toilet) {
        ToiletContent = <div key={"SpacesDetailToiletKey_"+spaceID} className="SpacesDetailToilet"><b>Toilet:</b> {toilet}</div>
    }
    var EntranceFeeContent;
    if (entrance_fee === "free") {
        EntranceFeeContent = <div key={"SpacesDetailFreeKey_"+spaceID} className="SpacesDetailFree"><b>Admission:</b> Free</div>
    } else {
        EntranceFeeContent = <a key={"SpacesDetailCostKey_"+spaceID} className="SpacesDetailCost" href={entrance_fee} target="_blank" rel="noreferrer">cost info <span className="sr-only">↗</span></a>
    }

    var ThisMoment = moment(this.props.TimeNow, "DD/MM/YYYY").toDate().getTime()
    var CurrentlyShowing = [];
    var PreviouslyShown = []
    for (var ThisEE in this.props.FeedCalendar) {
        var eeStart = "";
        var eeStartMoment = "";
        var eeEnd = "";
        var eeEndMoment = "";
        if (this.props.FeedCalendar[ThisEE].eeTypeId=== 3) {
            if (spaceID === this.props.FeedCalendar[ThisEE].spaceId) {
                var artistList = [];
                for (var thisArtst in this.props.FeedArtists) {
                    if (this.props.FeedArtists[thisArtst].eEid === this.props.FeedCalendar[ThisEE].eeId && this.props.FeedArtists[thisArtst].roletext === "Artist") {
                        artistList.push(this.props.FeedArtists[thisArtst].contactName)
                    }
                }
                eeStart = dayjs(this.props.FeedCalendar[ThisEE].startDay).format('DD/MM/YYYY')
                eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
                eeEnd = dayjs(this.props.FeedCalendar[ThisEE].endDay).format('DD/MM/YYYY')
                eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
                if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment) {
                    CurrentlyShowing.push(<div>{this.props.FeedCalendar[ThisEE].title}, <i>{artistList.join(", ")}</i></div>)
                }
                if (eeEndMoment < ThisMoment ) {
                    PreviouslyShown.push(<div>{this.props.FeedCalendar[ThisEE].title}, <i>{artistList.join(", ")}</i></div>)
                }
            }
        }
    }

    var CurrentlyShowinigOut =  "";
    if (CurrentlyShowing.length > 0 ) {
        CurrentlyShowinigOut = <div key={"SpacesCurrenlyShowing_"+spaceID} className="SpacesCurrenlyShowing"><div key={"CurrentlyShowingTitle_"+spaceID} className="CurrentlyShowingTitle">Currently showing</div><div key={"CurrentlyShowingItems_"+spaceID} className="CurrentlyShowingItems">{CurrentlyShowing}</div></div>
    }

    var PreviouslyShownOut =  "";
    if (0 < PreviouslyShown.length) {
        PreviouslyShownOut = <div key={"SpacesPreviouslyShown_"+spaceID} className="SpacesPreviouslyShown"><div key={"PreviouslyShownTitle_"+spaceID} className="PreviouslyShownTitle">Previously shown</div><div key={"PreviouslyShownItems_"+spaceID} className="PreviouslyShownItems">{PreviouslyShown}</div></div>
    }
    
    SpacesDetailSpace.push(
    <div key={"SpacesCalendarListKey_"+spaceID} className="SpacesCalendarList">
        <div key={"SpaceGalleryTypeContKey_"+spaceID} className="SpaceGalleryTypeCont">
            <div key={"SpaceNameKey_"+spaceID} className="SpaceName">
                {name}
            </div>
            <div key={"SpaceTypeKey_"+spaceID} className="SpaceType">
                {type}
            </div>
        </div>
        <div key={"SpaceAddressReadContKey_"+spaceID} className="SpaceAddressReadCont">
            <div key={"SpaceAddressKey_"+spaceID} className="SpaceAddress">
                {address}, {city}
            </div>
        </div>
        <div key={"SpacesDetailExtraContentKey_"+spaceID} className="SpacesDetailExtraContent">
            {CurrentlyShowinigOut}
            <div key={"SpacesDetailCommLinksKey_"+spaceID} className="SpacesDetailCommLinks">
                <div key={"SpacesDetailPhoneEmailKey_"+spaceID} className="SpacesDetailPhoneEmail">
                    {PhonenumberContent}
                    {EmailContent}
                </div>
            </div>
            {AboutImageContent}
            <div key={"SpacesDetailTimeExtraKey_"+spaceID} className="SpacesDetailTimeExtra">
                <div key={"SpacesDetailTimeKey_"+spaceID} className="SpacesDetailTime">
                    {TimeExtraContent}
                </div>
                <div key={"SpacesDetailExtraKey_"+spaceID} className="SpacesDetailExtra">
                    <div className="eeDetailSpaceExternal">
                        {WebsiteContent}
                        {InstagramContent}
                        {FacebookContent}
                    </div>
                    <div className="eeDetailSpaceExternal2">
                        {EntranceFeeContent}
                        {ToiletContent}
                        {AccessContent}
                    </div>
                </div>
            </div>
            {PreviouslyShownOut}
        </div>
    </div>)
    this.setState({
        LowerSpaceContent: SpacesDetailSpace,
    })

    // Isn't working for whtever reason
    /*var generalClasselement = document.getElementsByClassName("MapPinTitle")
    for (var i in generalClasselement) {
        if (typeof(generalClasselement[i]) === "object") {
            generalClasselement[i].style.display = "none"
        }
    }
    var IDelement = document.getElementById("MapSpaceTitleId_"+name);
    IDelement.style.display = "flex";*/
}

render() {
    
    var SpacePage;
    if (this.props.SpacesPageState) {
        SpacePage = "flex";
    } else {
        SpacePage = "none";
    }

    var SpacesType; var ListVis; var ListShow; var MapVis; var MapShow;
    if (this.state.SpacesType) {
        SpacesType = "Map"
        ListVis = "flex"
        ListShow = "flex"
        MapVis = "none"
        MapShow = "none"
    } else {
        SpacesType = "List"
        ListVis = "none"
        ListShow = "none"
        MapVis = "block"
        MapShow = "flex"
    }

    const date = new Date();
    var SpacesApppearList;
    var SpacesSortList = [];
    var FilterJump = new Set();
    var FilterJumpObjectList = {};
    var JumpContent;
    var SpacesFeed = this.props.SpacesFeed;
    if (SpacesFeed) {
        SpacesFeed.sort((a, b) => a.name.localeCompare(b.name));
        var SpacesFilter = [];
        var excludeSpaces = ["Benhuset","Den Fantastiska Platsen","Domeij Gallery","Flat Octopus","Fylkingen","Galerie Nordenhake Focus","Galleri Skomakeriet","Gerlesborgsskolan","Ring art space","Selva Studio Sthlm","Galleri Artsight","Art Lab Gnesta","Galleri gemla","Litografiska Museet"]
        SpacesFeed.forEach((space) => {
            if (!excludeSpaces.some(excludeSpace => space.name.includes(excludeSpace))) {
                SpacesFilter.push(space)
            }
        })
        SpacesFeed = SpacesFilter;
    }

    var SpacesAttributes = this.props.FeedSpacesAttributes;
    var SpacesTime = this.props.FeedSpacesTime;
    var JumpKey;
    var SpacesAttribute;
    var JumpID;
    var SpacesSortDisplay;
    var SpacesKey;
    const InstituteOptions = [{ value: 'spaces#Konsthall', label: 'Konsthall' }, { value: 'spaces#Artist Run', label: 'Artist Run' }, { value: 'spaces#Gallery', label: 'Gallery' }, { value: 'spaces#Other', label: 'Other' }, { value: 'spaces#Organization', label: 'Organization' }, { value: 'spaces#Museum', label: 'Museum' }, { value: 'spaces#Art School', label: 'Art School' }]
    const defaultInstitute = InstituteOptions[0];
    const InstituteOptionsMap = [{ value: 'all', label: 'All'}, { value: 'konsthall', label: 'Konsthall' }, { value: 'artistrun', label: 'Artist Run' }, { value: 'gallery', label: 'Gallery' }, { value: 'other', label: 'Other' }, { value: 'organization', label: 'Organization' }, { value: 'museum', label: 'Museum' }, { value: 'artschool', label: 'Art School' }]
    const defaultInstituteMap = InstituteOptionsMap[0];
    if (this.state.SpacesApppearList) {
        JumpContent = [];
        for (SpacesKey in SpacesFeed) {
            FilterJump.add(SpacesFeed[SpacesKey].name[0].toUpperCase())
            if  (FilterJumpObjectList[SpacesFeed[SpacesKey].name[0].toUpperCase()] === undefined) {
                FilterJumpObjectList[SpacesFeed[SpacesKey].name[0].toUpperCase()] = [SpacesFeed[SpacesKey]]
            } else {
                FilterJumpObjectList[SpacesFeed[SpacesKey].name[0].toUpperCase()].push(SpacesFeed[SpacesKey]);
            }
        }
        Array.from(FilterJump).sort().forEach(
            element => {
                JumpID="#"+element;
                JumpKey="jumpletter_"+element;
                JumpContent.push(<a href={JumpID} key={JumpKey}className="AlphabetJump">{element}</a>);
            }
        )
        SpacesApppearList = "Type"
        SpacesSortList.push(JumpContent)
        SpacesSortDisplay="block"
    } else {
        JumpContent = [];
        for (SpacesKey in SpacesFeed) {
            FilterJump.add(SpacesFeed[SpacesKey].type)
            if  (FilterJumpObjectList[SpacesFeed[SpacesKey].type] === undefined) {
                FilterJumpObjectList[SpacesFeed[SpacesKey].type] = [SpacesFeed[SpacesKey]]
            } else {
                FilterJumpObjectList[SpacesFeed[SpacesKey].type].push(SpacesFeed[SpacesKey]);
            }
        }
        Array.from(FilterJump).sort().forEach(
            element => {
                JumpID="#"+element;
                JumpKey="jumpletter_"+element;
                JumpContent.push(<a href={JumpID} key={JumpKey} className="AlphabetJump">{element}</a>)
            }
        )
        SpacesApppearList = "Alphabet"
        SpacesSortList.push(<div key="SpaceSelectDropdown" className="SpacesTypeSelect"><Select isSearchable={false} options={InstituteOptions} defaultValue={defaultInstitute} className="react-select-institute" classNamePrefix="react-select-institute" onChange={(e)=>{window.location.assign(e.value)}}/></div>)
        SpacesSortDisplay="flex"
    }
    
    var MapSet = new Set();
    var MapSpacesPinsAll = [];
    var MapSpacesPinsOngoing = [];
    var MapSpacesPinsKonsthall = [];
    var MapSpacesPinsArtistRun = [];
    var MapSpacesPinsOther = [];
    var MapSpacesPinsGallery = [];
    var MapSpacesPinsOrganization = [];
    var MapSpacesPinsArtSchool = [];
    var MapSpacesPinsMuseum = [];
    var MapSpacesInformation = {}
    var hms;
    var SecondOpenTime;
    var SecondCloseTime;
    var TimeNowRaw;
    var TimeNow;
    var MapLabelColour;
    for (SpacesKey in SpacesFeed) {
        MapLabelColour = "red";
        for (SpacesAttribute in SpacesAttributes) {
            if (SpacesFeed[SpacesKey].spaceId === SpacesAttributes[SpacesAttribute].spaceId){
                if (SpacesAttributes[SpacesAttribute].attributeValue) {
                    MapSpacesInformation["name"] = SpacesFeed[SpacesKey].name
                    MapSpacesInformation["address"] = SpacesFeed[SpacesKey].address
                    MapSpacesInformation["city"] = SpacesFeed[SpacesKey].city
                    MapSpacesInformation["phonenumber"] = SpacesFeed[SpacesKey].phonenumber
                    MapSpacesInformation["email"] = SpacesFeed[SpacesKey].email
                    MapSpacesInformation["type"] = SpacesFeed[SpacesKey].type
                    MapSpacesInformation["havingBreak"] = SpacesFeed[SpacesKey].havingBreak
                    MapSpacesInformation["spaceId"] = SpacesFeed[SpacesKey].spaceId
                    if (SpacesAttributes[SpacesAttribute].attributeId === 1) { 
                        MapSpacesInformation["website"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 2) { 
                        MapSpacesInformation["instagram"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 3) { 
                        MapSpacesInformation["facebook"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 4) { 
                        MapSpacesInformation["accessibility_info"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 5) { 
                        MapSpacesInformation["toilet"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 6) { 
                        MapSpacesInformation["other"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 7) { 
                        MapSpacesInformation["entrance_fee"] = SpacesAttributes[SpacesAttribute].attributeValue
                    }
                    if (SpacesAttributes[SpacesAttribute].attributeId === 8) { 
                        MapSpacesInformation["about"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 9) { 
                        MapSpacesInformation["lat"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 10) {
                        MapSpacesInformation["lng"] = SpacesAttributes[SpacesAttribute].attributeValue
                    }
                }
            }
        }
        var DateEntrySpace;
        for (DateEntrySpace in SpacesTime) {
            if (SpacesTime[DateEntrySpace].spaceId === SpacesFeed[SpacesKey].spaceId & !MapSpacesInformation.havingBreak) {
                if (SpacesTime[DateEntrySpace].daynumber === 1) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                    MapSpacesInformation["monday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["monday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["monday"] = "null";
                    }
                } 
                if (SpacesTime[DateEntrySpace].daynumber === 2) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                        MapSpacesInformation["tuesday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["tuesday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["tuesday"] = "null";
                    }
                } 
                if (SpacesTime[DateEntrySpace].daynumber === 3) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                        MapSpacesInformation["wednesday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["wednesday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["wednesday"] = "null";
                    }
                } 
                if (SpacesTime[DateEntrySpace].daynumber === 4) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                        MapSpacesInformation["thursday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["thursday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["thursday"] = "null";
                    }
                } 
                if (SpacesTime[DateEntrySpace].daynumber === 5) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                        MapSpacesInformation["friday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["friday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["friday"] = "null";
                    }
                } 
                if (SpacesTime[DateEntrySpace].daynumber === 6) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                        MapSpacesInformation["saturday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["saturday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["saturday"] = "null";
                    }
                } 
                if (SpacesTime[DateEntrySpace].daynumber === 7) {
                    if (SpacesTime[DateEntrySpace].opentime) {
                        MapSpacesInformation["sunday"] = SpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + SpacesTime[DateEntrySpace].closetime.slice(0,5)
                    } else if (SpacesTime[DateEntrySpace].byAppointment) {
                        MapSpacesInformation["sunday"] = "By Appt.";
                    } else {
                        MapSpacesInformation["sunday"] = "null";
                    }
                }
                
                var DayConvert;
                if (SpacesTime[DateEntrySpace].opentime) {
                    if (date.getDay() === 0) {
                        DayConvert = 6;
                    } else {
                        DayConvert = date.getDay() - 1;
                    }
                    if (DayConvert === SpacesTime[DateEntrySpace].daynumber) {
                        TimeNowRaw = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                        hms = TimeNowRaw.split(":");
                        TimeNow = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2])
                        hms = SpacesTime[DateEntrySpace].opentime.split(":");
                        SecondOpenTime = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2])
                        hms = SpacesTime[DateEntrySpace].closetime.split(":");
                        SecondCloseTime = (+hms[0]) * 60 * 60 + (+hms[1]) * 60 + (+hms[2])
                        if (SecondOpenTime < TimeNow < SecondCloseTime) {
                            MapLabelColour = "green"
                        }
                    }
                }
            }
        }

        MapSpacesInformation["Ongoing"] = "ThisIsNotOngoing";
        for (var ThisEE in this.props.FeedCalendar) {
            var eeStart = "";
            var eeStartMoment = "";
            var eeEnd = "";
            var eeEndMoment = "";
            if (SpacesFeed[SpacesKey].spaceId === this.props.FeedCalendar[ThisEE].spaceId) {
                var ThisMoment = moment(this.props.TimeNow, "DD/MM/YYYY").toDate().getTime()
                eeStart = dayjs(this.props.FeedCalendar[ThisEE].startDay).format('DD/MM/YYYY')
                eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
                eeEnd = dayjs(this.props.FeedCalendar[ThisEE].endDay).format('DD/MM/YYYY')
                eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
                if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment) {
                    MapSpacesInformation["Ongoing"] = "ThisIsOngoing";
                }
                else if  (eeStartMoment === ThisMoment || ThisMoment === eeEndMoment) {
                    MapSpacesInformation["Ongoing"] = "ThisIsOngoing";
                }
            }
        }
    

        if (MapSpacesInformation["lat"]) {
            if (!MapSet.has(MapSpacesInformation["name"])) {
                const Marker = ({ name, address, city, phonenumber, email, type, havingBreak, website, instagram, facebook, accessibility_info, toilet, other, entrance_fee, about, monday, tuesday, wednesday, thursday, friday, saturday, sunday, MarkerColor, spaceID}) => <div id="MarkerId" key={"MapSpacePinKey_"+spaceID} className={"MapPin all "+type} style={{backgroundColor:MarkerColor}} onClick={() => {this.handleMapSpaceEffect(name,  address, city, phonenumber, email, type, havingBreak, website, instagram, facebook, accessibility_info, toilet, other, entrance_fee, monday, tuesday, wednesday, thursday, friday, saturday, sunday, about, spaceID)}}><div key={"MapSpaceNameKey_"+spaceID} id={"MapSpaceTitleIdKey_"+spaceID} className="MapPinTitle">{name}</div></div>
                MapSpacesPinsAll.push(
                <Marker
                key={"MarkerKeyall_"+MapSpacesInformation["spaceId"]}
                lat={MapSpacesInformation["lat"]}
                lng={MapSpacesInformation["lng"]}
                spaceID={MapSpacesInformation["spaceId"]}
                name={MapSpacesInformation["name"]}
                address={MapSpacesInformation["address"]}
                city={MapSpacesInformation["city"]}
                phonenumber={MapSpacesInformation["phonenumber"]}
                email={MapSpacesInformation["email"]}
                type={MapSpacesInformation["type"]}
                havingBreak={MapSpacesInformation["havingBreak"]}
                website={MapSpacesInformation["website"]}
                instagram={MapSpacesInformation["instagram"]}
                facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                toilet={MapSpacesInformation["toilet"]}
                other={MapSpacesInformation["other"]}
                entrance_fee={MapSpacesInformation["entrance_fee"]}
                about={MapSpacesInformation["about"]}
                monday={MapSpacesInformation["monday"]}
                tuesday={MapSpacesInformation["tuesday"]}
                wednesday={MapSpacesInformation["wednesday"]}
                thursday={MapSpacesInformation["thursday"]}
                friday={MapSpacesInformation["friday"]}
                saturday={MapSpacesInformation["saturday"]}
                sunday={MapSpacesInformation["sunday"]}
                MarkerColor={MapLabelColour}/>
                )
                if (MapSpacesInformation["Ongoing"] === "ThisIsOngoing") {
                    MapSpacesPinsOngoing.push(
                    <Marker
                    key={"MarkerKeyongoing_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Konsthall") {
                    MapSpacesPinsKonsthall.push(
                    <Marker
                    key={"MarkerKeykonsthall_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Artist Run") {
                    MapSpacesPinsArtistRun.push(
                    <Marker
                    key={"MarkerKey_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Other") {
                    MapSpacesPinsOther.push(
                    <Marker
                    key={"MarkerKeyother_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Gallery") {
                    MapSpacesPinsGallery.push(
                    <Marker
                    key={"MarkerKeygallery_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Organization") {
                    MapSpacesPinsOrganization.push(
                    <Marker
                    key={"MarkerKeyrganisatin_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Museum") {
                    MapSpacesPinsMuseum.push(
                    <Marker
                    key={"MarkerKeymuseum_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
                if (MapSpacesInformation["type"] === "Art School") {
                    MapSpacesPinsArtSchool.push(
                    <Marker
                    key={"MarkerKeyartschool_"+MapSpacesInformation["spaceId"]}
                    lat={MapSpacesInformation["lat"]}
                    lng={MapSpacesInformation["lng"]}
                    spaceID={MapSpacesInformation["spaceId"]}
                    name={MapSpacesInformation["name"]}
                    address={MapSpacesInformation["address"]}
                    city={MapSpacesInformation["city"]}
                    phonenumber={MapSpacesInformation["phonenumber"]}
                    email={MapSpacesInformation["email"]}
                    type={MapSpacesInformation["type"]}
                    havingBreak={MapSpacesInformation["havingBreak"]}
                    website={MapSpacesInformation["website"]}
                    instagram={MapSpacesInformation["instagram"]}
                    facebook={MapSpacesInformation["facebook"]} accessibility_info={MapSpacesInformation["accessibility_info"]}
                    toilet={MapSpacesInformation["toilet"]}
                    other={MapSpacesInformation["other"]}
                    entrance_fee={MapSpacesInformation["entrance_fee"]}
                    about={MapSpacesInformation["about"]}
                    monday={MapSpacesInformation["monday"]}
                    tuesday={MapSpacesInformation["tuesday"]}
                    wednesday={MapSpacesInformation["wednesday"]}
                    thursday={MapSpacesInformation["thursday"]}
                    friday={MapSpacesInformation["friday"]}
                    saturday={MapSpacesInformation["saturday"]}
                    sunday={MapSpacesInformation["sunday"]}
                    MarkerColor={MapLabelColour}/>
                    )
                }
            }
            MapSet.add(MapSpacesInformation["name"])
        }
    }

    var SpacesAppearMap;
    if (this.state.SpacesAppearMap) {
        SpacesAppearMap = "All"
    } else {
        SpacesAppearMap = "Ongoing"
    }   

    var FilterJumpObjectListOutput = [];
    var KeySectionContent = [];
    var ReadMoreSpaceInformation;
    for (var ThisKey in FilterJumpObjectList) {
        KeySectionContent = [];
        ReadMoreSpaceInformation = {};
        for (var ElementsKey in FilterJumpObjectList[ThisKey]) {
            var SpaceElement = FilterJumpObjectList[ThisKey][ElementsKey]
            var DateEntry;
            for (SpacesAttribute in SpacesAttributes) {
                if (SpaceElement.spaceId === SpacesAttributes[SpacesAttribute].spaceId){
                    if (SpacesAttributes[SpacesAttribute].attributeId === 1) { 
                        ReadMoreSpaceInformation["website"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 2) { 
                        ReadMoreSpaceInformation["instagram"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 3) { 
                        ReadMoreSpaceInformation["facebook"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 4) { 
                        ReadMoreSpaceInformation["accessibility_info"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 5) { 
                        ReadMoreSpaceInformation["toilet"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 6) { 
                        ReadMoreSpaceInformation["other"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 7) { 
                        ReadMoreSpaceInformation["entrance_fee"] = SpacesAttributes[SpacesAttribute].attributeValue
                    }
                    if (SpacesAttributes[SpacesAttribute].attributeId === 8) { 
                        ReadMoreSpaceInformation["about"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 9) { 
                        ReadMoreSpaceInformation["lat"] = SpacesAttributes[SpacesAttribute].attributeValue
                    } 
                    if (SpacesAttributes[SpacesAttribute].attributeId === 10) {
                        ReadMoreSpaceInformation["lng"] = SpacesAttributes[SpacesAttribute].attributeValue
                    }
                }
            }

            for (DateEntry in SpacesTime) {
                if (SpacesTime[DateEntry].spaceId === SpaceElement.spaceId) {
                    if (SpacesTime[DateEntry].daynumber === 1) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["monday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["monday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["monday"] = "null";
                        }
                    } 
                    if (SpacesTime[DateEntry].daynumber === 2) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["tuesday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["tuesday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["tuesday"] = "null";
                        }
                    } 
                    if (SpacesTime[DateEntry].daynumber === 3) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["wednesday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["wednesday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["wednesday"] = "null";
                        }
                    } 
                    if (SpacesTime[DateEntry].daynumber === 4) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["thursday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["thursday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["thursday"] = "null";
                        }
                    } 
                    if (SpacesTime[DateEntry].daynumber === 5) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["friday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["friday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["friday"] = "null";
                        }
                    } 
                    if (SpacesTime[DateEntry].daynumber === 6) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["saturday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["saturday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["saturday"] = "null";
                        }
                    } 
                    if (SpacesTime[DateEntry].daynumber === 7) {
                        if (SpacesTime[DateEntry].opentime) {
                            ReadMoreSpaceInformation["sunday"] = SpacesTime[DateEntry].opentime.slice(0,5) + "-" + SpacesTime[DateEntry].closetime.slice(0,5)
                        } else if (SpacesTime[DateEntry].byAppointment) {
                            ReadMoreSpaceInformation["sunday"] = "By Appt.";
                        } else {
                            ReadMoreSpaceInformation["sunday"] = "null";
                        }
                    }
                }
            }

            ThisMoment = moment(this.props.TimeNow, "DD/MM/YYYY").toDate().getTime()
            var CurrentlyShowing = [];
            var PreviouslyShown = []
            for (ThisEE in this.props.FeedCalendar) {
                eeStart = "";
                eeStartMoment = "";
                eeEnd = "";
                eeEndMoment = "";
                if (this.props.FeedCalendar[ThisEE].eeTypeId === 3) {
                    if (SpaceElement.spaceId === this.props.FeedCalendar[ThisEE].spaceId) {
                        var artistList = [];
                        for (var thisArtst in this.props.FeedArtists) {
                            if (this.props.FeedArtists[thisArtst].eEid === this.props.FeedCalendar[ThisEE].eeId && this.props.FeedArtists[thisArtst].roletext === "Artist") {
                                artistList.push(this.props.FeedArtists[thisArtst].contactName)
                            }
                        }
                        eeStart = dayjs(this.props.FeedCalendar[ThisEE].startDay).format('DD/MM/YYYY')
                        eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
                        eeEnd = dayjs(this.props.FeedCalendar[ThisEE].endDay).format('DD/MM/YYYY')
                        eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
                        if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment) {
                            CurrentlyShowing.push(<div>{this.props.FeedCalendar[ThisEE].title}, <i>{artistList.join(", ")}</i></div>)
                        }
                        if (eeEndMoment < ThisMoment ) {
                            PreviouslyShown.push(<div>{this.props.FeedCalendar[ThisEE].title}, <i>{artistList.join(", ")}</i></div>)
                        }
                    }
                }
            }

            var CurrentlyShowinigOut =  "";
            if (CurrentlyShowing.length > 0 ) {
                CurrentlyShowinigOut = <div key={"SpacesCurrenlyShowing_"+SpaceElement.spaceId} className="SpacesCurrenlyShowing"><div key={"CurrentlyShowingTitle_"+SpaceElement.spaceId} className="CurrentlyShowingTitle">Currently showing</div><div key={"CurrentlyShowingItems_"+SpaceElement.spaceId} className="CurrentlyShowingItems">{CurrentlyShowing}</div></div>
            }

            var PreviouslyShownOut =  "";
            if (0 < PreviouslyShown.length && PreviouslyShown.length < 3) {
                PreviouslyShownOut = <div key={"SpacesPreviouslyShown_"+SpaceElement.spaceId} className="SpacesPreviouslyShown"><div key={"PreviouslyShownTitle_"+SpaceElement.spaceId} className="PreviouslyShownTitle">Previously shown</div><div key={"PreviouslyShownItems_"+SpaceElement.spaceId} className="PreviouslyShownItems">{PreviouslyShown}</div></div>
            } else if (3 <= PreviouslyShown.length) {
                PreviouslyShownOut = <div key={"SpacesPreviouslyShown_"+SpaceElement.spaceId} className="SpacesPreviouslyShown"><div key={"PreviouslyShownTitle_"+SpaceElement.spaceId} className="PreviouslyShownTitle">Previously shown</div><div key={"PreviouslyShownItems_"+SpaceElement.spaceId} className="PreviouslyShownItems">{PreviouslyShown.slice(0,3)}<div className={"seeAllButton_"+SpaceElement.spaceId} id={SpaceElement.spaceId} onClick={event => {this.seeAllButton(event.currentTarget.id)}} style={{textDecoration: "underline", marginTop: "3px"}}>see all previous shows</div><div id={"seeAllCont_"+SpaceElement.spaceId} style={{display: "none"}}>{PreviouslyShown.slice(3)}<div id={"seeLessButton_"+SpaceElement.spaceId} onClick={event => {this.seeLessButton(event.currentTarget.id)}} style={{textDecoration: "underline", marginTop: "3px"}}>see less</div></div></div></div>
            }

            var PhonenumberContent;
            var EmailContent;
            var WebsiteContent;
            var InstagramContent;
            var FacebookContent;
            var AboutImageContent
            if (SpaceElement.phonenumber) {
                PhonenumberContent = <div key={"SpacePhoneKey_"+SpaceElement.spaceId} className="SpacesDetailPhone">{SpaceElement.phonenumber}</div>
            }
            if (SpaceElement.email) {
                EmailContent = <div key={"SpaceEmailKey_"+SpaceElement.spaceId} className="SpacesDetailEmail">{SpaceElement.email}<div key={"SpaceEmailSymbolCont_"+SpaceElement.spaceId} className="EmailCopySymbol"><BiCopy className="copysymbol" id={SpaceElement.email} key={"SpaceEmailSymbol_"+SpaceElement.spaceId} onClick={(event) => navigator.clipboard.writeText(event.currentTarget.id)}/></div></div>
            }
            if (ReadMoreSpaceInformation["website"]) {
                WebsiteContent = <a key={"SpaceWebsiteKey_"+SpaceElement.spaceId} target="_blank" rel="noreferrer" href={ReadMoreSpaceInformation["website"]} className="SpacesDetailWebsite">website <span className="sr-only">↗</span></a>
            }
            if (ReadMoreSpaceInformation["instagram"]) {
                InstagramContent = <a key={"SpaceInstagramKey_"+SpaceElement.spaceId} target="_blank" rel="noreferrer" href={"https://www.instagram.com/"+ReadMoreSpaceInformation["instagram"]} className="SpacesDetailInstagram">instagram <span className="sr-only">↗</span></a>
            }
            if (ReadMoreSpaceInformation["facebook"]) {
                FacebookContent = <a key={"SpaceFacebookKey_"+SpaceElement.spaceId} target="_blank" rel="noreferrer"  href={ReadMoreSpaceInformation["facebook"]} className="SpacesDetailFacebook">facebook <span className="sr-only">↗</span></a>
            }
            ReadMoreSpaceInformation["image"]=null;//="https://optimise2.assets-servd.host/maniacal-finch/production/animals/reticulated-giraffe-01-01.jpg?w=1200&auto=compress%2Cformat&fit=crop&dm=1658951271&s=179de228b20aafcd748a5f0dc8fde776";
            ReadMoreSpaceInformation["caption"]="woo";
            if (ReadMoreSpaceInformation["image"]) {
                AboutImageContent = <div key={"SpaceReadDescriptionImageKey_"+SpaceElement.spaceId} className="SpacesDetailDescriptionImage"><div key={"SpaceReadDetailAboutKey_"+SpaceElement.spaceId} className="SpacesDetailAbout" style={{width:"49%"}} dangerouslySetInnerHTML={{__html: ReadMoreSpaceInformation["about"]}}></div><div key={"SpaceReadDetailImgCaptionKey_"+SpaceElement.spaceId}  className="SpacesDetailImgCaption"><div key={"SpaceReadDetailImgContKey_"+SpaceElement.spaceId} className="SpacesDetailImgCont"><img key={"SpaceReadImgKey_"+SpaceElement.spaceId} src={ReadMoreSpaceInformation["image"]} className="SpacesDetailImg" alt="SpaceBuilding"/></div><div key={"SpaceReadCaptionKey_"+SpaceElement.spaceId} className="SpacesDetailCaption">{ReadMoreSpaceInformation["caption"]}</div></div></div>
            } else {
                AboutImageContent = <div key={"SpaceReadDescriptionImageKey_"+SpaceElement.spaceId}  className="SpacesDetailDescriptionImage"><div key={"SpaceReadDetailAboutKey_"+SpaceElement.spaceId} className="SpacesDetailAbout" style={{width:"100"}}  dangerouslySetInnerHTML={{__html: ReadMoreSpaceInformation["about"]}}></div></div>
            }

            var TimeExtraContent = [];
            if (!ReadMoreSpaceInformation["monday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailMond_"+SpaceElement.spaceId} className="SpacesDetailMond"><b>Mon:</b> {ReadMoreSpaceInformation["monday"]}</div>)
            }
            if (!ReadMoreSpaceInformation["tuesday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailTues_"+SpaceElement.spaceId} className="SpacesDetailTues"><b>Tue:</b> {ReadMoreSpaceInformation["tuesday"]}</div>)
            }
            if (!ReadMoreSpaceInformation["wednesday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailWeds_"+SpaceElement.spaceId} className="SpacesDetailWeds"><b>Wed:</b> {ReadMoreSpaceInformation["wednesday"]}</div>)
            }
            if (!ReadMoreSpaceInformation["thursday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailThur_"+SpaceElement.spaceId} className="SpacesDetailThurs"><b>Thu:</b> {ReadMoreSpaceInformation["thursday"]}</div>)
            }
            if (!ReadMoreSpaceInformation["friday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailFrid_"+SpaceElement.spaceId} className="SpacesDetailFri"><b>Fri:</b> {ReadMoreSpaceInformation["friday"]}</div>)
            }
            if (!ReadMoreSpaceInformation["saturday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailSatu_"+SpaceElement.spaceId} className="SpacesDetailSat"><b>Sat:</b> {ReadMoreSpaceInformation["saturday"]}</div>)
            }
            if (!ReadMoreSpaceInformation["sunday"].includes("null")) {
                TimeExtraContent.push(<div key={"SpaceReadDetailSund_"+SpaceElement.spaceId} className="SpacesDetailSund"><b>Sun:</b> {ReadMoreSpaceInformation["sunday"]}</div>)
            }
            
            var AccessContent;
            if (ReadMoreSpaceInformation["accessibility_info"]) {
                AccessContent = <a key={"SpaceReadDetailAccess_"+SpaceElement.spaceId} className="SpacesDetailAccess" href={ReadMoreSpaceInformation["accessibility_info"]} target="_blank" rel="noreferrer">accessibility info <span className="sr-only">↗</span></a>
            }
            var ToiletContent;
            if (ReadMoreSpaceInformation["toilet"]) {
                ToiletContent = <div key={"SpaceReadDetailToilet_"+SpaceElement.spaceId} className="SpacesDetailToilet"><b>Toilet:</b> {ReadMoreSpaceInformation["toilet"]}</div>
            }
            var EntranceFeeContent;
            if (ReadMoreSpaceInformation["entrance_fee"] === "free") {
                EntranceFeeContent = <div key={"SpaceReadDetailFree_"+SpaceElement.spaceId} className="SpacesDetailFree"><b>Admission:</b> Free</div>
            } else {
                EntranceFeeContent = <a key={"SpaceReadDetailCost_"+SpaceElement.spaceId} className="SpacesDetailCost" href={ReadMoreSpaceInformation["entrance_fee"]} target="_blank" rel="noreferrer">cost info <span className="sr-only">↗</span></a>
            }
            
            var SpaceDetailMap = [];
            if (ReadMoreSpaceInformation["lat"]) {
                const DetailMarker = ({ name, spaceID }) => <div key={"SpaceDetailPinKey_"+spaceID} className={"PinDetail"}><div key={"SpaceDetailNameKey_"+spaceID} id={"SpaceDetailTitleId_"+spaceID} className="PinDetailTitle">{name}</div></div>
                SpaceDetailMap.push(<div key={"SpacesDetailMapsCont_"+SpaceElement.spaceId} className="SpacesDetailMapsCont">
                <GoogleMapReact key={"SpacesGoogleMapReact_"+SpaceElement.spaceId}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                center={{lat: parseFloat(ReadMoreSpaceInformation["lat"]), lng: parseFloat(ReadMoreSpaceInformation["lng"])}}
                zoom={14}
                options={{mapId:"eddf51aff07de27a"}}
                >
                    <DetailMarker key={"DetailMarkerKey_"+SpaceElement.spaceId}
                    lat={parseFloat(ReadMoreSpaceInformation["lat"])}
                    lng={parseFloat(ReadMoreSpaceInformation["lng"])}
                    name={SpaceElement.name}
                    spaceID={SpaceElement.spaceId}/>
                </GoogleMapReact>
            </div>)}
            var spaceDesktopHalf = "";
            if (window.innerWidth < 720) {
                KeySectionContent.push(
                    <div key={"SpaceContainerReadKey_"+SpaceElement.spaceId} id={SpaceElement.name} className="SpaceContainer">
                        <div key={"SpaceContentsReadKey_"+SpaceElement.spaceId} className="SpaceContents">
                            <div key={"SpaceGalleryTypeContReadKey_"+SpaceElement.spaceId} className="SpaceGalleryTypeCont">
                                <div key={"SpaceNameReadKey_"+SpaceElement.spaceId} className="SpaceName">{SpaceElement.name}</div>
                                <div key={"SpaceTypeReadKey_"+SpaceElement.spaceId} className="SpaceType">{SpaceElement.type}</div>
                            </div>
                            <div key={"SpaceAddressReadContKey_"+SpaceElement.spaceId} className="SpaceAddressReadCont">
                                <div key={"SpaceAddressReadKey_"+SpaceElement.spaceId} className="SpaceAddress">{SpaceElement.address}, {SpaceElement.city}</div>
                                <a key={"SpaceReadMoreKey_"+SpaceElement.spaceId} href={"#"+SpaceElement.name} className={"SpaceReadMore "+SpaceElement.spaceId} id={SpaceElement.spaceId} onClick={event => {document.getElementById("ReadMore_"+event.currentTarget.id).style.display = "flex";document.getElementsByClassName("SpaceReadMore "+event.currentTarget.id)[0].style.display = "none";document.getElementsByClassName("SpaceReadLess "+event.currentTarget.id)[0].style.display = "flex"}}>read more</a>
                                <div key={"SpaceReadLessKey_"+SpaceElement.spaceId} className={"SpaceReadLess "+SpaceElement.spaceId} id={SpaceElement.spaceId} onClick={event => { var thisId = document.getElementById("ReadMore_"+event.currentTarget.id);thisId.style.display = "none";document.getElementsByClassName("SpaceReadMore "+event.currentTarget.id)[0].style.display = "flex";document.getElementsByClassName("SpaceReadLess "+event.currentTarget.id)[0].style.display = "none"}}>read less</div>
                            </div>
                        </div>
                        <div key={"SpacesDetailExtraContentReadKey_"+SpaceElement.spaceId} className="SpacesDetailExtraContent" style={{display: "none"}} id={"ReadMore_"+SpaceElement.spaceId}>
                            {CurrentlyShowinigOut}
                            <div key={"SpacesDetailCommLinksReadKey_"+SpaceElement.spaceId} className="SpacesDetailCommLinks">
                                <div key={"SpacesDetailPhoneEmailReadKey_"+SpaceElement.spaceId} className="SpacesDetailPhoneEmail">
                                    {PhonenumberContent}
                                    {EmailContent}
                                </div>
                                <div key={"SpacesDetailLinksReadKey_"+SpaceElement.spaceId} className="SpacesDetailLinks">
                                    {WebsiteContent}
                                    {InstagramContent}
                                    {FacebookContent}
                                </div>
                            </div>
                            {AboutImageContent}
                            <div key={"SpacesDetailTimeExtraReadKey_"+SpaceElement.spaceId} className="SpacesDetailTimeExtra">
                                <div key={"SpacesDetailTimeReadKey_"+SpaceElement.spaceId} className="SpacesDetailTime">
                                    {TimeExtraContent}
                                </div>
                                <div key={"SpacesDetailExtraReadKey_"+SpaceElement.spaceId} className="SpacesDetailExtra">
                                    {AccessContent}
                                    {ToiletContent}
                                    {EntranceFeeContent}
                                </div>
                            </div>
                            {SpaceDetailMap}
                            {PreviouslyShownOut}
                        </div>
                    </div>
                )
                } else {
                KeySectionContent.push(
                    <div key={"SpaceContainerReadKey_"+SpaceElement.spaceId} id={SpaceElement.name} className="SpaceContainer">
                        <div key={"SpaceContentsReadKey_"+SpaceElement.spaceId} className="SpaceContents">
                            <div key={"SpaceGalleryTypeContReadKey_"+SpaceElement.spaceId} className="SpaceGalleryTypeCont">
                                <div key={"SpaceNameReadKey_"+SpaceElement.spaceId} className="SpaceName">{SpaceElement.name}</div>
                                <div key={"SpaceTypeReadKey_"+SpaceElement.spaceId} className="SpaceType">{SpaceElement.type}</div>
                            </div>
                            <div key={"SpaceAddressReadContKey_"+SpaceElement.spaceId} className="SpaceAddressReadCont">
                                <div key={"SpaceAddressReadKey_"+SpaceElement.spaceId} className="SpaceAddress">{SpaceElement.address}, {SpaceElement.city}</div>
                                <div className="SpaceReadMore" id={SpaceElement.spaceId} onClick={event => {this.loadSpacesDetailDesktop(event.currentTarget.id)}}>read more</div>
                            </div>
                        </div>
                    </div>
                    )
                spaceDesktopHalf = <div className="spaceDetailContent" style={{display: ListVis}}>{this.state.spaceDetailContent}</div>
                }
            }

        FilterJumpObjectListOutput.push(
        <div key={"LetterJumpSection_" + ThisKey} className="SpaceLetterSection">
            <div key={"SpaceKeyTitleKey_" + ThisKey} className="SpaceKeyTitle" id={ThisKey}>{ThisKey}</div>
            <div key={"SpaceKeycontentKey_" + ThisKey} className="SpaceKeycontent">
                {KeySectionContent}
            </div>
        </div>)
    }

    return (
    <div className="SpacesContent" style={{display: SpacePage}}>
        <div className="SpacesFilterBar">
            <div className="SpacesType" onClick={() => {this.toggleSpacesType();}}><a href="#SpacesCalendarContent">{SpacesType}</a></div>
            <div className="SpacesSort" style={{display:SpacesSortDisplay}}>
                <div className="SpacesSortList" style={{display: ListVis}}>{SpacesSortList}</div>
                <div className="SpacesSortMap" style={{display: MapVis}}><Select isSearchable={false} options={InstituteOptionsMap} defaultValue={defaultInstituteMap} className="react-select-institute" classNamePrefix="react-select-institute" onChange={(event) => {this.handleMapLabelVisibility(event.value)}}/></div>
            </div>
            <div className="SpacesApppear">
                <div className="SpacesApppearList" onClick={() => {this.toggleSpacesApppearList();}} style={{display: ListVis}}>{SpacesApppearList}</div>
                <div className="SpacesAppearMap" onClick={() => {this.toggleSpacesAppearMap();}} style={{display: MapVis}}>{SpacesAppearMap}</div>
            </div>
        </div>
        <div className="SpacesTypeContent">
            <div style={{display: "flex", flexDirection: "row"}}>
                <div className="SpacesListContent" style={{display: ListShow}}>
                    {FilterJumpObjectListOutput}
                </div>
                <div className="SpacesCalendarContent" style={{display: MapShow}} id="SpacesCalendarContent">
                    <div className="SpacesCalendarCont">
                        <VenueGoogleMap SpacesMapType={this.state.SpacesMapType} MapSpacesPinsAll={MapSpacesPinsAll} MapSpacesPinsOngoing={MapSpacesPinsOngoing} MapSpacesPinsKonsthall={MapSpacesPinsKonsthall} MapSpacesPinsArtistRun={MapSpacesPinsArtistRun} MapSpacesPinsGallery={MapSpacesPinsGallery} MapSpacesPinsOther={MapSpacesPinsOther} MapSpacesPinsOrganization={MapSpacesPinsOrganization} MapSpacesPinsMuseum={MapSpacesPinsMuseum} MapSpacesPinsArtSchool={MapSpacesPinsArtSchool}/>
                    </div>
                    <div className="SpacesCalendarListCont">
                        {this.state.LowerSpaceContent}
                    </div>
                </div>
                {spaceDesktopHalf}
            </div>
        </div>
    </div>
    )
}
}
