import React from "react";
import './css/global.css';
import BasePage from "./components/BasePage"
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga4';

function App() {
  React.useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  const allConsentGranted = () => {
    window.gtag('consent', 'update', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted',
      'ad_personalization': 'granted',
      'ad_user_data': 'granted'
    });
  };
  return (
  <div>
    <BasePage/>
    <CookieConsent buttonText="All good" onAccept={allConsentGranted} style={{ backgroundColor: "rgba(255, 255, 255, 0.95)", color: "black", borderTop: "2px solid #89cca3" }} buttonStyle={{ backgroundColor: "#019449", color: "white", fontWeight:"bold"}}>This website uses cookies to enhance the user experience.</CookieConsent>
  </div>
  );
}

export default App;