import React, {Component} from "react";
import "../css/global.css";

export default class TermsPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
  };
}

render() {
    return (
    <div className="TermsPageCont">
        <div key={"menu_privacy"} className="TermsContent">
            <h1 key={"privacy_title_1"} className="privacy_title">Privacy Policy</h1>
            <p key={"privacy_text_1"} className="privacy_text">We at seaslug.se take the utmost precautions when handling your personal data. We treat your personal data in accordance with the legal regulations of the relevant data European Data Protection Regulation (GDPR) and this privacy policy.</p>
            <p key={"privacy_text_2"} className="privacy_text">This privacy policy covers the use of seaslug.se’s digital offerings, including our instagram, and all other Internet-enabled devices.</p>
            <p key={"privacy_text_3"} className="privacy_text">The digital offers may contain links to other websites to which this privacy policy does not apply.</p>
            <h2 key={"privacy_subtitle_1"} className="privacy_subtitle">1. Data processing for communication with you</h2>
            <p key={"privacy_text_4"} className="privacy_text">We process your communication data development suggestions and/or to be able to contact you. Personal data that you provide us with by e-mail will only be considered when in correspondence with you.</p>
            <p key={"privacy_text_5"} className="privacy_text">If you would like to contact us, our formal email is contact@seaslug.se.</p>
            <h2 key={"privacy_subtitle_3"} className="privacy_subtitle">2. Cookies</h2>
            <p key={"privacy_text_14"} className="privacy_text">We use cookies on some of our Internet pages in order to be able to offer you website-specific services, to be able to recognise you on a repeated visit to our website, and/or to be able to adapt to your personal preferences.</p>
            <p key={"privacy_text_15"} className="privacy_text">Cookies are small text files that are stored on a visitor's computer and contain data on the respective user to enable access to various functions. Our website uses session cookies as well as permanent cookies. A session cookie is temporarily stored on the computer you use while you navigate through the website. A session cookie is deleted when you close your internet browser or when your session expires after a certain time. A permanent cookie remains on your computer until it is deleted. Storing a cookie ensures that you do not have to re-enter your personal settings and preferences each time you visit. This saves you time and makes using our website more convenient for you.</p>
            <p key={"privacy_text_16"} className="privacy_text">We may work with third parties on some of our websites, and therefore, when you visit such a website, cookies from partner companies may be stored on your hard drive (third-party cookies). We inform you below about the use of such cookies and the scope of the data collected in each case.</p>
            <p key={"privacy_text_17"} className="privacy_text">You can delete permanently installed cookies via the settings of your browser. Most browsers accept cookies automatically - so if you wish to suppress the use of cookies, you may need to actively delete or block cookies or prevent the storage of cookies by changing the settings of your browser software. Please note, however, that if you refuse the use of cookies, you may still be able to visit our website, but some functions may be impaired in their operation.</p>
            <p key={"privacy_text_18"} className="privacy_text">Insofar as only necessary cookies are used, the data processing is based on Art. 6 Para. 1 S. 1 lit. f GDPR, which allows the processing of data to safeguard the legitimate interests of the data controller, unless the interests or fundamental rights and freedoms of the data subject outweigh the data processing. Our interest lies in the better design and improvement of the usability of our website. For the use of unnecessary cookies we may obtain your consent. The data processing will then be carried out on the basis of your consent (Art. 6 Para. 1 S. 1 lit. a GDPR). You can revoke your consent at any time. The legality of the data processing operations that have already taken place remains unaffected by the revocation.</p>
            <h2 key={"privacy_subtitle_4"} className="privacy_subtitle">3. Google Maps</h2>
            <p key={"privacy_text_19"} className="privacy_text">This website uses the map service Google Maps via an API. The provider is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
            <p key={"privacy_text_20"} className="privacy_text">To use the functions of Google Maps it is necessary to store your IP address. This information is usually transferred to a Google server in the USA and stored there. The provider of this site has no influence on this data transfer. For more information on how Google handles user data, please refer to Google's privacy policy: https://www.google.de/intl/de/policies/privacy/.</p>
            <p key={"privacy_text_21"} className="privacy_text">Important in connection with data processing in the USA: According to the European Court of Justice, the data protection standard in the USA is insufficient and there is a risk that your data will be processed by US authorities, for control and monitoring purposes and possibly without any possibility of legal recourse.</p>
            <p key={"privacy_text_22"} className="privacy_text">The processing takes place on the basis of your consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR. You can revoke your consent at any time. You can revoke your consent by sending a message to the contact data mentioned under point 1 (e.g. e-mail). The legality of the data processing operations that have already taken place remains unaffected by the revocation.</p>
            <h2 key={"privacy_subtitle_5"} className="privacy_subtitle">4. Data processing within the scope of our online presence at Instagram</h2>
            <p key={"privacy_text_23"} className="privacy_text">We have an online presence with Instagram, a platform of Facebook Ireland Ltd., 4 Grand Canal Square, Dublin 2, Ireland, to communicate with you and keep you informed of news. In addition to our interaction with you, Instagram processes data from visitors to its websites for the purposes of market research and advertising, i.e., a user profile may be created by the social network operator based on the visitor's visit or usage behavior and the preferences and interests of the visitor. Such user profiles may be used, among other things, to display advertisements within Instagram and possibly on other websites to users individually adapted to the respective user profile. Cookies (see above) may be stored on visitors' devices to collect data on user behavior. The collection of this data, especially for logged in members of the respective social network, can also be realized across several browsers and/or devices used by a user. Even if a visitor does not have a profile with Instagram, it cannot be ruled out that personal data on this visitor may be stored when visiting the respective website. Requests for information regarding the data stored by Instagram via our online presence or the exercise of other related rights of affected persons (see below) can be addressed to the provider. Only this provider has access to the respective data stored there and can provide the corresponding information, etc. Regarding the purpose and scope of data processing by Instagram, we refer to the data protection information and contact details:</p>
            <p key={"privacy_text_24"} className="privacy_text">Instagram</p>
            <p key={"privacy_text_25"} className="privacy_text">Facebook Ireland Ltd.</p>
            <p key={"privacy_text_26"} className="privacy_text">4 Grand Canal Square</p>
            <p key={"privacy_text_27"} className="privacy_text">Dublin 2, Ireland</p>
            <p key={"privacy_text_28"} className="privacy_text">Privacy policy: https://www.facebook.com/about/privacy/</p>
            <p key={"privacy_text_29"} className="privacy_text">Contact: https://www.facebook.com/help/contact/540977946302970</p>
            <p key={"privacy_text_30"} className="privacy_text">Opt-out: https://www.facebook.com/settings?tab=ads</p>
            <p key={"privacy_text_31"} className="privacy_text">The basis for the data processing is Art. 6 para. 1 sentence 1 letter b GDPR, which permits the processing of data for the performance of a contract or pre-contractual measures, insofar as data is processed in accordance with the Instagram/Facebook terms of use, otherwise, if applicable, Art. 6 para. 1 sentence 1 letter f GDPR, which permits the processing of data for the purpose of safeguarding the legitimate interests of the person responsible, provided that the interests or fundamental rights and freedoms of the person concerned do not prevail. Our interest lies in providing content and communication with Facebook users and in improving the reach and effectiveness of our posts.</p>
            <h2 key={"privacy_subtitle_6"} className="privacy_subtitle">5. Data processing to fulfil legal obligations</h2>
            <p key={"privacy_text_32"} className="privacy_text">In addition, we process your data to fulfil legal obligations (e.g. regulatory requirements, commercial and tax law storage and verification obligations).</p>
            <p key={"privacy_text_33"} className="privacy_text">The basis for this data processing is Art. 6 para. 1 sentence 1 lit. c GDPR, which permits processing to fulfil a legal obligation.</p>

            <h2 key={"privacy_subtitle_7"} className="privacy_subtitle">6. Categories of recipients of the personal data</h2>
            <p key={"privacy_text_34"} className="privacy_text">Your personal data will only be passed on or otherwise transferred to third parties if this is necessary or if you have given your prior consent or if there is a legal basis for the transfer.</p>
            <p key={"privacy_text_35"} className="privacy_text">Service providers who support us in providing our services to you are sales and marketing partners, software (SaaS) providers, IT service providers, especially service providers for software and hardware maintenance, hosting providers and e-mail service providers.</p>
            <h2 key={"privacy_subtitle_8"} className="privacy_subtitle">7. Duration of data storage</h2>
            <p key={"privacy_text_36"} className="privacy_text">In principle, we delete your data as soon as they are no longer required for the above-mentioned purposes, unless temporary storage is still necessary.</p>
            <h2 key={"privacy_subtitle_9"} className="privacy_subtitle">8. Rights of data subjects</h2>
            <p key={"privacy_text_37"} className="privacy_text">Within the framework of the applicable legal provisions, you have the right to obtain information free of charge at any time about your personal data stored by us, its origin and recipients and the purpose of the data processing and, if applicable, a right to correct, block or delete this data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time using the contact details given in clause 1. You may also have a right to restrict the processing of your data and a right to have the data provided by you released in a structured, common and machine-readable format. If you have given us your consent to process personal data for specific purposes, you can revoke your consent at any time with effect for the future. If we process your data to protect legitimate interests, you may object to this processing for reasons arising from your particular situation. In addition, you have the possibility of contacting a data protection supervisory authority (right of appeal).</p>
        </div>
    </div>
    )
}
}
