import React, {Component} from "react";
import { BsFillRssFill } from 'react-icons/bs';
import "../css/global.css";

export default class BlogPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
  };
}

render() {
  const BlogContent = [
    {
      title: "Slugs that slime together",
      author: "Nathalie Viruly",
      description: "“We are at a loss. A lemon, a seven and a cherry. Stockholm ain't no Las Vegas; it doesn’t want to be. Whoever set up the lights in this city was a functional guy and he still holds the switch. All the lights are turned off after midnight, especially after mid-summer when he goes to his cabin — when everything grinds to a halt. […]”",
      date: "16/08/2024",
      link: "https://seaslug.substack.com/p/where-have-all-the-gamblers-gone"
    },
    {
      title: "Slugs that slime together",
      author: "Seaslug Art Guide",
      description: "A few months back we were interviewed for Supermarket #14 about all things Seaslug. Now that the issue is out, listen in for Laus’, Ali’s & Katinka’s thoughts on our work, the Stockholm Art Scene + more! ",
      date: "07/06/2024",
      link: "https://seaslug.substack.com/p/slugs-that-slime-together"
    },
    {
      title: "Suicide Aesthetics",
      author: "Jaana-Kristiina Alakoski",
      description: "How did we get here, where are we, and what now?",
      date: "04/05/2024",
      link: "https://seaslug.substack.com/p/suicide-aesthetics-by-jaana-kristiina-alakoski"
    },
    {
      title: "Shell Schwoon",
      author: "Ali Razzak",
      description: "Recounting a string of awkward date nights, a sketch of Stockholm’s gallery scene shines through Razzak’s (mis)adventures.",
      date: "02/04/2024",
      link: "https://seaslug.substack.com/p/shell-schwoon-by-ali-razzak"
    },
    {
      title: "AN EXERCISE IN RADICAL HAPPINESS",
      author: "Alberte Skronski",
      description: "It’s dark and cold outside but we have something that can light up your mood! Artist Alberte Skronski is presenting drawings and an exercise for you to experience her mantra of RADICAL HAPPINESS",
      date: "21/12/2023",
      link: "https://seaslug.substack.com/p/an-exercise-in-radical-happiness"
    },
    {
      title: "Re: Our collaboration",
      author: "Yul Cho",
      description: "Dear Ifra, I’ve been meaning to dedicate some time to write to you about this journey we’ve been on together as close-enough, because honestly, it’s been quite a ride.",
      date: "31/10/2023",
      link: "https://seaslug.substack.com/p/re-our-collaboration"
    },
    {
      title: "Silencing Storms",
      author: "Joel Albinsson",
      description: "On a Wednesday afternoon in early August, as the storm Hans swept over Sweden, I marched over to the ABF building in central Stockholm. Determined to reduce the long list of unseen exhibitions that had accumulated over the course of the summer, I descended the stairs toward the art space Mint. There, I encountered Cara Tolmie’s exhibition Cascade Bend Chamber.",
      date: "17/10/2023",
      link: "https://seaslug.substack.com/p/silencing-storms"
    },
    {
      title: "Eco-chamber for heartbroken art",
      author: "Linda Louise Karlsson",
      description: "Walking home through a deserted Stockholm on a summer evening in July, I noticed the patches of wild grass and living meadow between the building blocks. Call it slackness or resignation, it might be a simple gesture, but yet it was something.",
      date: "28/09/2023",
      link: "https://seaslug.substack.com/p/eco-chamber-for-heartbroken-art"
    },
    {
      title: "On Arrival",
      author: "Ifra Shariq, close-enough",
      description: "Ifra Shariq has composed a reflective poem titled On Arrival, in which she shares her experience of navigating the art world. The poem expresses her emotions upon entering these unfamiliar spaces and reflects on the momentary feeling of discomfort that arises on arrival. It draws inspiration from Sara Ahmed’s article “A Phenomenology of Whiteness,” which delves into the idea that the presence of non-white bodies can induce disorientation and challenge the established order.",
      date: "11/09/2023",
      link: "https://seaslug.substack.com/p/on-arrival"
    },
    {
      title: "Brushing Up on Inclusion",
      author: "Yul Cho, close-enough",
      description: "Building on the understanding derived from close-enough’s previous survey, the research paper by Yul Cho, “Brushing Up on Inclusion: An Exploration of Complex Inequalities within the Swedish Art World,” was produced as a response to the issue of segregation within the Swedish art world. The aim of this research is not only to draw attention to materials that have dealt with similar issues, but also to provide a comprehensive theoretical framework for addressing this problem. The paper serves as an exploratory overview of the issue, as well as a means of anchoring our understanding of the topic.",
      date: "11/09/2023",
      link: "https://seaslug.substack.com/p/brushing-up-on-inclusion"
    },
    {
    title: "Seaslug Art Guide",
    author: "Katinka Saarnak",
    description: "We started working on Seaslug Art Guide in September 2021 when we had all just moved to Stockholm. Laus Østergaard and I moved here to study at the Curating Art Program at Stockholm University, and Ali Razzak to work as a data scientist and bioinformatician at SciLifeLab at Karolinska Institutet. Despite our different backgrounds—I myself came from Art History and art consultancy in Denmark, Laus Østergaard from a BFA at Bergen Art Academy, and Ali Razzak working with data science in the medical fields—we all had an interest in art and exhibitions.",
    date: "29/08/2023",
    link: "https://seaslug.substack.com/p/seaslug-art-guide"
    }]
  var BlogDisplay;

  let blogcontents = [];
  for (var entry in BlogContent) {
    blogcontents.push(<div key={BlogContent[entry].title} className="BlogEntryCont"><div className="BlogEntryContent">
      <div className="BlogTitle">{BlogContent[entry].title}</div> 
      <div className="BlogAuthor">By {BlogContent[entry].author}</div>
      <div className="BlogDescription" dangerouslySetInnerHTML={{__html: BlogContent[entry].description}}></div>
      <div className="DateLinkCont"><div className="BlogDate">Published {BlogContent[entry].date}</div><a className="BlogRead"href={BlogContent[entry].link} target="_blank" rel="noreferrer"><u>read more</u> <span className="sr-only">↗</span></a></div></div></div>)
  }

  if (this.props.BlogPageState) {
    BlogDisplay = "block"
  } else {
    BlogDisplay = "none"
  }
    return (
    <div className="BlogContent" style={{display: BlogDisplay}}>
      <div className="BlogFilterBar">
        <a className="SubStackLink" href="https://seaslug.substack.com/" target="_blank" rel="noreferrer"><u>Read on substack</u> </a>
        <a className="SubStackRss" href="https://seaslug.substack.com/feed" target="_blank" rel="noreferrer"><BsFillRssFill/></a>
      </div>
      <div className="BlogEntriesCont">
        {blogcontents}
      </div>
    </div>
    )
}
}