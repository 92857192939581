import React, {Component} from "react";
import { differenceInCalendarDays } from 'date-fns';
import { BiCopy } from 'react-icons/bi';
import moment from "moment";
import Calendar from 'react-calendar';
import GoogleMapReact from 'google-map-react';
import logo_wide_svg from "../assets/logo_wide.svg"
import logo_tall_svg from "../assets/logo_tall.svg"
import instagram_logo from "../assets/instagram.svg"
import { atcb_action } from "add-to-calendar-button";
import 'react-calendar/dist/Calendar.css';
import {Routes, Route, Link} from "react-router-dom";
import dayjs from 'dayjs';
import Select from 'react-select';
import SpacesPage from "./SpacesPage";
import BlogPage from "./BlogPage"
import TipUsPage from "./TipUsPage"
import AboutPage from "./AboutPage"
import TermsPage from "./TermsPage"
import TourPlan from "./TourPlan"
import CFAF from "./CFAF"

export default class BasePage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            TimeNow: "",
            LoadingFinish: false,
            FeedCalendar: "",
            FeedSpaces: "",
            FeedArtists: "",
            FeedSpacesAttributes: "",
            MoreNavigation: false,
            CalendarPage: true,
            SpacesPage: false,
            BlogPage: false,
            TipUsPage: false,
            AboutPage: false,
            TermsPage: false,
            TourplanPage: false,
            LovePage: false,
            CalendarType: true,
            CalendarOutput: "",
            CalendarListTime: "everything",
            CalendarListType:  "All",
            CalendarListOutput: "",
            MobileHeaderState: [<Link key={"exhibitionsLink"} to ="" onClick={() =>{this.toggleCalendarPage()}} className="HeaderCalendar">exhibitions</Link>],
            DesktopHeaderState: [<Link key={"exhibitionsLink"} id="exhibitionLink" to ="" onClick={() =>{this.toggleCalendarPage()}} className="HeaderCalendar">exhibitions</Link>,<Link id="spaceLink" key={"spacesLink"} to="/spaces" onClick={() => {this.toggleSpacesPage()}} className="HeaderSpaces">spaces</Link>,<Link id="blogLink" key={"blogLink"} to="/blog" onClick={() => {this.toggleBlogPage()}} className="HeaderBlog">blog</Link>,<Link id="tipusLink" key={"tipUsLink"} to="/tip-us" onClick={() => {this.toggleTipUsPage()}} className="HeaderTip">tip-us</Link>],
            TourPlanList: new Set(),
            CalendarDate: "",
            detailContent: "",
        };
        this.toggleMoreNavigation = this.toggleMoreNavigation.bind(this);
        this.toggleCalendarPage = this.toggleCalendarPage.bind(this);
        this.toggleSpacesPage = this.toggleSpacesPage.bind(this);
        this.toggleBlogPage = this.toggleBlogPage.bind(this);
        this.toggleTipUsPage = this.toggleTipUsPage.bind(this);
        this.toggleAboutPage = this.toggleAboutPage.bind(this);
        this.toggleTermsPage = this.toggleTermsPage.bind(this);
        this.toggleLovePage = this.toggleLovePage.bind(this);
        this.UrlRouter = this.UrlRouter.bind(this);
        this.toggleCalendarType = this.toggleCalendarType.bind(this);
        this.CalenderChange = this.CalenderChange.bind(this);
        this.CalenderListChange = this.CalenderListChange.bind(this);
        this.ChangeCalendarButton = this.ChangeCalendarButton.bind(this);
        this.handleTourAdd = this.handleTourAdd.bind(this);
        this.toggleTourplanPage = this.toggleTourplanPage.bind(this);
        this.desktopEELoad = this.desktopEELoad.bind(this);
    }

    handleTourAdd(information) {
        var thisId = parseInt(information.split("_")[1])
        var TheTourPlan = this.state.TourPlanList
        if (!TheTourPlan.has(thisId)) {
            var NewTourPlanList = TheTourPlan.add(thisId)
            this.setState ({
                TourPlanList: NewTourPlanList
            })
        } else {
                TheTourPlan.delete(thisId)
                this.setState ({
                    TourPlanList: TheTourPlan
                })
        }
    }

    ChangeCalendarButton(TheEEId) {
        var ThisButtonId = parseInt(TheEEId.split("_")[1])
        var CalendarButtonName = "Untitled";
        var CalendarButtonFind = document.getElementById("CalendarButton_"+TheEEId.split("_")[1]);
        for (var ThisEECalBut in this.state.FeedCalendar) {
            if (ThisButtonId === this.state.FeedCalendar[ThisEECalBut].eeId) {
                if (this.state.FeedCalendar[ThisEECalBut].title) {
                    CalendarButtonName = this.state.FeedCalendar[ThisEECalBut].title
                }

                var eventType = ""
                var eventTimeStart = ""
                var eventTimeEnd = ""
                if (this.state.FeedCalendar[ThisEECalBut].eeTypeId === 1) {
                    eventType = "Vernissage:"
                    eventTimeStart = dayjs(this.state.FeedCalendar[ThisEECalBut].startDay).format('HH:mm')
                    eventTimeEnd = dayjs(this.state.FeedCalendar[ThisEECalBut].endDay).format('HH:mm')
                } else if (this.state.FeedCalendar[ThisEECalBut].eeTypeId === 2 ) {
                    eventType = "Artist talk:"
                    eventTimeStart = dayjs(this.state.FeedCalendar[ThisEECalBut].startDay).format('HH:mm')
                    eventTimeEnd = dayjs(this.state.FeedCalendar[ThisEECalBut].endDay).format('HH:mm')
                } else if (this.state.FeedCalendar[ThisEECalBut].eeTypeId === 4 ) {
                    eventType = "Other event:"
                    eventTimeStart = dayjs(this.state.FeedCalendar[ThisEECalBut].startDay).format('HH:mm')
                    eventTimeEnd = dayjs(this.state.FeedCalendar[ThisEECalBut].endDay).format('HH:mm')
                }

                var locationName
                this.state.FeedSpaces.forEach((space) => {
                    if (space.spaceId === this.state.FeedCalendar[ThisEECalBut].spaceId) {
                        locationName = `${space.address}, ${space.city} (${space.name})`
                    }
                })

                if (this.state.FeedCalendar[ThisEECalBut].eeTypeId === 3) {
                    var CalendarButtonConfig = {
                        name: `${eventType} ${CalendarButtonName}`,
                        location: locationName, 
                        description: this.state.FeedCalendar[ThisEECalBut].description,
                        startDate: dayjs(this.state.FeedCalendar[ThisEECalBut].startDay).format('YYYY-MM-DD'),
                        endDate: dayjs(this.state.FeedCalendar[ThisEECalBut].endDay).format('YYYY-MM-DD'),
                        options: ["Google", "Apple", 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
                        timeZone: "Europe/Stockholm"
                    }
                } else {
                    var CalendarButtonConfig = {
                        name: `${eventType} ${CalendarButtonName}`,
                        location: locationName, 
                        startTime: eventTimeStart,
                        endTime: eventTimeEnd,
                        description: this.state.FeedCalendar[ThisEECalBut].description,
                        startDate: dayjs(this.state.FeedCalendar[ThisEECalBut].startDay).format('YYYY-MM-DD'),
                        endDate: dayjs(this.state.FeedCalendar[ThisEECalBut].endDay).format('YYYY-MM-DD'),
                        options: ["Google", "Apple", 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo'],
                        timeZone: "Europe/Stockholm"
                    }
                };
            }
        }
        atcb_action(CalendarButtonConfig, CalendarButtonFind)
    }

    async componentDidMount(){
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = dd + '/' + mm + '/' + yyyy;
        this.setState({
            TimeNow: formattedToday,
            CalendarDate: formattedToday
        })
        const SpacesResponse = await fetch("https://seaslugadmin.dk/api/spaces");
        const SpacesFeed = await SpacesResponse.json();
        const SpacesAttributesResponse = await fetch("https://seaslugadmin.dk/api/spaceattributes")
        const SpacesAttributes = await SpacesAttributesResponse.json();
        const SpacesTimeResponse = await fetch("https://seaslugadmin.dk/api/spaceopenings")
        const SpacesTime = await SpacesTimeResponse.json();
        const EEResponse = await fetch("https://seaslugadmin.dk/api/ee");
        const EEResult = await EEResponse.json();
        const EEArtistsResponse = await fetch("https://seaslugadmin.dk/api/eecontacts");
        const EEArtists = await EEArtistsResponse.json();
        EEResult.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.startDay) - new Date(b.startDay);
        });
        this.setState({
            LoadingFinish: true,
            FeedCalendar: EEResult,
            FeedSpaces: SpacesFeed,
            FeedSpacesAttributes: SpacesAttributes,
            FeedSpacesTime: SpacesTime,
            FeedArtists: EEArtists,
        });
        this.UrlRouter()
        window.addEventListener('popstate', (event) => {
            this.UrlRouter()
        });
        window.addEventListener('pushstate', (event) => {
            this.UrlRouter()
        });
        this.CalenderChange(this.state.TimeNow)
        this.CalenderListChange(this.state.TimeNow, this.state.CalendarListTime, this.state.CalendarListType)
        if (window.innerWidth >= 720) {
            for (var thisEE in this.state.FeedCalendar) {
                var eeStart = dayjs(this.state.FeedCalendar[thisEE].startDay).format('DD/MM/YYYY')
                var eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
                var eeEnd = dayjs(this.state.FeedCalendar[thisEE].endDay).format('DD/MM/YYYY')
                var eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()

                if (eeStartMoment <= moment(this.state.CalendarDate, "DD/MM/YYYY").toDate().getTime() && moment(this.state.CalendarDate, "DD/MM/YYYY").toDate().getTime() <= eeEndMoment) { 
                    var thisId = this.state.FeedCalendar[thisEE].eeId
                    break
                }
            }
            this.desktopEELoad(thisId)
        }
    }

    UrlRouter(){
        if (window.location.href===window.location.origin + "/") {
            this.toggleCalendarPage()
        }
        if (window.location.href===window.location.origin + "/spaces") {
            this.toggleSpacesPage()
        }
        if (window.location.href===window.location.origin + "/blog") {
            this.toggleBlogPage()
        }
        if (window.location.href===window.location.origin + "/tip-us") {
            this.toggleTipUsPage()
        }
        if (window.location.href===window.location.origin + "/about") {
            this.toggleAboutPage()
        }
        if (window.location.href===window.location.origin + "/terms") {
            this.toggleTermsPage()
        }
        if (window.location.href===window.location.origin + "/the-centre-for-the-fictitious-ante-facto") {
            this.toggleLovePage()
        }
        if (window.location.href===window.location.origin + "/tourplan") {
            this.toggleTourplanPage()
        }
    }

    toggleMoreNavigation() {
        this.setState({
            MoreNavigation: !this.state.MoreNavigation,
        });
    }

    toggleCalendarPage() {
        if (window.innerWidth >= 720) {
            document.getElementById("exhibitionLink").style.textDecoration = "underline"
            document.getElementById("exhibitionLink").style.fontWeight = "bold"
            document.getElementById("spaceLink").style.textDecoration = "none"
            document.getElementById("spaceLink").style.fontWeight = "normal"
            document.getElementById("blogLink").style.textDecoration = "none"
            document.getElementById("blogLink").style.fontWeight = "normal"
            document.getElementById("tipusLink").style.textDecoration = "none"
            document.getElementById("tipusLink").style.fontWeight = "normal"
        }
        this.setState({
            CalendarPage: true,
            SpacesPage:false,
            BlogPage: false,
            TipUsPage:false,
            AboutPage:false,
            TermsPage:false,
            MoreNavigation: false,
            TourplanPage: false,
            LovePage: false,
            MobileHeaderState: [<Link key={"exhibitionsLink"} to ="" onClick={() =>{this.toggleCalendarPage()}} className="HeaderCalendar">exhibitions</Link>]
        });
    }

    toggleSpacesPage() {
        if (window.innerWidth >= 720) {
            document.getElementById("exhibitionLink").style.textDecoration = "none"
            document.getElementById("exhibitionLink").style.fontWeight = "normal"
            document.getElementById("spaceLink").style.textDecoration = "underline"
            document.getElementById("spaceLink").style.fontWeight = "bold"
            document.getElementById("blogLink").style.textDecoration = "none"
            document.getElementById("blogLink").style.fontWeight = "normal"
            document.getElementById("tipusLink").style.textDecoration = "none"
            document.getElementById("tipusLink").style.fontWeight = "normal"
        }
        this.setState({
            CalendarPage: false,
            SpacesPage:true,
            BlogPage: false,
            TipUsPage:false,
            AboutPage:false,
            TermsPage:false,
            MoreNavigation: false,
            TourplanPage: false,
            LovePage: false,
            MobileHeaderState: [<Link key={"spacesLink"} to="/spaces" onClick={() => {this.toggleSpacesPage()}} className="HeaderSpaces">spaces</Link>]
        });
    }

    toggleBlogPage() {
        if (window.innerWidth >= 720) {
            document.getElementById("exhibitionLink").style.textDecoration = "none"
            document.getElementById("exhibitionLink").style.fontWeight = "normal"
            document.getElementById("spaceLink").style.textDecoration = "none"
            document.getElementById("spaceLink").style.fontWeight = "normal"
            document.getElementById("blogLink").style.textDecoration = "underline"
            document.getElementById("blogLink").style.fontWeight = "bold"
            document.getElementById("tipusLink").style.textDecoration = "none"
            document.getElementById("tipusLink").style.fontWeight = "normal"
        }
        this.setState({
            CalendarPage: false,
            SpacesPage: false,
            BlogPage: true,
            TipUsPage:false,
            AboutPage:false,
            TermsPage:false,
            MoreNavigation: false,
            TourplanPage: false,
            LovePage: false,
            MobileHeaderState: [<Link key={"blogLink"} to="/blog" onClick={() => {this.toggleBlogPage()}} className="HeaderBlog">blog</Link>]
        });
    }

    toggleTipUsPage() {
        if (window.innerWidth >= 720) {
            document.getElementById("exhibitionLink").style.textDecoration = "none"
            document.getElementById("exhibitionLink").style.fontWeight = "normal"
            document.getElementById("spaceLink").style.textDecoration = "none"
            document.getElementById("spaceLink").style.fontWeight = "normal"
            document.getElementById("blogLink").style.textDecoration = "none"
            document.getElementById("blogLink").style.fontWeight = "normal"
            document.getElementById("tipusLink").style.textDecoration = "underline"
            document.getElementById("tipusLink").style.fontWeight = "bold"
        }
        this.setState({
            CalendarPage: false,
            SpacesPage:false,
            BlogPage: false,
            TipUsPage:true,
            AboutPage:false,
            TermsPage:false,
            MoreNavigation: false,
            TourplanPage: false,
            LovePage: false,
            MobileHeaderState: [<Link key={"tipUsLink"} to="/tip-us" onClick={() => {this.toggleTipUsPage()}} className="HeaderTip">tip-us</Link>]
        });
    }

    toggleAboutPage() {
        this.setState({
            CalendarPage: false,
            SpacesPage:false,
            BlogPage: false,
            TipUsPage:false,
            AboutPage:true,
            TermsPage:false,
            MoreNavigation: false,
            TourplanPage: false,
            LovePage: false,
            MobileHeaderState: [<Link key={"aboutLink"} to ="/about" onClick={() =>{this.toggleAboutPage()}} style={{fontSize: "20px", fontWeight: "bold"}}>about</Link>]
        });
    }

    toggleTermsPage() {
        this.setState({
            CalendarPage: false,
            SpacesPage:false,
            BlogPage: false,
            TipUsPage:false,
            AboutPage: false,
            MoreNavigation: false,
            TermsPage: true,
            TourplanPage: false,
            LovePage: false,
            MobileHeaderState: [<Link key={"termsLink"} to ="/terms" onClick={() =>{this.toggleTermsPage()}} style={{fontSize: "20px", fontWeight: "bold"}}>terms</Link>]
        });
    }

    toggleTourplanPage() {
        this.setState({
            CalendarPage: false,
            SpacesPage:false,
            BlogPage: false,
            TipUsPage:false,
            AboutPage:false,
            MoreNavigation: false,
            TermsPage: false,
            TourplanPage: true,
            LovePage: false,
        });
    }

    toggleLovePage() {
        this.setState({
            CalendarPage: false,
            SpacesPage:false,
            BlogPage: false,
            TipUsPage:false,
            AboutPage:false,
            MoreNavigation: false,
            TermsPage: false,
            TourplanPage: false,
            LovePage: true,
        });
    }

    toggleCalendarType() {
        this.setState({
            CalendarType: !this.state.CalendarType
        });
    }

    async CalenderListChange(timeToday, time, type) {
        var AllEE = this.state.FeedCalendar;
        var AllArtists = this.state.FeedArtists;
        var AddTime_raw;
        var AddTime_real;
        var eeStart;
        var eeStartMoment;
        var eeEnd;
        var eeEndMoment;
        var eventArray = [];
        var eventOutput = [];
        var exhibitionArray = [];
        var exhibitionOutput = [];
        var ThisMoment = moment(timeToday, "DD/MM/YYYY").toDate().getTime()
        var AllEECal = [];
        var ThisEE;
        var thisSpace;
        for (ThisEE in AllEE) {
            //console.log(AllEE[ThisEE].eeTypeId)
            eeStart = dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY')
            eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
            eeEnd = dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY')
            eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
            for (thisSpace in this.state.FeedSpaces) {
                if (AllEE[ThisEE].spaceId === this.state.FeedSpaces[thisSpace].spaceId) {
                    var eeType = this.state.FeedSpaces[thisSpace].type
                }
            }
            if (time === "everything") {
                if (type === "All") {
                    if ((ThisMoment) <= eeEndMoment) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                } else {
                    if (((ThisMoment)  <= eeEndMoment) && (type === eeType)) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                }
            }
            if (time === "rightnow") {
                if (type === "All") {
                    if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                }
                else {
                    if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment && type === eeType) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                }
            }
            if (time === "nextweek") {
                AddTime_raw = new Date()
                AddTime_real = moment(AddTime_raw.setDate(AddTime_raw.getDate() + 7))
                if (type === "All") {
                    if (Date.now() <= eeStartMoment && eeStartMoment <= AddTime_real && AllEE[ThisEE]['eeTypeId'] === 3) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                } else {
                    if (Date.now() <= eeStartMoment && eeStartMoment <= AddTime_real && type === eeType && AllEE[ThisEE]['eeTypeId'] === 3) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                }
            }
            if (time === "nextmonth") {
                AddTime_raw = new Date()
                AddTime_real = moment(AddTime_raw.setDate(AddTime_raw.getDate() + 4 * 7))
                if (type === "All") {
                    if (eeStartMoment <= AddTime_real && AddTime_real <= eeEndMoment && AllEE[ThisEE]['eeTypeId'] === 3) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                } else {
                    if (eeStartMoment <= AddTime_real && AddTime_real <= eeEndMoment && type === eeType && AllEE[ThisEE]['eeTypeId'] === 3) {
                        AllEECal.push(AllEE[ThisEE])
                    }
                }
            }
            if (time === "finalweek") {
                if (AllEE[ThisEE].startDay && AllEE[ThisEE].endDay) {
                    if (AllEE[ThisEE].endDay) {
                        var LastWeek_raw = new Date(AllEE[ThisEE].endDay.slice(0,10))
                    } else {
                            LastWeek_raw = new Date(AllEE[ThisEE].startDay.slice(0,10))
                    }

                    var LastWeek_real = moment(LastWeek_raw.setDate(LastWeek_raw.getDate() - 7))
                    if (type === "All") {
                        if (LastWeek_real <= ThisMoment && ThisMoment <= eeEndMoment && AllEE[ThisEE]['eeTypeId'] === 3) {
                            AllEECal.push(AllEE[ThisEE])
                        }
                    } else {
                        if (LastWeek_real <= ThisMoment && ThisMoment <= eeEndMoment && type === eeType && AllEE[ThisEE]['eeTypeId'] === 3) {
                            AllEECal.push(AllEE[ThisEE])
                        }
                    }
                }
            }

            if (time === "onlyevents") {
                if ((ThisMoment <= eeEndMoment) && AllEE[ThisEE]['eeTypeId'] !== 3) {
                    AllEECal.push(AllEE[ThisEE])
                }
            }

            if (time === "onlyexhibitions") {
                if ((ThisMoment <= eeEndMoment) && AllEE[ThisEE]['eeTypeId'] === 3) {
                    AllEECal.push(AllEE[ThisEE])
                }
            }
        }

        for (ThisEE in AllEECal) {
            var EETitle = "";
            var EEName = "";
            var EEAddress = "";
            var EEArtistDiv = "";
            var EEArtists = [];
            var EECuratorsDiv = "";
            var EECurators = [];
            var EESpace = "";
            var EEWebsite = "";
            var EEInstagram = "";
            var EEFacebook = "";
            var EEFee = "";
            var EEDescImg = "";
            var EEDescLink = "";
            var EECalendarMap = "";
            var EEMonTime = "";
            var EETueTime = "";
            var EEWedTime = "";
            var EEThuTime = "";
            var EEFriTime = "";
            var EESatTime = "";
            var EESunTime = "";
            var EELat = "";
            var EELng = "";
            var EELink = "";
            var DateEntrySpace;
            var EESticker = "";
            var EEListTime = "";
            var TourButton = "";
            var CalendarButton;
            var readContent;
            eeStart = dayjs(AllEECal[ThisEE].startDay).format('DD/MM/YYYY')
            eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
            eeEnd = dayjs(AllEECal[ThisEE].endDay).format('DD/MM/YYYY')
            eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
            if (AllEECal[ThisEE].title) {
                EETitle = AllEECal[ThisEE].title
            } else {
                EETitle = "Untitled"
            }
            for (var thisArtist in AllArtists) {
                if (AllArtists[thisArtist].eEid === AllEECal[ThisEE].eeId) {
                    if (AllArtists[thisArtist].roletext === "Artist") {
                        EEArtists.push(AllArtists[thisArtist].contactName)
                    }
                    if (AllArtists[thisArtist].roletext === "Curator") {
                        EECurators.push(AllArtists[thisArtist].contactName)
                    }
                }
            }
            if (EEArtists.length > 3) {
                EEArtistDiv = <><div className="EECalendarArtist" id={"CalendarArtistGroupCal_"+AllEECal[ThisEE].eeId} style={{display: "flex"}}>Group show</div>
                <div className="EECalendarArtist" id={"CalendarArtistBigCal_"+AllEECal[ThisEE].eeId} style={{display: "none"}}>{EEArtists.join(", ")}</div></>
            } else {
                EEArtistDiv = <><div className="EECalendarArtist" id={"CalendarArtistGroupCal_"+AllEECal[ThisEE].eeId} style={{display: "flex"}}>{EEArtists.join(", ")}</div><div className="EECalendarArtist" id={"CalendarArtistBigCal_"+AllEECal[ThisEE].eeId} style={{display: "none"}}>{EEArtists.join(", ")}</div></>;
            }

            if (EECurators.length > 0) {
                EECuratorsDiv = <div className="EECalendarCurator">{EECurators.join(", ")}</div>;
            }

            for (thisSpace in this.state.FeedSpaces) {
                if (AllEECal[ThisEE].spaceId === this.state.FeedSpaces[thisSpace].spaceId) {
                    EEName = <div key={"CalendarListEventName_"+AllEECal[ThisEE].eeId} className="CalendarListEventName">{this.state.FeedSpaces[thisSpace].name}</div>
                    EEAddress = <div key={"CalendarListEEAddress_"+AllEECal[ThisEE].eeId} className="CalendarListEEAddress">{this.state.FeedSpaces[thisSpace].address}, {this.state.FeedSpaces[thisSpace].city}</div>
                    EESpace = <div key={"CalendarEventName_"+AllEE[ThisEE].eeId} className="CalendarEventName">{this.state.FeedSpaces[thisSpace].name}</div>
                }
            }

            for (var thisAttribute in this.state.FeedSpacesAttributes) {
                if (AllEECal[ThisEE].spaceId === this.state.FeedSpacesAttributes[thisAttribute].spaceId) {
                    if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 1) {
                        if (this.state.FeedSpacesAttributes[thisAttribute]) {
                            EEWebsite = <a key={"CalendarEEWebsite_"+AllEECal[ThisEE].eeId} href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" className="CalendarEEWebsite">website <span className="sr-only">↗</span></a>
                        }
                    }
                    if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 2) {
                        if (this.state.FeedSpacesAttributes[thisAttribute]) {
                            EEInstagram = <a key={"CalendarEEInstagram_"+AllEECal[ThisEE].eeId} href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" className="CalendarEEInstagram">instagram <span className="sr-only">↗</span></a>
                        }
                    }
                    if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 3) {
                        if (this.state.FeedSpacesAttributes[thisAttribute]) {
                            EEFacebook = <a key={"CalendarEEFacebook_"+AllEECal[ThisEE].eeId} href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" className="CalendarEEFacebook">facebook <span className="sr-only">↗</span></a>
                        }
                    }
                    if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 7) {
                        if (this.state.FeedSpacesAttributes[thisAttribute].spaceId=== 1087) {
                            EEFee = <div key={"CalendarEEFee_"+AllEE[ThisEE].eeId} className="CalendarEEFee">{this.state.FeedSpacesAttributes[thisAttribute].attributeValue}</div>
                        } else if (this.state.FeedSpacesAttributes[thisAttribute].attributeValue === "free") {
                            EEFee = <div key={"CalendarEEFee_"+AllEECal[ThisEE].eeId} className="CalendarEEFee">Free</div>
                        } else if (this.state.FeedSpacesAttributes[thisAttribute].attributeValue) {
                            EEFee = <a href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" key={"CalendarEEFee_"+AllEECal[ThisEE].eeId} className="CalendarEECost">cost info<span className="sr-only">↗</span></a>
                        }
                    }
                    if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 9) {
                        if (this.state.FeedSpacesAttributes[thisAttribute]) {
                            EELat = this.state.FeedSpacesAttributes[thisAttribute].attributeValue
                        }
                    }
                    if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 10) {
                        if (this.state.FeedSpacesAttributes[thisAttribute]) {
                            EELng = this.state.FeedSpacesAttributes[thisAttribute].attributeValue
                        }
                    }
                }
            }

            //console.log(EETitle)

            for (DateEntrySpace in this.state.FeedSpacesTime) {
                if (this.state.FeedSpacesTime[DateEntrySpace].spaceId === AllEECal[ThisEE].spaceId) {
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 1) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EEMonTime = <div key={"EEMonTime_"+AllEECal[ThisEE].eeId} className="EEMonTime">Mon: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EEMonTime = <div key={"EEMonTime_"+AllEECal[ThisEE].eeId} className="EEMonTime">Mon: By Appt.</div>
                        }
                    } 
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 2) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EETueTime = <div key={"EETueTime_"+AllEECal[ThisEE].eeId} className="EETueTime">Tue: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EETueTime = <div key={"EETueTime_"+AllEECal[ThisEE].eeId} className="EETueTime">Tue: By Appt.</div>
                        }
                    } 
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 3) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EEWedTime = <div key={"EEWedTime_"+AllEECal[ThisEE].eeId} className="EEWedTime">Wed: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EEWedTime = <div key={"EEWedTime_"+AllEECal[ThisEE].eeId} className="EEWedTime">Wed: By Appt.</div>
                        }
                    } 
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 4) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EEThuTime = <div key={"EEThuTime_"+AllEECal[ThisEE].eeId} className="EEThuTime">Thu: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EEThuTime = <div key={"EEThuTime_"+AllEECal[ThisEE].eeId} className="EEThuTime">Thu: By Appt.</div>
                        }
                    } 
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 5) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EEFriTime = <div key={"EEFriTime_"+AllEECal[ThisEE].eeId} className="EEFriTime">Fri: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EEFriTime = <div key={"EEFriTime_"+AllEECal[ThisEE].eeId} className="EEFriTime">Fri: By Appt.</div>
                        }
                    } 
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 6) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EESatTime = <div key={"EESatTime_"+AllEECal[ThisEE].eeId} className="EESatTime">Sat: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EESatTime = <div key={"EESatTime_"+AllEECal[ThisEE].eeId} className="EESatTime">Sat: By Appt.</div>
                        }
                    } 
                    if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 7) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                            EESunTime = <div key={"EESunTime_"+AllEECal[ThisEE].eeId} className="EESunTime">Sun: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                        } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                            EESunTime = <div key={"EESunTime_"+AllEECal[ThisEE].eeId} className="EESunTime">Sun: By Appt.</div>
                        }
                    }
                }
            }

            if (AllEECal[ThisEE].link) {
                EELink = <a key={"EventDescLink_"+AllEECal[ThisEE].eeId} className="EventDescLink" href={AllEECal[ThisEE].link} target="_blank" rel="noreferrer">More info</a>
            }

            if (AllEECal[ThisEE].description) {
                EEDescImg = <div key={"EventDescImgCont_"+AllEECal[ThisEE].eeId} className="EventDescImgCont"><div key={"EventDescCont_"+AllEECal[ThisEE].eeId} className="EventDescCont" dangerouslySetInnerHTML={{__html: AllEE[ThisEE].description}}></div>{EELink}</div>
            }
            
            var accessoryAppearance = "flex";
            if (eeEndMoment < (Date.now() - 86400000)) {
                accessoryAppearance = "none"
            }

            if (EELat) {
                const DetailMarker = ({ name, spaceID }) => <div key={"SpaceDetailPinKey_"+spaceID} className={"PinDetail"}><div key={"SpaceDetailNameKey_"+spaceID} id={"SpaceDetailTitleId_"+spaceID} className="PinDetailTitle">{name}</div></div>
                EECalendarMap = <div style={{ height: "200px", width: "100%", marginTop: "10px", marginBottom: "5px" }} key={"CalendarEventMap_"+AllEECal[ThisEE].eeId}>
                    <GoogleMapReact key={"CallEEGoogleMapReact_"+AllEECal[ThisEE].eeId} center={{lat: parseFloat(EELat), lng: parseFloat(EELng)}} zoom={14} bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }} options={{mapId:"eddf51aff07de27a"}}>
                        <DetailMarker key={"DetailMarkerKey_"+AllEECal[ThisEE].eeId} lat={parseFloat(EELat)} lng={parseFloat(EELng)} name={EESpace} spaceID={AllEECal[ThisEE].eeId}/>
                    </GoogleMapReact>
                </div>

                TourButton = <div style={{color: "black",backgroundColor: "#89cca3", padding: "2px", borderRadius: "3px", transition: "0.3s ease", cursor: "pointer", display: accessoryAppearance}} key={"CalendarEETourAdd_"+AllEECal[ThisEE].eeId} className={"CalendarEETourAdd_"+AllEECal[ThisEE].eeId} id={"CalendarEETourAdd_"+AllEECal[ThisEE].eeId} onClick={event => {var theseClasses=document.getElementsByClassName(event.currentTarget.className); if (theseClasses[0].style.color==="black"){for (var i=0; i<theseClasses.length;i++) { theseClasses[i].style.backgroundColor="rgb(1, 148, 73)"; theseClasses[i].style.color="white"; theseClasses[i].style.fontWeight="bold"}} else {for (var i=0; i<theseClasses.length;i++) {theseClasses[i].style.backgroundColor="#89cca3"; theseClasses[i].style.color="black"; theseClasses[i].style.fontWeight="normal"}}this.handleTourAdd(event.currentTarget.id)}} name={+AllEECal[ThisEE].eeId}>tour add</div>
            }

            if (eeEndMoment => (Date.now())) {
                CalendarButton = <button key={"CalendarButton_"+AllEECal[ThisEE].eeId} id={"CalendarButton_"+AllEECal[ThisEE].eeId} style={{border: "2px solid #89cca3", backgroundColor: "#89cca3", padding: "1px", borderRadius: "3px", transition: "0.3s ease", cursor: "pointer", display: accessoryAppearance}} onClick={event => (this.ChangeCalendarButton(event.currentTarget.id))}>calendar add</button>
            }
            
            if ((AllEECal[ThisEE].eeTypeId ===  1 | AllEECal[ThisEE].eeTypeId === 2 | AllEECal[ThisEE].eeTypeId === 4) && AllEECal[ThisEE].verified) {
                var eeTypeName;
                if (AllEECal[ThisEE].eeTypeId === 1) {
                    eeTypeName = "Vernissage:"
                } else if (AllEECal[ThisEE].eeTypeId === 2 ) {
                    eeTypeName = "Artist talk: "
                } else if (AllEECal[ThisEE].eeTypeId === 4 ) {
                    eeTypeName = "Other event: "
                }

                if (dayjs(AllEECal[ThisEE].startDay).format('DD/MM/YYYY HH:mm') === dayjs(AllEECal[ThisEE].endDay).format('DD/MM/YYYY HH:mm')) {
                    EEListTime  = <div key={"CalendarEventDates_"+AllEECal[ThisEE].eeId} className="CalendarEventDates"><i>{dayjs(AllEECal[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')}</i></div>
                } else {
                    if (eeStart === eeEnd) {
                        EEListTime  = <div key={"CalendarEventDates_"+AllEECal[ThisEE].eeId} className="CalendarEventDates"><i>{dayjs(AllEECal[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')}</i> to <i>{dayjs(AllEECal[ThisEE].endDay).format('HH:mm')}</i></div>
                    } else {
                        EEListTime  = <div key={"CalendarEventDates_"+AllEECal[ThisEE].eeId} className="CalendarEventDates"><i>{dayjs(AllEECal[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')}</i> to <i>{dayjs(AllEECal[ThisEE].endDay).format('DD/MM/YYYY - HH:mm')}</i></div>
                    }
                }

                if (eeEndMoment < (Date.now() - 86400000)) {
                    EESticker = <div key={"CalendarStickerArchive_"+AllEECal[ThisEE].eeId} className="CalendarStickerArchive">Archived</div>
                }

                if (window.innerWidth < 720) {
                    readContent = <div key={"CalendarEEAccessory_"+AllEECal[ThisEE].eeId} className="CalendarEEAccessory">
                    {EESticker}
                    <div key={"CalendarEEAdd_"+AllEECal[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                    {TourButton}
                    <a href={"#CalendarEEContList_"+AllEECal[ThisEE].eeId} key={"CalendarEEReadMore_"+AllEECal[ThisEE].eeId} id={AllEECal[ThisEE].eeId} className={"CalendarEEReadMoreList "+AllEECal[ThisEE].eeId} onClick={event => {document.getElementById("ReadMoreList_"+event.currentTarget.id).style.display = "flex";document.getElementsByClassName("CalendarEEReadMoreList "+event.currentTarget.id)[0].style.display = "none";document.getElementsByClassName("CalendarEEReadLessList "+event.currentTarget.id)[0].style.display = "flex";document.getElementById("CalendarArtistGroupCal_"+event.currentTarget.id).style.display = "none";document.getElementById("CalendarArtistBigCal_"+event.currentTarget.id).style.display = "flex"}}>read more</a>
                    <div key={"CalendarEEReadLess_"+AllEECal[ThisEE].eeId} className={"CalendarEEReadLessList "+AllEECal[ThisEE].eeId} id={AllEECal[ThisEE].eeId} onClick={event => { var thisId = document.getElementById("ReadMoreList_"+event.currentTarget.id);thisId.style.display = "none";document.getElementsByClassName("CalendarEEReadMoreList "+event.currentTarget.id)[0].style.display = "flex";document.getElementsByClassName("CalendarEEReadLessList "+event.currentTarget.id)[0].style.display = "none"; document.getElementById("CalendarArtistGroupCal_"+event.currentTarget.id).style.display = "flex";document.getElementById("CalendarArtistBigCal_"+event.currentTarget.id).style.display = "none"}}>read less</div>
                </div>
                } else {
                    readContent = <div key={"CalendarEEAccessory_"+AllEE[ThisEE].eeId} className="CalendarEEAccessory">
                    {EESticker}
                    <div key={"CalendarEEAdd_"+AllEE[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                    {TourButton}
                    <div id={AllEECal[ThisEE].eeId} onClick={event => {this.desktopEELoad(event.currentTarget.id)}} style={{textDecoration: "underline"}}>read more</div>
                </div>
                }

                eventArray.push(
                    <div key={"CalendarEventECont_"+AllEECal[ThisEE].eeId} className="CalendarEECont" id={"CalendarEEContList_"+AllEECal[ThisEE].eeId}>
                        <div key={"CalendarEventCont_"+AllEECal[ThisEE].eeId} className="CalendarEESummary">
                            <div key={"CalendarEEInfo_"+AllEECal[ThisEE].eeId} className="CalendarEEInfo">
                                <div className="CalendarEventTitleSum" ><b>{eeTypeName}</b> {EETitle}</div>
                                {EEArtistDiv}
                                {EEName}
                                {EEFee}
                                {EEListTime}
                            </div>
                            {readContent}
                        </div>
                        <div key={"CalendarEEExtraContent_"+AllEECal[ThisEE].eeId} className="CalendarEEExtraContent" style={{display: "none"}} id={"ReadMoreList_"+AllEECal[ThisEE].eeId}>
                            {EEDescImg}
                            <div key={"CalendarEESpaceExtra_"+AllEECal[ThisEE].eeId} className="CalendarEESpaceExtra">
                                <div key={"CalendarEESpaceTime_"+AllEECal[ThisEE].eeId} className="CalendarEESpaceTime">
                                    {EEMonTime}
                                    {EETueTime}
                                    {EEWedTime}
                                    {EEThuTime}
                                    {EEFriTime}
                                    {EESatTime}
                                    {EESunTime}
                                </div>
                                <div key={"CalendarEESpaceSocials_"+AllEECal[ThisEE].eeId} className="CalendarEESpaceSocials">
                                    {EEAddress}
                                    {EEWebsite}
                                    {EEInstagram}
                                    {EEFacebook}
                                </div>
                            </div>
                            {EECalendarMap}
                        </div>
                    </div>
                )
            }
            if ((AllEECal[ThisEE].eeTypeId === 3 ) && AllEECal[ThisEE].verified) {
                EEListTime  = <div key={"CalendarExhibitDates_"+AllEECal[ThisEE].eeId} className="CalendarExhibitDates">{eeStart} - {eeEnd}</div>
                
                var FirstWeek_raw = new Date(AllEECal[ThisEE].startDay.slice(0,10))
                var FirstWeek_real = moment(FirstWeek_raw.setDate(FirstWeek_raw.getDate() + 7))
                var FinalWeek_raw = new Date(AllEECal[ThisEE].endDay.slice(0,10))
                var FinalWeek_real = moment(FinalWeek_raw.setDate(FinalWeek_raw.getDate() - 7))
                if (eeStartMoment <= ThisMoment && ThisMoment <= FirstWeek_real) {
                    EESticker = <div key={"CalendarStickerNew_"+AllEECal[ThisEE].eeId} className="CalendarStickerNew">Just opened</div>
                }

                if (FinalWeek_real <= ThisMoment && ThisMoment <= eeEndMoment) {
                    EESticker = <div key={"CalendarStickerFinal_"+AllEECal[ThisEE].eeId} className="CalendarStickerFinal">Last chance</div>
                }

                if (eeEndMoment === moment(this.state.TimeNow, "DD/MM/YYYY").toDate().getTime()) {
                    EESticker = <div key={"CalendarStickerFinal_"+AllEECal[ThisEE].eeId} className="CalendarStickerFinal">Last day</div>
                } else if (eeEndMoment < (ThisMoment - 86400000)) {
                    EESticker = <div key={"CalendarStickerArchive_"+AllEECal[ThisEE].eeId} className="CalendarStickerArchive">Archived</div>
                }

                if (window.innerWidth < 720) {
                    readContent = <div key={"CalendarEEAccessory_"+AllEECal[ThisEE].eeId} className="CalendarEEAccessory">
                    {EESticker}
                    <div key={"CalendarEEAdd_"+AllEECal[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                    {TourButton}
                    <a href={"#CalendarEEContList_"+AllEECal[ThisEE].eeId} key={"CalendarEEReadMore_"+AllEECal[ThisEE].eeId} id={AllEECal[ThisEE].eeId} className={"CalendarEEReadMoreList "+AllEECal[ThisEE].eeId} onClick={event => {document.getElementById("ReadMoreList_"+event.currentTarget.id).style.display = "flex";document.getElementsByClassName("CalendarEEReadMoreList "+event.currentTarget.id)[0].style.display = "none";document.getElementsByClassName("CalendarEEReadLessList "+event.currentTarget.id)[0].style.display = "flex";document.getElementById("CalendarArtistGroupCal_"+event.currentTarget.id).style.display = "none";document.getElementById("CalendarArtistBigCal_"+event.currentTarget.id).style.display = "flex"}}>read more</a>
                    <div key={"CalendarEEReadLess_"+AllEECal[ThisEE].eeId} className={"CalendarEEReadLessList "+AllEECal[ThisEE].eeId} id={AllEECal[ThisEE].eeId} onClick={event => { var thisId = document.getElementById("ReadMoreList_"+event.currentTarget.id);thisId.style.display = "none";document.getElementsByClassName("CalendarEEReadMoreList "+event.currentTarget.id)[0].style.display = "flex";document.getElementsByClassName("CalendarEEReadLessList "+event.currentTarget.id)[0].style.display = "none"; document.getElementById("CalendarArtistGroupCal_"+event.currentTarget.id).style.display = "flex";document.getElementById("CalendarArtistBigCal_"+event.currentTarget.id).style.display = "none"}}>read less</div>
                </div>
                } else {
                    readContent = <div key={"CalendarEEAccessory_"+AllEE[ThisEE].eeId} className="CalendarEEAccessory">
                    {EESticker}
                    <div key={"CalendarEEAdd_"+AllEE[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                    {TourButton}
                    <div id={AllEECal[ThisEE].eeId} onClick={event => {this.desktopEELoad(event.currentTarget.id)}} style={{textDecoration: "underline"}}>read more</div>
                </div>
                }
                exhibitionArray.push(
                    <div key={"CalendarEExhibitCont_"+AllEECal[ThisEE].eeId} className="CalendarEECont" id={"CalendarEEContList_"+AllEECal[ThisEE].eeId}>
                        <div key={"CalendarExhibitCont_"+AllEECal[ThisEE].eeId} className="CalendarEESummary">
                            <div key={"CalendarEEInfo_"+AllEECal[ThisEE].eeId} className="CalendarEEInfo">
                                <div className="CalendarEventTitleSum">{EETitle}</div>
                                {EEArtistDiv}
                                {EEName}
                                {EEFee}
                                {EEListTime}
                            </div>
                            {readContent}
                        </div>
                        <div key={"CalendarEEExtraContent_"+AllEECal[ThisEE].eeId} style={{display: "none"}} className="CalendarEEExtraContent" id={"ReadMoreList_"+AllEECal[ThisEE].eeId}>
                            {EEDescImg}
                            <div key={"CalendarEESpaceExtra_"+AllEECal[ThisEE].eeId} className="CalendarEESpaceExtra">
                                <div key={"CalendarEESpaceTime_"+AllEECal[ThisEE].eeId} className="CalendarEESpaceTime">
                                    {EEMonTime}
                                    {EETueTime}
                                    {EEWedTime}
                                    {EEThuTime}
                                    {EEFriTime}
                                    {EESatTime}
                                    {EESunTime}
                                </div>
                                <div key={"CalendarEESpaceSocials_"+AllEECal[ThisEE].eeId} className="CalendarEESpaceSocials">
                                    {EEAddress}
                                    {EEWebsite}
                                    {EEInstagram}
                                    {EEFacebook}
                                </div>
                            </div>
                            {EECalendarMap}
                        </div>
                    </div>
                )
            }
        }
        if (eventArray.length !== 0) {
            eventOutput.push(<div key={"CalendarEventSectionCont_"+AllEECal[ThisEE].eeId} className="CalendarEventSectionCont"><div key={"CalendarEventTitle_"+AllEECal[ThisEE].eeId} className="CalendarEventTitle">events</div>{eventArray}</div>)
        }
        if (exhibitionArray.length !== 0) {
            exhibitionOutput.push(<div key={"CalendarExhibitiSectionCont_"+AllEECal[ThisEE].eeId} className="CalendarExhibitiSectionCont"><div key={"CalendarExhibitiTitle_"+AllEECal[ThisEE].eeId} className="CalendarExhibitiTitle">exhibitions</div>{exhibitionArray}</div>)
        }

        this.setState({
            CalendarListOutput: eventOutput.concat(exhibitionOutput),
            CalendarListTime: time,
            CalendarListType: type
        })
    }

    async CalenderChange(calendar_raw) {
        var ThisDate;
        if (calendar_raw.length === 10) {
            ThisDate = calendar_raw
        } else {
            ThisDate = dayjs(calendar_raw).format('DD/MM/YYYY')
        }
        this.setState({
            CalendarDate: ThisDate
        })
        var ThisMoment = moment(ThisDate, "DD/MM/YYYY").toDate().getTime()

        var AllEE = this.state.FeedCalendar;
        var AllArtists = this.state.FeedArtists;
        var eeStart;
        var eeStartMoment;
        var eeEnd;
        var eeEndMoment;
        var eventArray = [];
        var eventOutput = [];
        var exhibitionArray = [];
        var exhibitionOutput = [];
        var readContent;
        for (var ThisEE in AllEE) {
            var EETitle = "";
            var EEArtistDiv = "";
            var EEArtists = [];
            var EECuratorsDiv = "";
            var EECurators = [];
            var EESpace = "";
            var EEAddress = "";
            var EEFee;
            var thisSpace;
            var thisAttribute;
            var EEWebsite = "";
            var EEInstagram = "";
            var EEFacebook = "";
            var EELink = "";
            var EEDescImg = "";
            var EventDescLink = "";
            var EECalendarMap = "";
            var EEMonTime = "";
            var EETueTime = "";
            var EEWedTime = "";
            var EEThuTime = "";
            var EEFriTime = "";
            var EESatTime = "";
            var EESunTime = "";
            var EELat = "";
            var EELng = "";
            var DateEntrySpace;
            var EEListTime = ""
            var EESticker = "";
            var CalendarButton = "";
            var TourButton = "";
            eeStart = dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY')
            eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
            eeEnd = dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY')
            eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
            if (eeStartMoment <= ThisMoment && ThisMoment <= eeEndMoment) {
                if (AllEE[ThisEE].title) {
                    EETitle = AllEE[ThisEE].title
                } else {
                    EETitle = "Untitled"
                }

                for (thisSpace in this.state.FeedSpaces) {
                    if (AllEE[ThisEE].spaceId === this.state.FeedSpaces[thisSpace].spaceId) {
                        EESpace = <div key={"CalendarEventName_"+AllEE[ThisEE].eeId} className="CalendarEventName">{this.state.FeedSpaces[thisSpace]["name"]}</div>
                        EEAddress = <div key={"CalendarListEEAddress_"+AllEE[ThisEE].eeId} className="CalendarListEEAddress">{this.state.FeedSpaces[thisSpace]["address"]}, {this.state.FeedSpaces[thisSpace]["city"]}</div>
                    }
                }

                for (var thisArtist in AllArtists) {
                    if (AllArtists[thisArtist].eEid === AllEE[ThisEE].eeId) {
                        if (AllArtists[thisArtist].roletext === "Artist") {
                            EEArtists.push(AllArtists[thisArtist].contactName)
                        }
                        if (AllArtists[thisArtist].roletext === "Curator") {
                            EECurators.push(AllArtists[thisArtist].contactName)
                        }
                    }
                }

                if (EEArtists.length > 3) {
                    EEArtistDiv = <><div className="EECalendarArtist" id={"CalendarArtistGroup_"+AllEE[ThisEE].eeId} style={{display: "flex"}}>Group show</div>
                    <div className="EECalendarArtist" id={"CalendarArtistBig_"+AllEE[ThisEE].eeId} style={{display: "none"}}>{EEArtists.join(", ")}</div></>
                } else {
                    EEArtistDiv = <><div className="EECalendarArtist" id={"CalendarArtistGroup_"+AllEE[ThisEE].eeId} style={{display: "flex"}}>{EEArtists.join(", ")}</div><div className="EECalendarArtist" id={"CalendarArtistBig_"+AllEE[ThisEE].eeId} style={{display: "none"}}>{EEArtists.join(", ")}</div></>;
                }
    
                if (EECurators.length > 0) {
                    EECuratorsDiv = <div className="EECalendarCurator">{EECurators.join(", ")}</div>;
                }

                for (thisAttribute in this.state.FeedSpacesAttributes) {
                    if (AllEE[ThisEE]["spaceId"] === this.state.FeedSpacesAttributes[thisAttribute]["spaceId"]) {
                        if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 1) {
                            if (this.state.FeedSpacesAttributes[thisAttribute]) {
                                EEWebsite = <a key={"CalendarEEWebsite_"+AllEE[ThisEE].eeId} href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" className="CalendarEEWebsite">website <span className="sr-only">↗</span></a>
                            }
                        }
                        if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 2) {
                            if (this.state.FeedSpacesAttributes[thisAttribute]) {
                                EEInstagram = <a key={"CalendarEEInstagram_"+AllEE[ThisEE].eeId} href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" className="CalendarEEInstagram">instagram <span className="sr-only">↗</span></a>
                            }
                        }
                        if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 3) {
                            if (this.state.FeedSpacesAttributes[thisAttribute]) {
                                EEFacebook = <a key={"CalendarEEFacebook_"+AllEE[ThisEE].eeId} href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" className="CalendarEEFacebook">facebook <span className="sr-only">↗</span></a>
                            }
                        }
                        if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 7) {
                            if (this.state.FeedSpacesAttributes[thisAttribute].spaceId=== 1087) {
                                EEFee = <div key={"CalendarEEFee_"+AllEE[ThisEE].eeId} className="CalendarEEFee">{this.state.FeedSpacesAttributes[thisAttribute].attributeValue}</div>
                            } else if (this.state.FeedSpacesAttributes[thisAttribute].attributeValue === "free") {
                                EEFee = <div key={"CalendarEEFee_"+AllEE[ThisEE].eeId} className="CalendarEEFee">Free</div>
                            } else if (this.state.FeedSpacesAttributes[thisAttribute].attributeValue) {
                                EEFee = <a href={this.state.FeedSpacesAttributes[thisAttribute].attributeValue} target="_blank" rel="noreferrer" key={"CalendarEEFee_"+AllEE[ThisEE].eeId} className="CalendarEECost">cost info<span className="sr-only">↗</span></a>
                            }
                        }
                        if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 9) {
                            if (this.state.FeedSpacesAttributes[thisAttribute]) {
                                EELat = this.state.FeedSpacesAttributes[thisAttribute].attributeValue
                            }
                        }
                        if (this.state.FeedSpacesAttributes[thisAttribute].attributeId === 10) {
                            if (this.state.FeedSpacesAttributes[thisAttribute]) {
                                EELng = this.state.FeedSpacesAttributes[thisAttribute].attributeValue
                            }
                        }
                    }
                }

                var DayCheck = new Set()
                for (DateEntrySpace in this.state.FeedSpacesTime) {
                    if (this.state.FeedSpacesTime[DateEntrySpace].spaceId === AllEE[ThisEE].spaceId) {
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 1) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EEMonTime = <div key={"EEMonTime_"+AllEE[ThisEE].eeId} className="EEMonTime">Mon: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(1)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EEMonTime = <div key={"EEMonTime_"+AllEE[ThisEE].eeId} className="EEMonTime">Mon: By Appt.</div>
                                DayCheck.add(1)
                            }
                        } 
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 2) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EETueTime = <div key={"EETueTime_"+AllEE[ThisEE].eeId} className="EETueTime">Tue: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(2)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EETueTime = <div key={"EETueTime_"+AllEE[ThisEE].eeId} className="EETueTime">Tue: By Appt.</div>
                                DayCheck.add(2)
                            }
                        } 
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 3) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EEWedTime = <div key={"EEWedTime_"+AllEE[ThisEE].eeId} className="EEWedTime">Wed: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(3)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EEWedTime = <div key={"EEWedTime_"+AllEE[ThisEE].eeId} className="EEWedTime">Wed: By Appt.</div>
                                DayCheck.add(3)
                            }
                        } 
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 4) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EEThuTime = <div key={"EEThuTime_"+AllEE[ThisEE].eeId} className="EEThuTime">Thu: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(4)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EEThuTime = <div key={"EEThuTime_"+AllEE[ThisEE].eeId} className="EEThuTime">Thu: By Appt.</div>
                                DayCheck.add(4)
                            }
                        } 
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 5) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EEFriTime = <div key={"EEFriTime_"+AllEE[ThisEE].eeId} className="EEFriTime">Fri: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(5)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EEFriTime = <div key={"EEFriTime_"+AllEE[ThisEE].eeId} className="EEFriTime">Fri: By Appt.</div>
                                DayCheck.add(5)
                            }
                        } 
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 6) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EESatTime = <div key={"EESatTime_"+AllEE[ThisEE].eeId} className="EESatTime">Sat: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(6)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EESatTime = <div key={"EESatTime_"+AllEE[ThisEE].eeId} className="EESatTime">Sat: By Appt.</div>
                                DayCheck.add(6)
                            }
                        } 
                        if (this.state.FeedSpacesTime[DateEntrySpace].daynumber === 7) {
                            if (this.state.FeedSpacesTime[DateEntrySpace].opentime) {
                                EESunTime = <div key={"EESunTime_"+AllEE[ThisEE].eeId} className="EESunTime">Sun: {this.state.FeedSpacesTime[DateEntrySpace].opentime.slice(0,5) + "-" + this.state.FeedSpacesTime[DateEntrySpace].closetime.slice(0,5)}</div>
                                DayCheck.add(0)
                            } else if (this.state.FeedSpacesTime[DateEntrySpace].byAppointment) {
                                EESunTime = <div key={"EESunTime_"+AllEE[ThisEE].eeId} className="EESunTime">Sun: By Appt.</div>
                                DayCheck.add(0)
                            }
                        }
                    }
                }

                if (AllEE[ThisEE]["link"]) {
                    EELink = <a key={"EventDescLink_"+AllEE[ThisEE].eeId} className="EventDescLink" href={AllEE[ThisEE]["link"]} target="_blank" rel="noreferrer">More Info</a>
                }

                if (AllEE[ThisEE]["description"]) {
                    EEDescImg = <div key={"EventDescImgCont_"+AllEE[ThisEE].eeId} className="EventDescImgCont"><div className="EventDescCont" dangerouslySetInnerHTML={{__html: AllEE[ThisEE].description}}></div>{EELink}</div>
                }

                var accessoryAppearance = "flex";
                if (eeEndMoment < (Date.now() - 86400000)) {
                    accessoryAppearance = "none"
                }

                if (EELat) {
                    const DetailMarker = ({ name, spaceID }) => <div key={"SpaceDetailPinKey_"+spaceID} className={"PinDetail"}><div key={"SpaceDetailNameKey_"+spaceID} id={"SpaceDetailTitleId_"+spaceID} className="PinDetailTitle">{name}</div></div>
                    EECalendarMap = <div style={{ height: "200px", width: "100%", marginTop: "10px", marginBottom: "5px" }} key={"CalendarEventMap_"+AllEE[ThisEE].eeId}>
                        <GoogleMapReact key={"EEGoogleMap_"+AllEE[ThisEE].eeId} center={{lat: parseFloat(EELat), lng: parseFloat(EELng)}} zoom={14} bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }} options={{mapId:"eddf51aff07de27a"}}>
                            <DetailMarker key={"DetailMarkerKey_"+AllEE[ThisEE].eeId} lat={parseFloat(EELat)} lng={parseFloat(EELng)} name={EESpace} spaceID={AllEE[ThisEE].eeId}/>
                        </GoogleMapReact>
                    </div>

                    TourButton = <div style={{color: "black",backgroundColor: "#89cca3", padding: "2px", borderRadius: "3px", transition: "0.3s ease", cursor: "pointer", display: accessoryAppearance}} key={"CalendarEETourAdd_"+AllEE[ThisEE].eeId} className={"CalendarEETourAdd_"+AllEE[ThisEE].eeId} id={"CalendarEETourAdd_"+AllEE[ThisEE].eeId} onClick={event => {var theseClasses=document.getElementsByClassName(event.currentTarget.className); if (theseClasses[0].style.color==="black"){for (var i=0; i<theseClasses.length;i++) {theseClasses[i].style.backgroundColor="rgb(1, 148, 73)"; theseClasses[i].style.color="white"; theseClasses[i].style.fontWeight="bold"}} else {for (var i=0; i<theseClasses.length;i++) {theseClasses[i].style.backgroundColor="#89cca3"; theseClasses[i].style.color="black"; theseClasses[i].style.fontWeight="normal"}};this.handleTourAdd(event.currentTarget.id)}}>tour add</div>
                }

                if (eeEndMoment => (Date.now())) {
                    CalendarButton = <button key={"CalendarButton_"+AllEE[ThisEE].eeId} id={"CalendarButton_"+AllEE[ThisEE].eeId} style={{border: "2px solid #89cca3", backgroundColor: "#89cca3", padding: "1px", borderRadius: "3px", transition: "0.3s ease", cursor: "pointer", display: accessoryAppearance}} onClick={event => (this.ChangeCalendarButton(event.currentTarget.id))}>calendar add</button>
                }

                if ((AllEE[ThisEE].eeTypeId === 1 || AllEE[ThisEE].eeTypeId === 2 || AllEE[ThisEE].eeTypeId === 4) && DayCheck.has(moment(ThisMoment).day()) && AllEE[ThisEE].verified) {
                    var eeTypeName;
                    if (AllEE[ThisEE].eeTypeId === 1) {
                        eeTypeName = "Vernissage: "
                    } else if (AllEE[ThisEE].eeTypeId === 2 ) {
                        eeTypeName = "Artist talk: "
                    } else if (AllEE[ThisEE].eeTypeId === 4 ) {
                        eeTypeName = "Other event: "
                    }
                    
                    if (dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY HH:mm') === dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY HH:mm')) {
                        EEListTime  = <div key={"CalendarEventDates_"+AllEE[ThisEE].eeId} className="CalendarEventDates"><i>{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')}</i></div>
                    } else {
                        if (eeStart === eeEnd) {
                            EEListTime  = <div key={"CalendarEventDates_"+AllEE[ThisEE].eeId} className="CalendarEventDates"><i>{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')}</i> to <i>{dayjs(AllEE[ThisEE].endDay).format('HH:mm')}</i></div>
                        } else {
                            EEListTime  = <div key={"CalendarEventDates_"+AllEE[ThisEE].eeId} className="CalendarEventDates"><i>{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')}</i> to <i>{dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY - HH:mm')}</i></div>
                        }
                    }

                    if (eeEndMoment < (Date.now() - 86400000)) {
                        EESticker = <div key={"CalendarStickerArchive_"+AllEE[ThisEE].eeId} className="CalendarStickerArchive">Archived</div>
                    }

                    if (window.innerWidth < 720) {
                        readContent = <div key={"CalendarEEAccessory_"+AllEE[ThisEE].eeId} className="CalendarEEAccessory">
                        {EESticker}
                        <div key={"CalendarEEAdd_"+AllEE[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                        {TourButton}
                        <a href={"#CalendarEECont_"+AllEE[ThisEE].eeId} key={"CalendarEEReadMore_"+AllEE[ThisEE].eeId} id={AllEE[ThisEE].eeId} className={"CalendarEEReadMore "+AllEE[ThisEE].eeId} onClick={event => {document.getElementById("ReadMore_"+event.currentTarget.id).style.display = "flex";document.getElementsByClassName("CalendarEEReadMore "+event.currentTarget.id)[0].style.display = "none";document.getElementsByClassName("CalendarEEReadLess "+event.currentTarget.id)[0].style.display = "flex";document.getElementById("CalendarArtistGroup_"+event.currentTarget.id).style.display = "none";document.getElementById("CalendarArtistBig_"+event.currentTarget.id).style.display = "flex"}}>read more</a>
                        <div key={"CalendarEEReadLess_"+AllEE[ThisEE].eeId} className={"CalendarEEReadLess "+AllEE[ThisEE].eeId} id={AllEE[ThisEE].eeId} onClick={event => { var thisId = document.getElementById("ReadMore_"+event.currentTarget.id);thisId.style.display = "none";document.getElementsByClassName("CalendarEEReadMore "+event.currentTarget.id)[0].style.display = "flex";document.getElementsByClassName("CalendarEEReadLess "+event.currentTarget.id)[0].style.display = "none"; document.getElementById("CalendarArtistGroup_"+event.currentTarget.id).style.display = "flex";document.getElementById("CalendarArtistBig_"+event.currentTarget.id).style.display = "none"}}>read less</div>
                    </div>
                    } else {
                        readContent = <div key={"CalendarEEAccessory_"+AllEE[ThisEE].eeId} className="CalendarEEAccessory">
                        {EESticker}
                        <div key={"CalendarEEAdd_"+AllEE[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                        {TourButton}
                        <div id={AllEE[ThisEE].eeId} onClick={event => {this.desktopEELoad(event.currentTarget.id)}} style={{textDecoration: "underline"}}>read more</div>
                    </div>
                    }
                    eventArray.push(
                        <div key={"CalendarEventECont_"+AllEE[ThisEE].eeId} className="CalendarEECont" id={"CalendarEECont_"+AllEE[ThisEE].eeId}>
                            <div key={"CalendarEESummary_"+AllEE[ThisEE].eeId} className="CalendarEESummary">
                                <div key={"CalendarEEInfo_"+AllEE[ThisEE].eeId} className="CalendarEEInfo">
                                    <div className="CalendarEventTitleSum" ><b>{eeTypeName}</b> {EETitle}</div>
                                    {EEArtistDiv}
                                    {EESpace}
                                    {EEFee}
                                    {EEListTime}
                                </div>
                                {readContent}
                            </div>
                            <div key={"CalendarEEExtraContent_"+AllEE[ThisEE].eeId} className="CalendarEEExtraContent" style={{display: "none"}} id={"ReadMore_"+AllEE[ThisEE].eeId}>
                            {EEDescImg}
                                <div key={"CalendarEESpaceExtra_"+AllEE[ThisEE].eeId} className="CalendarEESpaceExtra">
                                    <div key={"CalendarEESpaceTime_"+AllEE[ThisEE].eeId} className="CalendarEESpaceTime">
                                        {EEMonTime}
                                        {EETueTime}
                                        {EEWedTime}
                                        {EEThuTime}
                                        {EEFriTime}
                                        {EESatTime}
                                        {EESunTime}
                                    </div>
                                    <div key={"CalendarEESpaceSocials_"+AllEE[ThisEE].eeId} className="CalendarEESpaceSocials">
                                        {EEAddress}
                                        {EEWebsite}
                                        {EEInstagram}
                                        {EEFacebook}
                                    </div>
                                </div>
                                {EECalendarMap}
                            </div>
                        </div>
                    )
                }
                
                if (AllEE[ThisEE].eeTypeId === ( 3 ) && DayCheck.has(moment(ThisMoment).day()) && AllEE[ThisEE].verified) {
                    EEListTime  = <div key={"CalendarExhibitDates_"+AllEE[ThisEE].eeId} className="CalendarExhibitDates">{eeStart} - {eeEnd}</div>

                    var FirstWeek_raw = new Date(AllEE[ThisEE].startDay.slice(0,10))
                    var FirstWeek_real = moment(FirstWeek_raw.setDate(FirstWeek_raw.getDate() + 7))
                    var FinalWeek_raw = new Date(AllEE[ThisEE].endDay.slice(0,10))
                    var FinalWeek_real = moment(FinalWeek_raw.setDate(FinalWeek_raw.getDate() - 7))
                    if (eeStartMoment <= ThisMoment && ThisMoment <= FirstWeek_real) {
                        EESticker = <div key={"CalendarStickerNew_"+AllEE[ThisEE].eeId} className="CalendarStickerNew">Just opened</div>
                    }
                    if (FinalWeek_real <= ThisMoment && ThisMoment <= eeEndMoment) {
                        EESticker = <div key={"CalendarStickerFinal_"+AllEE[ThisEE].eeId} className="CalendarStickerFinal">Last chance</div>
                    }

                    if (eeEndMoment === moment(this.state.TimeNow, "DD/MM/YYYY").toDate().getTime()) {
                        EESticker = <div key={"CalendarStickerFinal_"+AllEE[ThisEE].eeId} className="CalendarStickerFinal">Last day</div>
                    } else if (eeEndMoment < (Date.now() - 86400000)) {
                        EESticker = <div key={"CalendarStickerArchive_"+AllEE[ThisEE].eeId} className="CalendarStickerArchive">Archived</div>
                    }

                    if (window.innerWidth < 720) {
                        readContent = <div key={"CalendarEEAccessory_"+AllEE[ThisEE].eeId} className="CalendarEEAccessory">
                        {EESticker}
                        <div key={"CalendarEEAdd_"+AllEE[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                        {TourButton}
                        <a href={"#CalendarEECont_"+AllEE[ThisEE].eeId} key={"CalendarEEReadMore_"+AllEE[ThisEE].eeId} id={AllEE[ThisEE].eeId} className={"CalendarEEReadMore "+AllEE[ThisEE].eeId} onClick={event => {document.getElementById("ReadMore_"+event.currentTarget.id).style.display = "flex";document.getElementsByClassName("CalendarEEReadMore "+event.currentTarget.id)[0].style.display = "none";document.getElementsByClassName("CalendarEEReadLess "+event.currentTarget.id)[0].style.display = "flex"; document.getElementById("CalendarArtistGroup_"+event.currentTarget.id).style.display = "none";document.getElementById("CalendarArtistBig_"+event.currentTarget.id).style.display = "flex"}}>read more</a>
                        <div key={"CalendarEEReadLess_"+AllEE[ThisEE].eeId} className={"CalendarEEReadLess "+AllEE[ThisEE].eeId} id={AllEE[ThisEE].eeId} onClick={event => { var thisId = document.getElementById("ReadMore_"+event.currentTarget.id);thisId.style.display = "none";document.getElementsByClassName("CalendarEEReadMore "+event.currentTarget.id)[0].style.display = "flex";document.getElementsByClassName("CalendarEEReadLess "+event.currentTarget.id)[0].style.display = "none"; document.getElementById("CalendarArtistGroup_"+event.currentTarget.id).style.display = "flex";document.getElementById("CalendarArtistBig_"+event.currentTarget.id).style.display = "none"}}>read less</div>
                    </div>
                    } else {
                        readContent = <div key={"CalendarEEAccessory_"+AllEE[ThisEE].eeId} className="CalendarEEAccessory">
                        {EESticker}
                        <div key={"CalendarEEAdd_"+AllEE[ThisEE].eeId} className="CalendarEEAdd">{CalendarButton}</div>
                        {TourButton}
                        <div id={AllEE[ThisEE].eeId} onClick={event => {this.desktopEELoad(event.currentTarget.id)}} style={{textDecoration: "underline"}}>read more</div>
                    </div>
                    }
                    exhibitionArray.push(
                        <div key={"CalendarExhibitECont_"+AllEE[ThisEE].eeId} className="CalendarEECont" id={"CalendarEECont_"+AllEE[ThisEE].eeId}>
                            <div key={"CalendarEESummary_"+AllEE[ThisEE].eeId} className="CalendarEESummary">
                                <div key={"CalendarEEInfo_"+AllEE[ThisEE].eeId} className="CalendarEEInfo">
                                    <div className="CalendarEventTitleSum" >{EETitle}</div>
                                    {EEArtistDiv}
                                    {EESpace}
                                    {EEFee}
                                    {EEListTime}
                                </div>
                                {readContent}
                            </div>
                            <div key={"CalendarEEExtraContent_"+AllEE[ThisEE].eeId} style={{display: "none"}} className="CalendarEEExtraContent" id={"ReadMore_"+AllEE[ThisEE].eeId}>
                            {EEDescImg}
                                <div key={"CalendarEESpaceExtra_"+AllEE[ThisEE].eeId} className="CalendarEESpaceExtra">
                                    <div key={"CalendarEESpaceTime_"+AllEE[ThisEE].eeId} className="CalendarEESpaceTime">
                                        {EEMonTime}
                                        {EETueTime}
                                        {EEWedTime}
                                        {EEThuTime}
                                        {EEFriTime}
                                        {EESatTime}
                                        {EESunTime}
                                    </div>
                                    <div key={"CalendarEESpaceSocials_"+AllEE[ThisEE].eeId} className="CalendarEESpaceSocials">
                                        {EEAddress}
                                        {EEWebsite}
                                        {EEInstagram}
                                        {EEFacebook}
                                    </div>
                                </div>
                            {EECalendarMap}
                            </div>
                        </div>
                    )
                }
            }
        }
        if (eventArray.length !== 0) {
            eventOutput.push(<div key={"CalendarEventSectionCont_"+AllEE[ThisEE].eeId} className="CalendarEventSectionCont"><div key={"CalendarEventTitle_"+AllEE[ThisEE].eeId} className="CalendarEventTitle">events</div>{eventArray}</div>)
        }
        if (exhibitionArray.length !== 0) {
            exhibitionOutput.push(<div key={"CalendarExhibitiSectionCont_"+AllEE[ThisEE].eeId} className="CalendarExhibitiSectionCont"><div key={"CalendarExhibitiTitle_"+AllEE[ThisEE].eeId} className="CalendarExhibitiTitle">exhibitions</div>{exhibitionArray}</div>)
        }
        this.setState({
            CalendarOutput: eventOutput.concat(exhibitionOutput)
        })
    }

    desktopEELoad(theId) {
        var AllEE = this.state.FeedCalendar;
        var AllSpaces = this.state.FeedSpaces;
        var AllArtists = this.state.FeedArtists;
        var spaceAttributes = this.state.FeedSpacesAttributes;
        var spaceTimes = this.state.FeedSpacesTime
        var spaceName = "";
        var eeSpacePlace = "";
        var eeSpaceNumber = "";
        var eeTitle = ""
        var eeDescription = "";
        var eeLink = "";
        var EEArtistDiv = "";
        var EEArtists = [];
        var EECuratorsDiv = "";
        var EECurators = [];
        var eeDate;
        var eeSpaceEmail = ""
        var eeCostInfo = "";
        var eeWebsite = "";
        var eeInsta = "";
        var eeFacebook = "";
        var eeAccess = "";
        var eeToilet = "";
        var eeLat = "";
        var eeLng = "";
        var eeMonTime = "";
        var eeTueTime = "";
        var eeWedTime = "";
        var eeThuTime = "";
        var eeFriTime = "";
        var eeSatTime = "";
        var eeSunTime = "";
        var eeGoogleMap = "";
        eeTitle = ""
        var eeType;
        var eeEndMoment;
        var EESticker;
        for (var ThisEE in AllEE) {
            if (AllEE[ThisEE].eeId === parseInt(theId)) {
                var eeStart = dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY')
                var eeStartMoment = moment(eeStart, "DD/MM/YYYY").toDate().getTime()
                var eeEnd = dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY')
                eeEndMoment = moment(eeEnd, "DD/MM/YYYY").toDate().getTime()
                var FirstWeek_raw = new Date(AllEE[ThisEE].startDay.slice(0,10))
                var FirstWeek_real = moment(FirstWeek_raw.setDate(FirstWeek_raw.getDate() + 7))
                var FinalWeek_raw = new Date(AllEE[ThisEE].endDay.slice(0,10))
                var FinalWeek_real = moment(FinalWeek_raw.setDate(FinalWeek_raw.getDate() - 7))
                if (AllEE[ThisEE].title) {
                    eeTitle = <div className="eeDetailTitle">{AllEE[ThisEE].title}</div>
                }
                if (AllEE[ThisEE].eeTypeId === 3) {
                    eeType = "Exhibition"
                    eeDate = <div className="eeDate">{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY')} - {dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY')}</div>
                } else {
                    if (AllEE[ThisEE].eeTypeId === 1) {
                        eeType = "Vernissage"
                        eeDate = <div className="eeDate">{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')} to {dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY - HH:mm')}</div>
                    } else if (AllEE[ThisEE].eeTypeId === 2) {
                        eeType = "Artist talk"
                        eeDate = <div className="eeDate">{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')} to {dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY - HH:mm')}</div>
                    } else if (AllEE[ThisEE].eeTypeId === 4) {
                        eeType = "Other event"
                        eeDate = <div className="eeDate">{dayjs(AllEE[ThisEE].startDay).format('DD/MM/YYYY - HH:mm')} to {dayjs(AllEE[ThisEE].endDay).format('DD/MM/YYYY - HH:mm')}</div>
                    }
                }
                if (AllEE[ThisEE].link) {
                    eeLink = <a href={AllEE[ThisEE].link} className="eeDetailLink" target="_blank">{eeType} link <span className="sr-only">↗</span></a>
                } else {
                    eeLink = <div className="eeDetailLink">{eeType}</div>
                }
                if (AllEE[ThisEE].description) {
                    eeDescription = <div className="eeDetailDescription" dangerouslySetInnerHTML={{__html: AllEE[ThisEE].description}}></div>
                }

                for (var thisSpace in AllSpaces) {
                    if (AllEE[ThisEE].spaceId === AllSpaces[thisSpace].spaceId) {
                        var eeSpaceName = <div className="eeDetailSpaceName">{AllSpaces[thisSpace].name}</div>
                        spaceName = AllSpaces[thisSpace].name
                        if (AllSpaces[thisSpace].address) {
                            eeSpacePlace = <div><div className="eeDetailSpacePlace">{AllSpaces[thisSpace].address}</div>
                            <div className="eeDetailSpacePlace">{AllSpaces[thisSpace].city}</div></div>
                        }
                        if (AllSpaces[thisSpace].phonenumber) {
                            eeSpaceNumber = <div className="eeDetailSpacePhone">{AllSpaces[thisSpace].phonenumber}</div>
                        }
                        if (AllSpaces[thisSpace].email) {
                            eeSpaceEmail = <div className="eeDetailSpaceEmail">{AllSpaces[thisSpace].email}<div className="EmailCopySymbol"><BiCopy className="copysymbol" onClick={() => {navigator.clipboard.writeText(AllSpaces[thisSpace].email)}}/></div></div>
                        }
                    }
                }
                for (var thisSpaceAttr in spaceAttributes) {
                    if (AllEE[ThisEE].spaceId === spaceAttributes[thisSpaceAttr].spaceId) {
                        if (spaceAttributes[thisSpaceAttr].attributeId === 1) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeWebsite = <a className="eeDetailWebsite" rel="noreferrer" target="_blank" href={spaceAttributes[thisSpaceAttr].attributeValue}>website <span className="sr-only">↗</span></a>
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 2) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeInsta = <a className="eeDetailInsta" rel="noreferrer" target="_blank" href={"https://www.instagram.com/"+spaceAttributes[thisSpaceAttr].attributeValue}>instagram <span className="sr-only">↗</span></a>
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 3) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeFacebook = <a className="eeDetailFacebok" target="_blank" rel="noreferrer" href={spaceAttributes[thisSpaceAttr].attributeValue}>facebook <span className="sr-only">↗</span></a>
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 4) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeAccess = <a href={spaceAttributes[thisSpaceAttr].attributeValue} target="_blank" rel="noreferrer" className="eeDetailAccess">accessibility info <span className="sr-only">↗</span></a>
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 5) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeToilet = <div className="eeDetailToilet"><b>Toilet:</b>{" " + spaceAttributes[thisSpaceAttr].attributeValue}</div>
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 7) {
                            if (this.state.FeedSpacesAttributes[thisSpaceAttr].spaceId=== 1087) {
                                eeCostInfo = <div key={"CalendarEEFee_"+AllEE[ThisEE].eeId} className="CalendarEEFee">{this.state.FeedSpacesAttributes[thisSpaceAttr].attributeValue}</div>
                            } else if (spaceAttributes[thisSpaceAttr].attributeValue === "free") {
                                eeCostInfo = <div key={"CalendarEEFee_"+AllEE[ThisEE].eeId} className="CalendarEEFee">Free</div>
                            } else if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeCostInfo = <a className="eeDetailFee" target="_blank" rel="noreferrer" href={spaceAttributes[thisSpaceAttr].attributeValue}>cost info <span className="sr-only">↗</span></a>
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 9) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeLat = spaceAttributes[thisSpaceAttr].attributeValue
                            }
                        }
                        if (spaceAttributes[thisSpaceAttr].attributeId === 10) {
                            if (spaceAttributes[thisSpaceAttr].attributeValue) {
                                eeLng = spaceAttributes[thisSpaceAttr].attributeValue
                            }
                        }
                    }
                }
                if (eeLng) {
                    const DetailMarker = ({ name }) => <div key="eeSpaceDetailPinKey" className="PinDetail"><div key="eeSpaceDetailNameKey" id="SpaceDetailTitleId" className="PinDetailTitle">{name}</div></div>

                    eeGoogleMap = <div key="eeDetailMapsCont" className="eeDetailMapsCont">
                    <GoogleMapReact key="eeDetailGoogleMapReact"
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                    center={{lat: parseFloat(eeLat), lng: parseFloat(eeLng)}}
                    zoom={14}
                    options={{mapId:"eddf51aff07de27a"}} 
                    style={{width: "100%", height: "400px", margin: "0px", padding: "0px", position: "relative"}}
                    >
                        <DetailMarker key="eeDetailMarkerKey"
                        lat={parseFloat(eeLat)}
                        lng={parseFloat(eeLng)}
                        name={spaceName}/>
                    </GoogleMapReact>
                    </div>;
                    var accessoryAppearance = "flex";
                    if (eeEndMoment < (Date.now() - 86400000)) {
                        accessoryAppearance = "none"
                    }

                    var tourPlanBg = "#89cca3"
                    var tourPlanText = "black"
                    if (this.state.TourPlanList.has(parseInt(AllEE[ThisEE].eeId))) {
                        tourPlanBg = "#89cca3"
                        tourPlanText = "white"
                    }
                    var eeTourButton = <div style={{color: tourPlanText, borderRadius: "3px", backgroundColor: tourPlanBg, borderColor: "transparent", display: accessoryAppearance, padding: "2px", width: "fit-content", cursor: "pointer", marginTop: "5px"}} key={"CalendarEETourAdd_"+AllEE[ThisEE].eeId} className={"CalendarEETourAdd_"+AllEE[ThisEE].eeId} id={"CalendarEETourAdd_"+AllEE[ThisEE].eeId} onClick={event => {var theseClasses=document.getElementsByClassName(event.currentTarget.className); if (theseClasses[0].style.color==="black"){for (var i=0; i<theseClasses.length;i++) {theseClasses[i].style.backgroundColor="rgb(1, 148, 73)"; theseClasses[i].style.color="white"; theseClasses[i].style.fontWeight="bold"}} else {for (var i=0; i<theseClasses.length;i++) {theseClasses[i].style.backgroundColor="#89cca3"; theseClasses[i].style.color="black"; theseClasses[i].style.fontWeight="normal"}};this.handleTourAdd(event.currentTarget.id)}} name={+AllEE[ThisEE].eeId}>tour add</div>
                }
                for (var thisTime in spaceTimes) {
                    if (AllEE[ThisEE].spaceId === spaceTimes[thisTime].spaceId) {
                        if (spaceTimes[thisTime].daynumber === 1) {
                            if (spaceTimes[thisTime].opentime) {
                                eeMonTime = <div className="eeTimeMon"><b>Mon:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeMonTime = <div className="eeTimeMon"><b>Mon:</b> By Appt.</div>
                            }
                        }
                        if (spaceTimes[thisTime].daynumber === 2) {
                            if (spaceTimes[thisTime].opentime) {
                                eeTueTime = <div className="eeTimeTue"><b>Tue:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeTueTime = <div className="eeTimeTue"><b>Tue:</b> By Appt.</div>
                            }
                        }
                        if (spaceTimes[thisTime].daynumber === 3) {
                            if (spaceTimes[thisTime].opentime) {
                                eeWedTime = <div className="eeTimeWed"><b>Wed:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeWedTime = <div className="eeTimeWed"><b>Wed:</b> By Appt.</div>
                            }
                        }
                        if (spaceTimes[thisTime].daynumber === 4) {
                            if (spaceTimes[thisTime].opentime) {
                                eeThuTime = <div className="eeTimeThu"><b>Thu:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeThuTime = <div className="eeTimeThu"><b>Thu:</b> By Appt.</div>
                            }
                        }
                        if (spaceTimes[thisTime].daynumber === 5) {
                            if (spaceTimes[thisTime].opentime) {
                            eeFriTime = <div className="eeTimeFri"><b>Fri:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeFriTime = <div className="eeTimeFri"><b>Fri:</b> By Appt.</div>
                            }
                        }
                        if (spaceTimes[thisTime].daynumber === 6) {
                            if (spaceTimes[thisTime].opentime) {
                                eeSatTime = <div className="eeTimeSat"><b>Sat:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeSatTime = <div className="eeTimeSat"><b>Sat:</b> By Appt.</div>
                            }
                        }
                        if (spaceTimes[thisTime].daynumber === 7) {
                            if (spaceTimes[thisTime].opentime) {
                                eeSunTime = <div className="eeTimeSun"><b>Sun:</b> {spaceTimes[thisTime].opentime.slice(0,5)}-{spaceTimes[thisTime].closetime.slice(0,5)}</div>
                            } else if (spaceTimes[thisTime].byAppointment) {
                                eeSunTime = <div className="eeTimeSun"><b>Sun:</b> By Appt.</div>
                            }
                        }
                    }
                }
                for (var thisArtist in AllArtists) {
                    if (AllArtists[thisArtist].eEid === AllEE[ThisEE].eeId) {
                        if (AllArtists[thisArtist].roletext === "Artist") {
                            EEArtists.push(AllArtists[thisArtist].contactName)
                        }
                        if (AllArtists[thisArtist].roletext === "Curator") {
                            EECurators.push(AllArtists[thisArtist].contactName)
                        }
                    }
                }
    
                if (EECurators.length > 0) {
                    EECuratorsDiv = <div className="eeDetailCurators">Curated by {EECurators.join(", ")}</div>;
                }
            }
        }
        var thisMoment = moment(this.state.TimeNow, "DD/MM/YYYY").toDate().getTime()
        if (eeStartMoment <= thisMoment && thisMoment <= FirstWeek_real && eeType === "Exhibition") {
            EESticker = <div key="CalendarStickerNew" className="CalendarStickerNew">Just opened</div>
        }
        
        if (FinalWeek_real <= thisMoment && thisMoment <= eeEndMoment && eeType === "Exhibition") {
            EESticker = <div key="CalendarStickerFinal" className="CalendarStickerFinal">Last chance</div>
        }
        if ((eeEndMoment === moment(this.state.TimeNow, "DD/MM/YYYY").toDate().getTime()) && eeType === "Exhibition") {
            EESticker = <div key="CalendarStickerFinal" className="CalendarStickerFinal">Last day</div>
        } else if (eeEndMoment < (Date.now() - 86400000)) {
            EESticker = <div key="CalendarStickerArchive" className="CalendarStickerArchive">Archived</div>
        }

        if (eeEndMoment => (Date.now())) {
            var eeCalendarButton = <button key="CalendarButton" id={"CalendarButton_"+AllEE[ThisEE].eeId} style={{border: "2px solid #89cca3", backgroundColor: "#89cca3", padding: "1px", borderRadius: "3px", transition: "0.3s ease", cursor: "pointer", display: accessoryAppearance}} onClick={event => (this.ChangeCalendarButton(event.currentTarget.id))}>calendar add</button>
        }

        this.setState({
            detailContent :[<div className="eeDetailContent">
                <div className="eeDetailTop">
                    <div className="eeDetailTopLeft">
                        {eeTitle}
                        <div className="eeDetailTopArtists">{EEArtists.join(", ")}</div>
                        <div className="eeSpaceNameType">
                            {eeSpaceName}
                        </div>
                        <div className="eeSpaceExtraType">
                            {eeCostInfo}
                            {eeLink}
                        </div>
                        {eeDate}
                    </div>
                    <div className="eeDetailTopRight">
                        {EESticker}
                        {eeCalendarButton}
                        {eeTourButton}
                    </div>
                </div>
                {EECuratorsDiv}
                {eeDescription} 
                <div className="eeDetailSpaceInfo">
                    <div className="eeDetailSpaceInfoLeft">
                        {eeMonTime}
                        {eeTueTime}
                        {eeWedTime}
                        {eeThuTime}
                        {eeFriTime}
                        {eeSatTime}
                        {eeSunTime}
                    </div>
                    <div className="eeDetailSpaceInfoRight">
                        {eeSpacePlace}
                        {eeSpaceNumber}
                        {eeSpaceEmail}
                        <div className="eeDetailSpaceExternal">
                            {eeWebsite}
                            {eeInsta}
                            {eeFacebook}
                        </div>
                        <div className="eeDetailSpaceExternal2">
                            {eeAccess}
                            {eeToilet}
                        </div>
                    </div>
                </div>
                {eeGoogleMap}
        </div>]
        })
    }
    render() { 

        var EEHighlightSet = new Set();
        //console.log(this.state.FeedCalendar)
        for (var thisEE in this.state.FeedCalendar) {
            if (this.state.FeedCalendar[thisEE].eeTypeId === 1 || this.state.FeedCalendar[thisEE].eeTypeId === 2 || this.state.FeedCalendar[thisEE].eeTypeId === 4) {
                EEHighlightSet.add(new Date(Date.parse(this.state.FeedCalendar[thisEE].startDay.slice(0,10))))
            }
        }
        var EEHighlightarray = Array.from(EEHighlightSet)

        function isSameDay(a, b) {
            return differenceInCalendarDays(a, b) === 0;
          }

        function tileClassName({ date, view }) {
            if (
                view === 'month' &&
                EEHighlightarray.find((dDate) => isSameDay(dDate, date))
              ) {
                return 'highlight';
              }
        }

        var NotLoadingPage; var LoadingPage;
        if (this.state.LoadingFinish) {
            NotLoadingPage = "flex"
            LoadingPage = "none"
        } else {
            NotLoadingPage = "none"
            LoadingPage = "flex"
        }
        

        var CalendarPage;
        if (this.state.CalendarPage) {
            CalendarPage = "flex";
        } else {
            CalendarPage = "none";
        }
        
        var CalendarType; var CalendarShow; var ListVis; var ListShow;
        if (this.state.CalendarType) {
            CalendarType = "list"
            CalendarShow = "flex"
            ListVis = "none"
            ListShow = "none"
        } else {
            CalendarType = "calendar"
            CalendarShow = "none"
            ListVis = "block"
            ListShow = "flex"
        }

        var MoreNavigation = "none"
        if (this.state.MoreNavigation) {
            MoreNavigation = "block"
        }
        var d = new Date();
        d.setDate(d.getDate()-7);
        const tileDisabled = ({ activeStartDate, date, view }) => {
            return date <  d;
         }

        const TimeOptions = [{ value: 'everything', label: 'All' }, { value: 'finalweek', label: 'Last chance' }, { value: 'nextweek', label: 'Newly opened' }, { value: 'onlyevents', label: 'Only events' }, { value: 'onlyexhibitions', label: 'Only exhibitions' }]
        const defaultTime = TimeOptions[0];

        const TypeOptions = [{ value: 'All', label: 'All' }, { value: 'Artist Run', label: 'Artist Run' }, { value: 'Konsthall', label: 'Konsthall' }, { value: 'Gallery', label: 'Gallery' }, { value: 'Museum', label: 'Museum' }, { value: 'ArtSchool', label: 'Art School' }, { value: 'Other', label: 'Other' }, { value: 'Organization', label: 'Organization' }]
        const defaultType = TypeOptions[0];

        var MorePageContent;
        var headerContent;
        var moreButton;
        var deskTopHalf;
        if (window.innerWidth < 720) {
            MorePageContent=<div className="MoreSectionsCont">
                <div className="MoreMainItems">
                    <Link to ="/" onClick={() =>{this.toggleCalendarPage()}} className="MoreCalendar">exhibitions</Link>
                    <Link to="/spaces" onClick={() => {this.toggleSpacesPage()}} className="MoreHeader">spaces</Link>
                    <Link to="/blog" onClick={() => {this.toggleBlogPage()}} className="MoreBlog" >blog</Link>
                    <Link to="/tip-us" onClick={() => {this.toggleTipUsPage()}} className="MoreTip">tip-us</Link>
                </div>
                <div className="MoreLesserItems">
                    <Link to="/about" onClick={() => {this.toggleAboutPage()}}>about</Link>
                    <Link to="https://buymeacoffee.com/seaslug">support</Link>
                    <Link to="/terms" onClick={() => {this.toggleTermsPage()}}>terms</Link>
                </div>
            </div>;
            headerContent = this.state.MobileHeaderState;
            moreButton = <svg className="MoreIcon" onClick={() => {this.toggleMoreNavigation()}}><line className="MoreIconLine" y1="2.38" x2="40" y2="2.38"></line><line className="MoreIconLine" y1="9.68" x2="40" y2="9.68"></line><line className="MoreIconLine" y1="16.88" x2="40" y2="16.88"></line></svg>;
            deskTopHalf = ""
        } else {
            MorePageContent=<div className="MoreSectionsCont">
                <div className="MoreMainItems">
                    <Link to="/about" onClick={() => {this.toggleAboutPage()}}>about</Link>
                    <Link to="https://buymeacoffee.com/seaslug">support</Link>
                    <Link to="/terms" onClick={() => {this.toggleTermsPage()}}>terms</Link>
                </div>
            </div>;
            headerContent = this.state.DesktopHeaderState;
            moreButton = <svg className="MoreIcon" onClick={() => {this.toggleMoreNavigation()}}><line className="MoreIconLine" y1="10" x2="40" y2="10"></line><line className="MoreIconLine" y1="25" x2="40" y2="25"></line><line className="MoreIconLine" y1="40" x2="40" y2="40"></line></svg>
            deskTopHalf = <div className="calendarDetailContent">{this.state.detailContent}</div>
        }

        var TourPlanListsize;
        if (this.state.TourPlanList.size > 0) {
            TourPlanListsize = "(" + String(this.state.TourPlanList.size) + ")"
        } else {
            TourPlanListsize = ""
        }

        return (
            <div className="BigContainer">
                <div className="header_cont">
                    <div className="header_logo">
                        <a href="https://www.seaslug.se/"><img src={logo_wide_svg} alt="seaslug logo" className="webLogo"/></a>
                    </div>
                    <div className="header_selection">
                        {headerContent}
                    </div>
                    <div className="header_tools">
                        <div className="header_insta_cont">
                            <a href="https://www.instagram.com/seaslug.se/" target="_blank" rel="noreferrer"><img src={instagram_logo} alt="instagram logo" className="instaLogo"/></a>
                        </div>
                        <div className="MoreIconCont">
                            {moreButton}
                        </div>
                    </div>
                </div>
                <div className="MoreNavigationContainer" style={{display: MoreNavigation}}>
                    <div className="MoreCrossCont">
                        <svg className="MoreCross" onClick={() => {this.toggleMoreNavigation()}}>
                            <line className="MoreCrossLine" x1="28.55" y1=".27" x2=".27" y2="28.55"></line>
                            <line className="MoreCrossLine" x1="28.55" y1="28.55" x2=".27" y2=".27"></line>
                        </svg>
                    </div>
                    {MorePageContent}
                </div>
                <div className="PageContents" style={{display: NotLoadingPage}}>
                    <div className="CalendarContent" style={{display: CalendarPage}}>
                        <div className="CalendarFilterBar">
                            <div className="CalendarType" onClick={() => {this.toggleCalendarType();}}>{CalendarType}</div>
                            <div className="TimeFilter" style={{display: ListVis}}>
                                <Select isSearchable={false} options={TimeOptions} defaultValue={defaultTime} className="react-select-time" classNamePrefix="react-select-time" onChange={(event) => {this.CalenderListChange(this.state.TimeNow, event.value, this.state.CalendarListType)}}/>
                            </div>
                            <div className="TypeFilter" style={{display: ListVis}}>
                                <Select isSearchable={false} options={TypeOptions} defaultValue={defaultType} className="react-select-type" classNamePrefix="react-select-type" onChange={(event) => {this.CalenderListChange(this.state.TimeNow, this.state.CalendarListTime, event.value)}}/>
                            </div>
                            <Link to="/tourplan" className="TourPlan" onClick={() => {this.toggleTourplanPage()}}>tourplan {TourPlanListsize}</Link>
                        </div>
                        <div className="calendarOrganise">
                            <div className="CalendarTypeContent">
                                <div className="CalendarCalendarContent" style={{display: CalendarShow}}>
                                    <div className="CalendarCalendarCont">
                                    <Calendar 
                                        className="CalendarCalendarItself"
                                        classNamePrefix="react-select-itself"
                                        onChange={(date) => this.CalenderChange(date)}
                                        tileClassName={tileClassName}
                                    />
                                    <div  className="CalendarsDate">{this.state.CalendarDate}</div>
                                    </div>
                                    <div className="CalendarCalendarList">
                                        {this.state.CalendarOutput}
                                    </div>
                                </div>
                                <div className="CalendarListContent" style={{display: ListShow}}>
                                    {this.state.CalendarListOutput}
                                </div>
                            </div>
                            {deskTopHalf}
                        </div>
                    </div>
                    <Routes>
                        <Route exact path="/spaces" element={<SpacesPage SpacesPageState={this.state.SpacesPage} SpacesFeed={this.state.FeedSpaces} FeedCalendar={this.state.FeedCalendar} FeedSpacesAttributes={this.state.FeedSpacesAttributes} FeedSpacesTime={this.state.FeedSpacesTime} TimeNow={this.state.TimeNow} FeedArtists={this.state.FeedArtists}/>}/>
                        <Route exact path="/blog" element={<BlogPage BlogPageState={this.state.BlogPage}/>}/>
                        <Route exact path="/tip-us" element={<TipUsPage TipUsPageState={this.state.TipUsPage} SpacesFeed={this.state.FeedSpaces}/>}/>
                        <Route exact path="/about" element={<AboutPage AboutPageState={this.state.AboutPage}/>}/>
                        <Route exact path="/terms" element={<TermsPage TermsState={this.TermsPage}/>}/>
                        <Route exact path="/tourplan" element={<TourPlan TourPlanList={this.state.TourPlanList} FeedSpaces={this.state.FeedSpaces} FeedSpacesAttributes={this.state.FeedSpacesAttributes} FeedCalendar={this.state.FeedCalendar} FeedArtists={this.state.FeedArtists} FeedSpacesTime={this.state.FeedSpacesTime}/>}/>
                        <Route exact path="/the-centre-for-the-fictitious-ante-facto" element={<CFAF/>}/>
                    </Routes>
                </div>
                <div className="LoadingPageContent" style={{display: LoadingPage}}>
                    <div className="LoadingPage">
                        <img src={logo_tall_svg} alt="loading_logo" style={{width: "200px", height:"100%"}}/>
                    </div>
                </div>
                <Routes>
                <Route exact path="/the-centre-for-the-fictitious-ante-facto" element={<CFAF/>}/>
                </Routes>
            </div>
        );
    }
}