import React, {Component} from "react";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import dotenv from "dotenv"
import Swal from 'sweetalert2'
import "../css/global.css";
import "react-datepicker/dist/react-datepicker.css";
dotenv.config()

export default class TipUsPage extends Component {
constructor(props, context) {
  super(props, context);
  this.state = {
    visible: false,
    TipUsType: true,
    ExhibitionForm: true,
    EventForm: false,
    Name: "",
    Artists: "",
    Space: "none",
    Vernissage: "none",
    VernisageTimeDisplay: "none",
    EventType: "none",
    ExhibitionStartDate: "",
    ExhibitEndDateShow: false,
    ExhibitionEndDate: "",
    Description: "",
    Link: "",
    Curators: "",
    Contact: "",
    //ImageLink: "",
    //ImageCaption: "",
    EventAdmission: "",
    EventDateStart: [],
    EventDateEnd: [],
    EventNumber: 1,
    DescriptionCharacterNumber: 0,
    AdmissionCostAppearance: "none",
    AdmissionCost: "",
    CountdownSeconds:1000
  };
  this.toggleTipUsType = this.toggleTipUsType.bind(this);
  this.toggleExhibitionForm = this.toggleExhibitionForm.bind(this);
  this.toggleEventForm = this.toggleEventForm.bind(this);
  this.handleFormChange = this.handleFormChange.bind(this);
  this.handleSpaceSelect = this.handleSpaceSelect.bind(this);
  this.handleVernissageSelect = this.handleVernissageSelect.bind(this);
  this.handleTypeSelect = this.handleTypeSelect.bind(this);
  this.handleExhibitionStartDate = this.handleExhibitionStartDate.bind(this);
  this.handleExhibitionEndDate = this.handleExhibitionEndDate.bind(this);
  this.handleEventDateStart = this.handleEventDateStart.bind(this);
  this.handleEventDateEnd = this.handleEventDateEnd.bind(this);
  this.handleEventAdd = this.handleEventAdd.bind(this);
  this.handleCharacterCount = this.handleCharacterCount.bind(this);
  this.handleExhibitTip = this.handleExhibitTip.bind(this);
  this.handleEventTip = this.handleEventTip.bind(this);
  this.handleAdmissionSelect = this.handleAdmissionSelect.bind(this);
  this.handleSubtractOne = this.handleSubtractOne.bind(this)
}

handleSubtractOne(){
    if (this.state.CountdownSeconds>0) { 
        this.setState({
            CountdownSeconds: (this.state.CountdownSeconds - 1)
        })
    }
}

componentDidMount() {
    setInterval(this.handleSubtractOne, 10);
}

toggleTipUsType() {
    this.setState({
        TipUsType: !this.state.TipUsType
    });
}

toggleExhibitionForm(event) {
    this.setState({
        ExhibitionForm: !this.state.ExhibitionForm,
        EventForm: !this.state.EventForm
    });
}

toggleEventForm(event) {
    this.setState({
        ExhibitionForm: !this.state.ExhibitionForm,
        EventForm: !this.state.EventForm
    });
}

handleFormChange(information) {
    const target = information.target;
    const value = information.target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
}

handleSpaceSelect(information) {
    const value = information.value;
    this.setState({
        Space: value
    });
}

handleTypeSelect(information) {
    const value = information.value;
    this.setState({
        EventType: value
    });
}

handleVernissageSelect(information) {
    const value = information.value;
    this.setState({
        Vernissage: value
    });
    if (information.value === "yes") {
        this.setState({
            VernisageTimeDisplay: "flex"
        })
    } else {
        this.setState({
            VernisageTimeDisplay: "none"
        })
    }
}

handleExhibitionStartDate(exhibtstart) {
    this.setState({
      ExhibitionStartDate: exhibtstart,
      ExhibitEndDateShow: true,
      ExhibitionEndDate: ""
    })
}

handleExhibitionEndDate(exhibitend) {
    this.setState({
      ExhibitionEndDate: exhibitend,
    })
}

handleEventDateStart(eventdate, whatever, theIndex) {
    if (this.state.EventDateStart === "") {
        this.setState({
            EventDateStart: [eventdate]
        })
    } else {
        if (theIndex === 0) {
            this.setState({
                EventDateStart: [eventdate]
            })
        } else {
            var newStateArray = this.state.EventDateStart;
            newStateArray[theIndex] = eventdate
            this.setState({
                EventDateStart: newStateArray
            })
        }
    }
}

handleEventDateEnd(eventdate, whatever, theIndex) {
    if (this.state.EventDateEnd === "") {
        this.setState({
            EventDateEnd: [eventdate]
        })
    } else {
        if (theIndex === 0) {
            this.setState({
                EventDateEnd: [eventdate]
            })
        } else {
            var newStateArray = this.state.EventDateEnd;
            newStateArray[theIndex] = eventdate
            this.setState({
                EventDateEnd: newStateArray
            })
        }
    }
}

handleEventAdd(effect){ 
    var NewEventDateStart;
    var NewEventDateEnd;
    if (effect === "add") {
        NewEventDateStart = this.state.EventDateStart
        NewEventDateStart.push("")
        NewEventDateEnd = this.state.EventDateEnd
        NewEventDateEnd.push("")
        this.setState({
            EventNumber: this.state.EventNumber + 1,
            EventDateStart: NewEventDateStart,
            EventDateEnd: NewEventDateEnd,
        })
    }
    if (effect === "subtract") {
        NewEventDateStart = this.state.EventDateStart
        NewEventDateStart.pop()
        NewEventDateEnd = this.state.EventDateEnd
        NewEventDateEnd.pop()
        this.setState({
            EventNumber: this.state.EventNumber - 1,
            EventDateStart: NewEventDateStart,
            EventDateEnd: NewEventDateEnd,
        })
    }
}

handleCharacterCount(e) {
    var currentText = e.target.value;
    this.setState({
        DescriptionCharacterNumber: currentText.length
    })
}

async handleExhibitTip() {
    var ExhibitErrorCheck = [];
    if (this.state.Space === "none") {
        ExhibitErrorCheck.push("Space")
    }

    if (this.state.Artists.length === 0) {
        ExhibitErrorCheck.push("Artist(s)")
    }

    if (this.state.ExhibitionStartDate.length === 0) {
        ExhibitErrorCheck.push("Start date")
    }

    if (this.state.ExhibitionEndDate.length === 0) {
        ExhibitErrorCheck.push("End date")
    }

    if (this.state.Vernissage === "none") {
        ExhibitErrorCheck.push("Vernissage")
    } else if (this.state.Vernissage === "yes") {
        if (this.state.EventDateStart === "") {
            ExhibitErrorCheck.push("Vernissage start date")
        }
        if (this.state.EventDateEnd === "") {
            ExhibitErrorCheck.push("Vernissage end date")
        }
    }
    /*
    if (this.state.Link.length > 0) {
        if (!this.state.Link.includes("http")) {
            ExhibitErrorCheck.push("Image link must be a link")
        }
    }

    if (this.state.ImageLink.length > 0) {
        if (!this.state.Link.includes("https")) {
            ExhibitErrorCheck.push("Image link must be a https")
        }
    }*/

    if ((this.state.CountdownSeconds !== 0) && (this.state.AdmissionCost === "")) {
        ExhibitErrorCheck.push("Wait " + String(this.state.CountdownSeconds/100) + " more seconds")
    }

    if (ExhibitErrorCheck.length > 0) {
        Swal.fire({
            icon: 'error',
            title: 'Submission needs the following fields',
            text: ExhibitErrorCheck.join(", ")
    })
    } else {
        fetch('https://seaslugadmin.dk/api/webpost', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
            },
            body: JSON.stringify({
                title: this.state.Name,
                artists: this.state.Artists,
                curators: this.state.Curators,
                spaceId: this.state.Space,
                contacts: this.state.Contact,
                startDate: new Date(new Date(this.state.ExhibitionStartDate.getTime() + 2 * 60 * 60 * 1000)).toISOString().slice(0, 19),
                endDate: new Date(new Date(this.state.ExhibitionEndDate.getTime() + 2 * 60 * 60 * 1000)).toISOString().slice(0, 19),
                link: this.state.Link,
                description: this.state.Description,
                typeId: 3,
                //imageLink: this.state.ImageLink,
                //imageCaption: this.state.ImageCaption,
                postDate: new Date().toISOString().slice(0, 19),
                postingReference: "submission form"
            })
        })
        .then(response => response.json())

        if (this.state.Vernissage === "yes") {
            fetch('https://seaslugadmin.dk/api/webpost', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
                },
                body: JSON.stringify({
                    title: this.state.Name,
                    artists: this.state.Artists,
                    curators: this.state.Curators,
                    spaceId: this.state.Space,
                    contacts: this.state.Contact,
                    startDate: new Date(new Date(this.state.EventDateStart[0].getTime() + 1 * 60 * 60 * 1000)).toISOString().slice(0, 19),
                    endDate: new Date(new Date(this.state.EventDateEnd[0].getTime() + 1 * 60 * 60 * 1000)).toISOString().slice(0, 19),
                    link: this.state.Link,
                    description: this.state.Description,
                    typeId: 1,
                    //imageLink: this.state.ImageLink,
                    //imageCaption: this.state.ImageCaption,
                    postDate: new Date().toISOString().slice(0, 19),
                    postingReference: "submission form"
                })
            })
            .then(response => response.json())
        }
        
        Swal.fire(
            'Exhibition submitted',
            'Will be reviewed by the team asap',
            'success')
    }
}

handleEventTip() {
    var EventErrorCheck = [];
    if (this.state.Space === "none") {
        EventErrorCheck.push("Space")
    }
    if (this.state.Artists.length === 0) {
        EventErrorCheck.push("Artist(s)")
    }

    if (this.state.EventType === "none") {
        EventErrorCheck.push("Type")
    }

    if (this.state.EventDateStart.length === 0) {
        EventErrorCheck.push("Event start date")
    }

    if (this.state.EventDateEnd.length === 0) {
        EventErrorCheck.push("Event end date")
    }

    if ((this.state.EventDateStart.length !== this.state.EventNumber) | (this.state.EventDateEnd.length !== this.state.EventNumber) | (this.state.EventDateStart.includes("")) | (this.state.EventDateEnd.includes(""))) {
        EventErrorCheck.push("Times need to be filled")
    }

    if ((this.state.AdmissionCostAppearance === "flex") && (this.state.AdmissionCost === "")) {
        EventErrorCheck.push("Admission cost")
    }
    /*
    if (this.state.Link.length > 0) {
        if (!this.state.Link.includes("http")) {
            EventErrorCheck.push("Image link must be a link")
        }
    }

    if (this.state.ImageLink.length > 0) {
        if (!this.state.Link.includes("https")) {
            EventErrorCheck.push("Image link must be a https")
        }
    }*/

    if ((this.state.CountdownSeconds !== 0) && (this.state.AdmissionCost === "")) {
        EventErrorCheck.push("Wait " + String(this.state.CountdownSeconds/100) + " more seconds")
    }

    if (EventErrorCheck.length > 0) {
        Swal.fire({
            icon: 'error',
            title: 'Submission needs the following fields',
            text: EventErrorCheck.join(", ")
        })
    } else {
        for (var thisEvent in this.state.EventDateStart) {
            fetch('https://seaslugadmin.dk/api/webpost', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
                },
                body: JSON.stringify({
                    title: this.state.Name,
                    artists: this.state.Artists,
                    curators: this.state.Curators,
                    spaceId: this.state.Space,
                    contacts: this.state.Contact,
                    startDate: new Date(new Date(this.state.EventDateStart[thisEvent].getTime() + 1 * 60 * 60 * 1000)).toISOString().slice(0, 19),
                    endDate: new Date(new Date(this.state.EventDateEnd[thisEvent].getTime() + 1 * 60 * 60 * 1000)).toISOString().slice(0, 19),
                    link: this.state.Link,
                    description: this.state.Description,
                    typeId: this.state.EventType,
                    //imageLink: this.state.ImageLink,
                    //imageCaption: this.state.ImageCaption,
                    postDate: new Date().toISOString().slice(0, 19),
                    postingReference: "submission form"
                })
            })
            .then(response => response.json())
        }
        Swal.fire(
            'Event submitted',
            'Will be reviewed by the team asap',
            'success')
    }
}

handleAdmissionSelect(information) {
    if (information.value === "yes") {
        this.setState({
            AdmissionCostAppearance: "flex"
        })
    } else {
        this.setState({
            AdmissionCostAppearance: "none"
        })
    }
}

render() {
    var SpacesFeed = this.props.SpacesFeed;
    var SpaceKey;
    var TipUsSpaceSelect = [];
    var excludeSpaces = ["Benhuset","Den Fantastiska Platsen","Domeij Gallery","Flat Octopus","Fylkingen","Galerie Nordenhake Focus","Galleri Skomakeriet","Gerlesborgsskolan","Ring art space","Selva Studio Sthlm","Galleri Artsight","Art Lab Gnesta","Galleri gemla","Litografiska Museet"]
    for (SpaceKey in SpacesFeed) {
        if (!excludeSpaces.some(excludeSpace => SpacesFeed[SpaceKey].name.includes(excludeSpace))) {
            TipUsSpaceSelect.push({
                value: SpacesFeed[SpaceKey].spaceId,
                label: SpacesFeed[SpaceKey].name
            })
        }
    }

    // Sort the TipUsSpaceSelect array by the alphabetical value of the name property
    TipUsSpaceSelect.sort((a, b) => a.label.localeCompare(b.label));


    var form_name;
    var ExhibitDisplay;
    var EventDisplay;
    if (this.state.ExhibitionForm) {
        form_name = "exhibition"
        ExhibitDisplay = "flex"
        EventDisplay = "none"
    }

    if (this.state.EventForm) {
        form_name = "event"
        ExhibitDisplay = "none"
        EventDisplay = "flex"
    }

    var TipUsPage;
    if (this.props.TipUsPageState) {
        TipUsPage = "block";
    } else {
        TipUsPage = "none";
    }

    var ExhibitEndDateShow;
    if (this.state.ExhibitEndDateShow) {
        ExhibitEndDateShow = "flex";
    } else {
        ExhibitEndDateShow = "none"
    }

    var  AddedEventTimes;
    if (this.state.EventNumber <= 0){
        AddedEventTimes = ""
    } else {
        AddedEventTimes = []
        for (let i = 1; i < this.state.EventNumber; i++) {
            AddedEventTimes.push(<div key={"EventDateExtra_"+String(i)} className="EventDateExtra"><div key={"EventDateLabel_"+String(i)} className="EventDateLabel">start time*</div><div key={"EventDateExtraCalendar_"+String(i)} className='EventDateExtraCalendar'><DatePicker key={"EventDateExtraStart_"+String(i)} placeholderText={"Select date"} selected={this.state.EventDateStart[i]} onChange={(value, e) =>this.handleEventDateStart(value, e, i)} name="EventDateExtraStart" dateFormat="dd/MM/yyyy hh:mm" showTimeSelect timeFormat="HH:mm" timeIntervals={15} timeCaption="Time" minDate={new Date()}/></div><div key={"EventDateLabelEnd_"+String(i)} className="EventDateLabel">end time*</div><div key={"EventDateExtraCalendarEnd_"+String(i)} className='EventDateExtraCalendar'><DatePicker key={"EventDateExtraEnd_"+String(i)} placeholderText={"Select date"} selected={this.state.EventDateEnd[i]} onChange={(value, e) =>this.handleEventDateEnd(value, e, i)} name="EventDateExtraEnd" dateFormat="dd/MM/yyyy hh:mm" showTimeSelect timeFormat="HH:mm" timeIntervals={15} timeCaption="Time" minDate={new Date()}/></div><div className="EventDateExtraSubtract" onClick={() => {this.handleEventAdd("subtract")}}>-</div></div>)
        }
    }

    const SpacesOptions = TipUsSpaceSelect


    const TypeOptions = [{ value: '2', label: 'Artist Talk' }, { value: '4', label: 'Other event' }, { value: '1', label: 'Vernissage' }]

    const AdmissionOptions = [{value:"yes", label: "Yes"}, {valie: "no", label: "No"}]

    const VernissageOptions = [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]

    return (
    <div className="TipUsContent" style={{display: TipUsPage}}>
        <div className="TipUsDescription">If there is an exhibition or event that is not yet on seaslug we would love to know more about it. Please fill out the following form & the team will review the submission asap.
        <br/>
        <b>To ensure your event/exhibition reaches our platforms please notify us at least a week ahead of when it begins</b>
        </div>
        <form className="TipUsRadio">
            <input type="radio" name="FormType" defaultChecked onChange={this.toggleExhibitionForm}/> exhibition
            <input type="radio" name="FormType" onChange={this.toggleEventForm}/> event
        </form>
        <div className="TipUsForm">
            <form id="Form">
                <div className="NameCont">
                    <div className="NameLabel">{form_name} name</div>
                    <input type="text" className="NameContent" value={this.state.Name} name="Name" onChange={this.handleFormChange}/>
                </div>
                <div className="SpaceCont">
                    <div className="SpaceLabel">space* <div className="InformationButton">i<span className="tooltiptext">If Space not in list please select "Other"<br/> and feel free to add context in the description</span></div></div>
                    <div className="SpaceDropdown"><Select options={SpacesOptions} className="react-select-space" classNamePrefix="react-select-space" onChange={this.handleSpaceSelect}/></div>
                </div>
                <div className="ArtistCont">
                    <div className="ArtistLabel">artist(s)* <div className="InformationButton">i<span className="tooltiptext">If multiple artists please seperate each with ","</span></div></div>
                    <input type="text" className="ArtistContent" value={this.state.Artists} name="Artists" onChange={this.handleFormChange}/>
                </div>
                <div className="TypeCont" style={{display: EventDisplay}}>
                    <div className="TypeLabel">type*</div>
                    <div className="TypeDropdown"><Select options={TypeOptions} className="react-select-type" classNamePrefix="react-select-type" onChange={this.handleTypeSelect}/></div>
                </div>
                <div className="DatesExhibit" style={{display: ExhibitDisplay}}>
                    <div className="ExhibitStartDateCont">
                        <div className="ExhibitStartDateLabel">start date*</div>
                        <div className="ExhibitStartCalendar">
                            <DatePicker
                            placeholderText={"Select date"}
                            selected={this.state.ExhibitionStartDate }
                            onChange={this.handleExhibitionStartDate }
                            name="exhibitStart"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="ExhibitEndDateCont" style={{display: ExhibitEndDateShow}}>
                        <div className="ExhibitEndDateLabel">end date*</div>
                        <div className="ExhibitEndCalendar">
                            <DatePicker
                            placeholderText={"Select date"}
                            selected={this.state.ExhibitionEndDate }
                            onChange={this.handleExhibitionEndDate }
                            name="exhibitStart"
                            dateFormat="dd/MM/yyyy"
                            minDate={this.state.ExhibitionStartDate}
                            />
                        </div>
                    </div>
                </div>
                <div className="DatesEvent" style={{display: EventDisplay}}>
                    <div className="EventDateMainCont">
                        <div className="EventDateMainLabel">start time*</div>
                        <div className="EventDateMainCalendar">
                            <DatePicker
                            placeholderText={"Select date"}
                            selected={this.state.EventDateStart[0]}
                            onChange={(value, e) => this.handleEventDateStart(value, e, 0)}
                            name="EventDateStart"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            minDate={new Date()}
                            />
                        </div>
                        <div className="EventDateMainLabel">end time*</div>
                        <div className="EventDateMainCalendar">
                            <DatePicker
                            placeholderText={"Select date"}
                            selected={this.state.EventDateEnd[0]}
                            onChange={(value, e) => this.handleEventDateEnd(value, e, 0)}
                            name="EventDateEnd"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            minDate={new Date()}
                            />
                        </div>
                        <div className="EventDateMainAdd" onClick={() => {this.handleEventAdd("add")}}>+</div>
                    </div>
                    <div className="EventDateExtraCont">
                    {AddedEventTimes}
                    </div>
                </div>
                <div className="VernissageStuffCont" style={{display: ExhibitDisplay}}>
                    <div className="VernissageCheckCont">
                        <div className="VernisageCheckLabel">is there a vernissage?* <div className="InformationButton">i<span className="tooltiptext">This assumes the vernissage is on the same day as the start date</span></div></div>
                        <div className="VernisageCheckDropdown"><Select isSearchable={false} options={VernissageOptions} className="react-select-vernissage" classNamePrefix="react-select-vernissage" onChange={this.handleVernissageSelect}/></div>
                    </div>
                    <div className="ExhibitVernisageTime" style={{display: this.state.VernisageTimeDisplay}}>
                        <div className="VernisageTimeLabel">vernissage start time*</div>
                        <div className="VernisageTimeCalendar">
                            <DatePicker
                            placeholderText={"Select date"}
                            selected={this.state.EventDateStart[0]}
                            onChange={(value, e) => this.handleEventDateStart(value, e, 0)}
                            name="EventDateStart"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            minDate={new Date()}
                            />
                        </div>
                        <div className="VernisageTimeLabel" style={{marginLeft: "5px"}}>vernissage end time*</div>
                        <div className="VernisageTimeCalendar">
                            <DatePicker
                            placeholderText={"Select date"}
                            selected={this.state.EventDateEnd[0]}
                            onChange={(value, e) => this.handleEventDateEnd(value, e, 0)}
                            name="EventDateEnd"
                            dateFormat="dd/MM/yyyy HH:mm"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            timeCaption="Time"
                            minDate={new Date()}
                            />
                        </div>
                    </div>
                </div>
                <div className="DescriptionCont">
                    <div className="DescriptionLabel">description ({this.state.DescriptionCharacterNumber}/500 characters)</div>
                    <textarea type="text" className="DescriptionContent" maxLength="500" name="Description" rows="8" onChange={e => {this.handleCharacterCount(e); this.handleFormChange(e)}}/>
                </div>
                <div className="LinkCont">
                    <div className="LinkLabel">link <div className="InformationButton">i<span className="tooltiptext">Link specific to the event (must be a https link)</span></div></div>
                    <input type="text" className="LinkContent" name="Link" onChange={this.handleFormChange}/>
                </div>
                <div className="CuratorCont">
                    <div className="CuratorLabel">curator(s) <div className="InformationButton">i<span className="tooltiptext">If multiple curtors please seperate each with ","</span></div></div>
                    <input type="text" className="CuratorContent" name="Curators" onChange={this.handleFormChange}/>
                </div>
                <div className="ContactCont">
                    <div className="ContactLabel">contact <div className="InformationButton">i<span className="tooltiptext">Either email &/or phone please</span></div></div>
                    <input type="text" className="ContactContent" name="Contact" onChange={this.handleFormChange}/>
                </div>
                <div className="AdmissionCont" style={{display: EventDisplay}}>
                    <div className="AdmissionDropDownCont">
                        <div className="AdmissionLabel">admission</div>
                        <div className="AdimssionDropDown"><Select options={AdmissionOptions} className="react-select-space" classNamePrefix="react-select-space" onChange={this.handleAdmissionSelect}/></div>
                    </div>
                    <div className="AdmmissionCostCont" style={{display: this.state.AdmissionCostAppearance}}>
                        <div className="AdmissionCostLabel">cost (sek)*</div>
                        <input type="text" className="AdmissionCostContent" name="AdmissionCost" onChange={this.handleFormChange}/>
                    </div>
                </div>
                <div className="timerCont">
                <progress className="timerBar" value={this.state.CountdownSeconds} max="1000"></progress>
                </div>
                <div className="FormButtonCont">
                    <div style={{display: ExhibitDisplay}} className="ExhibitSubmit" onClick={() => {this.handleExhibitTip()}}>Submit</div>
                    <div style={{display: EventDisplay}} className="EventSubmit" onClick={() => {this.handleEventTip()}}>Submit</div>
                </div>
            </form>
        </div>
    </div>
    )
}
}